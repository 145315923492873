import { useReducer, useState } from "react";
import { useQuery } from "react-query";
import { Outlet } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import AddUserAutomaticallyModal from "./AddUserAutomaticallyModal";
import AddUserManuallyModal from "./AddUserManuallyModal";
import AddUserByShareQrCodeModal from "./AddUserByShareQrCodeModal";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";
import { FaUserPlus } from "react-icons/fa6";
import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";
import { BiPlus } from "react-icons/bi";
import AddToGroupModal from "./AddToGroupModal";
import UsersListComponent from "./UsersListComponent";

enum AddUserActionKind {
  AU = "AU",
  MA = "MA",
  SH = "SH",
  DEFAULT = "DEFAULT",
  AG = "AG",
}

interface AddUserAction {
  type: AddUserActionKind;
}

interface AddUserActionState {
  au: boolean;
  ma: boolean;
  sh: boolean;
}

const addUserActionReducer = (
  state: AddUserActionState,
  action: AddUserAction
) => {
  const { type } = action;

  switch (type) {
    case AddUserActionKind.AU:
      return {
        au: true,
        ma: false,
        sh: false,
        ag: false,
      };
    case AddUserActionKind.MA:
      return {
        au: false,
        ma: true,
        sh: false,
        ag: false,
      };
    case AddUserActionKind.SH:
      return {
        au: false,
        ma: false,
        sh: true,
        ag: false,
      };
    case AddUserActionKind.AG:
      return {
        au: false,
        ma: false,
        sh: false,
        ag: true,
      };
    default:
      return {
        au: false,
        ma: false,
        sh: false,
        ag: false,
      };
  }
};

const PointerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.67187 1.9115C6.52893 1.37804 5.9806 1.06146 5.44713 1.2044C4.91366 1.34734 4.59708 1.89568 4.74002 2.42914L4.99884 3.39507C5.14178 3.92853 5.69012 4.24512 6.22359 4.10217C6.75705 3.95923 7.07363 3.4109 6.93069 2.87743L6.67187 1.9115Z"
        fill="#BAE6FD"
      />
      <path
        d="M2.42923 4.73993C1.89577 4.59699 1.34743 4.91357 1.20449 5.44704C1.06155 5.9805 1.37813 6.52884 1.9116 6.67178L2.87752 6.9306C3.41099 7.07354 3.95932 6.75696 4.10227 6.22349C4.24521 5.69003 3.92862 5.14169 3.39516 4.99875L2.42923 4.73993Z"
        fill="#BAE6FD"
      />
      <path
        d="M11.2427 4.17152C11.6332 3.781 11.6332 3.14784 11.2427 2.75731C10.8522 2.36679 10.219 2.36679 9.82847 2.75731L9.12136 3.46442C8.73084 3.85494 8.73084 4.48811 9.12136 4.87863C9.51189 5.26916 10.1451 5.26916 10.5356 4.87863L11.2427 4.17152Z"
        fill="#BAE6FD"
      />
      <path
        d="M4.17162 11.2426L4.87872 10.5355C5.26925 10.145 5.26925 9.5118 4.87872 9.12127C4.4882 8.73075 3.85503 8.73075 3.46451 9.12127L2.7574 9.82838C2.36688 10.2189 2.36688 10.8521 2.7574 11.2426C3.14793 11.6331 3.78109 11.6331 4.17162 11.2426Z"
        fill="#BAE6FD"
      />
      <path
        d="M7.37154 6.07155C7.00012 5.92298 6.57591 6.01005 6.29304 6.29292C6.01018 6.57578 5.92311 7 6.07167 7.37141L10.0717 17.3714C10.2179 17.737 10.5651 17.9828 10.9586 17.9992C11.352 18.0155 11.7185 17.7995 11.8946 17.4472L13.2741 14.6882L16.293 17.7071C16.6836 18.0977 17.3167 18.0977 17.7073 17.7071C18.0978 17.3166 18.0978 16.6834 17.7073 16.2929L14.6883 13.274L17.4474 11.8945C17.7996 11.7183 18.0157 11.3519 17.9993 10.9585C17.9829 10.565 17.7372 10.2178 17.3715 10.0715L7.37154 6.07155Z"
        fill="#BAE6FD"
      />
    </svg>
  );
};

const FormIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.4142 2.58579C16.6332 1.80474 15.3668 1.80474 14.5858 2.58579L7 10.1716V13H9.82842L17.4142 5.41421C18.1953 4.63316 18.1953 3.36683 17.4142 2.58579Z"
        fill="#BAE6FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 4.89543 2.89543 4 4 4H8C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6H4V16H14V12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12V16C16 17.1046 15.1046 18 14 18H4C2.89543 18 2 17.1046 2 16V6Z"
        fill="#BAE6FD"
      />
    </svg>
  );
};

const LinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.5858 4.58579C13.3668 3.80474 14.6331 3.80474 15.4142 4.58579C16.1952 5.36683 16.1952 6.63316 15.4142 7.41421L12.4142 10.4142C11.6331 11.1953 10.3668 11.1953 9.58577 10.4142C9.19524 10.0237 8.56208 10.0237 8.17156 10.4142C7.78103 10.8047 7.78103 11.4379 8.17156 11.8284C9.73365 13.3905 12.2663 13.3905 13.8284 11.8284L16.8284 8.82843C18.3905 7.26633 18.3905 4.73367 16.8284 3.17157C15.2663 1.60948 12.7337 1.60948 11.1716 3.17157L9.67156 4.67157C9.28103 5.0621 9.28103 5.69526 9.67156 6.08579C10.0621 6.47631 10.6952 6.47631 11.0858 6.08579L12.5858 4.58579Z"
        fill="#BAE6FD"
      />
      <path
        d="M7.58579 9.58579C8.36683 8.80474 9.63316 8.80474 10.4142 9.58579C10.8047 9.97631 11.4379 9.97631 11.8284 9.58579C12.219 9.19526 12.219 8.5621 11.8284 8.17157C10.2663 6.60948 7.73367 6.60948 6.17157 8.17157L3.17157 11.1716C1.60948 12.7337 1.60948 15.2663 3.17157 16.8284C4.73367 18.3905 7.26633 18.3905 8.82843 16.8284L10.3284 15.3284C10.719 14.9379 10.719 14.3047 10.3284 13.9142C9.9379 13.5237 9.30474 13.5237 8.91421 13.9142L7.41421 15.4142C6.63316 16.1953 5.36684 16.1953 4.58579 15.4142C3.80474 14.6332 3.80474 13.3668 4.58579 12.5858L7.58579 9.58579Z"
        fill="#BAE6FD"
      />
    </svg>
  );
};

const UsersList = () => {
  const [t] = useTranslation();
  const { user, customer_identification, userEndedSubscription } = useAuth();

  const localStorage = useAppLocalStorage();

  // const [sorting, setSorting] = React.useState<SortingState>([]);
  const [limit, setLimit] = useState<10 | 30 | 50>(
    localStorage.getItem("umd_users_list_limit") &&
      [10, 30, 50].includes(
        Number(localStorage.getItem("umd_users_list_limit")) as any
      )
      ? (Number(localStorage.getItem("umd_users_list_limit")) as any)
      : 10
  );

  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [sortBy] = useState<"CREATION_DATE" | "EMAIL" | "">("");
  const [state, dispatch] = useReducer(addUserActionReducer, {
    au: false,
    ma: false,
    sh: false,
    ag: false,
  });

  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useQuery(
    ["entity-users", [offset, limit, sortBy, sortOrder]],
    () =>
      HttpService.get(
        `/${customer_identification}/${
          user.entityIdentification
        }/entity/users?limit=${
          limit
          // limit < 50 ? limit + 1 : limit
        }&offset=${offset}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      onSuccess(data) {
        setLimit(data.data.element.pagination.limit ?? 10);
        setOffset(data.data.element.pagination.offset ?? 0);
      },
    }
  );

  const handleShowRelatedAddUserType = (
    addUserType: "au" | "ma" | "sh" | null | ""
  ) => {
    if (addUserType !== null || addUserType !== "") {
      switch (addUserType) {
        case "au":
          return dispatch({ type: AddUserActionKind.AU });
        case "ma":
          return dispatch({ type: AddUserActionKind.MA });
        case "sh":
          return dispatch({ type: AddUserActionKind.SH });
        default:
          return dispatch({ type: AddUserActionKind.DEFAULT });
      }
    }
  };

  const userTitle = (
    <h1 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
      {t("dashboard.entities.viewEntityUser.key13")}
    </h1>
  );
  const addUserDropDown = (
    <Dropdown
      label=""
      dismissOnClick
      renderTrigger={() => (
        <button
          className="pl-[11px] pr-[13px] py-[9px] bg-sky-500 rounded-md shadow justify-center items-center gap-2 inline-flex"
          disabled={userEndedSubscription}
        >
          <div className="w-5 h-5 relative">
            <FaUserPlus size={20} color="white" />
          </div>
          <div className="text-white text-sm font-medium font-['Inter'] leading-none">
            {t("dashboard.usersList.key1")}
          </div>
        </button>
      )}
      className="bg-sky-500 rounded-md max-w-[350px] md:!left-[-25px] !left-[-10px]"
    >
      {!user?.customer_top_plan?.includes("FREE") && (
        <Dropdown.Item
          className="hover:bg-sky-blue focus:bg-sky-blue border-b border-gray-900/5 justify-start items-start gap-3 inline-flex"
          onClick={() => handleShowRelatedAddUserType("au")}
        >
          <div className="self-stretch py-2 flex-col justify-start items-start gap-1 inline-flex">
            <div className="self-stretch justify-start items-start gap-3 inline-flex">
              <div className="w-5 h-5 relative">
                <PointerIcon />
              </div>
              <div className="text-white text-sm font-semibold font-['Figtree'] leading-tight">
                {t("dashboard.usersList.addUserModal.title-01")}
              </div>
            </div>
            <div className="self-stretch justify-start items-start gap-3 inline-flex">
              <div className="w-[21px] text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                {" "}
              </div>
              <div className="grow shrink basis-0 text-sky-100 text-xs text-left font-normal font-['Figtree'] leading-none">
                {t("dashboard.usersList.addUserModal.text-01")}
              </div>
            </div>
          </div>
        </Dropdown.Item>
      )}
      <Dropdown.Item
        className="hover:bg-sky-blue focus:bg-sky-blue border-b border-gray-900/5 justify-start items-start gap-3 inline-flex"
        onClick={() => handleShowRelatedAddUserType("ma")}
      >
        <div className="self-stretch py-2 flex-col justify-start items-start gap-1 inline-flex">
          <div className="self-stretch justify-start items-start gap-3 inline-flex">
            <div className="w-5 h-5 relative">
              <FormIcon />
            </div>
            <div className="text-white text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.usersList.addUserModal.title-02")}
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-3 inline-flex">
            <div className="w-[21px] text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
              {" "}
            </div>
            <div className="grow shrink basis-0 text-sky-100 text-xs text-left font-normal font-['Figtree'] leading-none">
              {t("dashboard.usersList.addUserModal.text-02")}
            </div>
          </div>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        className="hover:bg-sky-blue focus:bg-sky-blue  justify-start items-start gap-3 inline-flex"
        onClick={() => handleShowRelatedAddUserType("sh")}
      >
        <div className="self-stretch py-2 flex-col justify-start items-start gap-1 inline-flex">
          <div className="self-stretch justify-start items-start gap-3 inline-flex">
            <div className="w-5 h-5 relative">
              <LinkIcon />
            </div>
            <div className="text-white text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.usersList.addUserModal.title-03")}
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-3 inline-flex">
            <div className="w-[21px] text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
              {" "}
            </div>
            <div className="grow shrink basis-0 text-sky-100 text-xs text-left font-normal font-['Figtree'] leading-none">
              {t("dashboard.usersList.addUserModal.text-03")}
            </div>
          </div>
        </div>
      </Dropdown.Item>
    </Dropdown>
  );

  const otherButtons = (
    <>
      {/* Add to group*/}
      {selectedRowIds?.size > 1 && (
        <div
          role="button"
          onClick={() => dispatch({ type: AddUserActionKind.AG })}
          className="px-2 py-3 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center gap-1 inline-flex overflow-hidden"
        >
          <div data-svg-wrapper className="relative">
            <BiPlus className="text-gray-500" size={18} />
          </div>
          <div className="text-gray-700 text-sm font-medium font-['Figtree'] leading-tight">
            {t("dashboard.entities.viewEntityUser.key14")}
          </div>
        </div>
      )}
      {/* limit */}
      <Dropdown
        label={`${limit}`}
        dismissOnClick
        color={"light"}
        className="z-[1]"
        // size={"sm"}
      >
        {[10, 30, 50].map((item: any) => (
          <Dropdown.Item
            key={item}
            onClick={() => {
              localStorage.setItem("umd_users_list_limit", item.toString());

              setLimit(item);
              setOffset(0);
            }}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown>
      {/* Sort */}
      <Dropdown
        label={
          !sortOrder
            ? t("dashboard.usersList.item-07")
            : sortOrder === "ASC"
            ? "Asc"
            : "Dec"
        }
        dismissOnClick
        color={"light"}
        // size={"sm"}
      >
        {["ASCENDING", "DESCENDING"].map((item: any) => (
          <Dropdown.Item
            key={item}
            onClick={() =>
              setSortOrder(
                item === "ASCENDING"
                  ? "ASC"
                  : item === "DESCENDING"
                  ? "DESC"
                  : ""
              )
            }
          >
            {item === "ASCENDING"
              ? t("dashboard.usersList.item-08")
              : t("dashboard.usersList.item-09")}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  );
  return (
    <div className="container mx-auto py-6">
      <div className="block md:hidden flex items-center justify-between mb-6">
        {userTitle}
        <div className="flex flex-wrap items-md-center justify-end gap-4">
          {addUserDropDown}
        </div>
      </div>

      <div className="block md:hidden flex items-center justify-end mb-6 flex-wrap gap-3">
        {otherButtons}
      </div>

      <div className="hidden md:flex  items-center justify-between mb-6">
        {userTitle}
        <div className="flex flex-wrap items-md-center justify-end gap-4">
          {otherButtons}
          {addUserDropDown}
        </div>
      </div>

      <div className="dl-shadow-box p-0 mb-3">
        <UsersListComponent
          users={res?.data.element.users}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          refetch={refetch}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          pagination={res?.data.element.pagination}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
        />

        <AddUserAutomaticallyModal
          show={state.au}
          onBackClick={() => {
            dispatch({ type: AddUserActionKind.DEFAULT });
          }}
          onHide={() => dispatch({ type: AddUserActionKind.DEFAULT })}
          size="lg"
          className="aum"
        />

        <AddUserManuallyModal
          show={state.ma}
          onBackClick={() => {
            dispatch({ type: AddUserActionKind.DEFAULT });
          }}
          onHide={() => dispatch({ type: AddUserActionKind.DEFAULT })}
          size="2xl"
          className="aum"
        />

        <AddUserByShareQrCodeModal
          show={state.sh}
          onBackClick={() => {
            dispatch({ type: AddUserActionKind.DEFAULT });
          }}
          onHide={() => dispatch({ type: AddUserActionKind.DEFAULT })}
          size="xl"
          className="aum"
        />
        <AddToGroupModal
          userList={selectedRowIds}
          show={state.ag}
          onHide={() => dispatch({ type: AddUserActionKind.DEFAULT })}
          onResolved={() => setSelectedRowIds(new Set())}
        />

        <Outlet />
      </div>
    </div>
  );
};

export default UsersList;
