import { useMutation, useQuery } from "react-query";
import HttpService from "../../services/http";
import { User } from "../../components/pages/dashboard/users/UsersListComponent";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { queryKeys } from "../../constants/queryKeys";
import { queryClient } from "../../providers/QueryProvider";
import useAuth from "../useAuth";
import { useNavigate } from "react-router-dom";

type SearchUsersProp = {
  customer_identification: string;
  entityIdentification: string;
  pageParam: number;
  limit: number;
  query?: string;
};

type DeleteOwnerProp = {
  entity_identification: string;
  user_identification: string;
  customer_identification: string;
  validation: string;
};
export const useGetSearchUsers = ({
  customer_identification,
  entityIdentification,
  pageParam,
  limit,
  query,
}: SearchUsersProp) => {
  return useQuery<any, any, any>(
    [
      queryKeys.searchUsers,
      pageParam,
      limit,
      customer_identification,
      entityIdentification,
      query,
    ],
    async () => {
      return await HttpService.get(
        `/${customer_identification}/${entityIdentification}/user/search?query=${encodeURIComponent(
          query ?? ""
        )}`,
        {
          auth: HttpService.getToken(),
        }
      ).then((data) => {
        return data?.data?.element;
      });
    },
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useDeleteOwner = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  return useMutation<DeleteOwnerProp, AxiosError<any, any>, any>(
    ({
      user_identification,
      entity_identification,
      customer_identification,
      validation,
    }: DeleteOwnerProp) =>
      HttpService.delete(
        `/${customer_identification}/${entity_identification}/user/owner/${user_identification}`,
        {
          data: {
            validation: validation,
          },
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("User account deleted successfully");
        localStorage.removeItem("_umd_credential");
        localStorage.removeItem("_umd_oauth_credential");
        localStorage.removeItem("_umd_service");
        localStorage.removeItem("umd_users_list_limit");

        auth.logout();

        queryClient.clear();

        navigate("/");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};
