import { useQuery } from "react-query";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { useEffect, useRef } from "react";
import QRCodeStyling from "livebook-qr-code";
import html2canvas from "html2canvas";

const qrCode = new QRCodeStyling({
  width: 800,
  height: 800,
  type: "svg",
  qrOptions: { errorCorrectionLevel: "H" },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 5,
  },
  dotsOptions: { color: "#000000", type: "square" },
  backgroundOptions: { color: "#FFFFFF" },
});

const CreateUserQrCode = () => {
  const [t] = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { user, customer_identification } = useAuth();

  const { data: res } = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleDownloadQrCode = () => {
    html2canvas(
      document.getElementById(
        `qr-${user.entityIdentification}`
      ) as HTMLCanvasElement
    ).then((canvas) => {
      let anchor = document.createElement("a");
      anchor.href = canvas.toDataURL("image/png");
      anchor.download = `create-user-${user.entityIdentification}.png`;
      anchor.click();
      anchor.remove();
    });
  };

  useEffect(() => {

    if (ref.current) {
      ref.current.innerHTML = "";
      const tempQR = new QRCodeStyling({
        width: 250, // Displayed size
        height: 250,
        type: "svg",
        qrOptions: { errorCorrectionLevel: "H" },
        imageOptions: { crossOrigin: "anonymous", margin: 5 },
        dotsOptions: { color: "#000000", type: "square" },
        backgroundOptions: { color: "#FFFFFF" },
      });
      tempQR.update({
        data: `${process.env.REACT_APP_HOST_URL}/user/${user.entityIdentification}/registration`,
        image:
            res?.data.element.cdn_logo_project_path ||
            process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL,
      });
      tempQR.append(ref.current);
    }
  }, []);

  useEffect(() => {
    qrCode.update({
      data: `${process.env.REACT_APP_HOST_URL}/user/${user.entityIdentification}/registration`,
      image:
        res?.data.element.cdn_logo_project_path ||
        process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL,
    });
  }, [res?.data.element.cdn_logo_project_path, user.entityIdentification]);

  return (
      <div className="w-full">
      <center className="w-full mb-4">
        <div ref={ref} id={`qr-${user.entityIdentification}`} style={{ width: 300, height: 300 }}/>
      </center>

      <div className="d-flex justify-content-center align-items-center">
        <Button className="bg-sky-500 w-full" onClick={handleDownloadQrCode}>
          {t("dashboard.createUserQr.btn-02")}
        </Button>
      </div>
    </div>
  );
};

export default CreateUserQrCode;
