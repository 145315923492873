import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import {
  INormalizedLmcDataType,
  deadlineCardFormProps,
  lmcOptionComponentProps,
} from "./types";
import LmcUtil from "./util";
import { Button, Label, Select, Textarea, TextInput } from "flowbite-react";
import { HiTrash } from "react-icons/hi";
import LMCButtons from "../../../public/LMCButtons";
import { currencyMapper } from "./AmountCard";

const uniqueValues = (arr: Array<any>, key: string) => {
  const seen = new Set();
  for (const obj of arr) {
    const value = obj[key];
    if (value !== null && value !== "" && seen.has(value)) {
      return false;
    }
    seen.add(value);
  }
  return true;
};

const validatePrecision = (value: number, precision: number) => {
  if (typeof value !== "number") {
    throw new Error("Input value must be a number");
  }

  const stringValue = value.toString();
  const decimalIndex = stringValue.indexOf(".");

  if (decimalIndex === -1) {
    return true; // No decimal places
  }

  const decimalPlaces = stringValue.length - decimalIndex - 1;
  return decimalPlaces <= precision;
};

const atLeastOneItemIsFilled = (arr: Array<{ value: number }>) => {
  let hasOne = arr.find(
    (item) =>
      Object.keys(item).length > 0 && item.value > 0 && item.value !== null
  );

  return hasOne ? true : false;
};

const schema = Joi.object()
  .keys({
    moneyType: Joi.string()
      .valid("amount", "percentage")
      .required()
      .label("Type")
      .messages({ "any.only": "One type must be selected" }),
    moneyValues: Joi.array()
      .items(
        Joi.object({
          value: Joi.number()
            .custom((value, helper) => {
              if (!validatePrecision(value, 2)) {
                // Define the error message using helper.message()
                return helper.message(
                  "Number must have at most 2 decimal places" as any
                );
              }
              return value;
            })
            .positive()
            .min(1)
            .max(9999999999)
            // .required()
            .allow(null)
            .allow("")
            .optional()
            .label("Default amount"),
        })
      )
      .min(1)
      .max(3)
      .required()
      .custom((value, helper) => {
        if (!uniqueValues(value, "value")) {
          return helper.message(
            "Default amounts must contain unique values for default amount" as any
          );
        }
        return value;
      })
      .custom((value, helper) => {
        if (!atLeastOneItemIsFilled(value)) {
          return helper.message(
            "Default amounts must contain at least one value" as any
          );
        }
        return value;
      })
      .label("Default amounts")
      .messages({
        "array.includesRequiredKnowns":
          "Add at least one value to the quick access buttons",
      }),
    moneyDefaultValue: Joi.string().optional().label("Default value"),
    moneyCurrency: Joi.alternatives().conditional("moneyType", {
      is: "amount",
      then: Joi.string().required().label("Default value"),
      otherwise: Joi.optional(),
    }),
    monyCustomValue: Joi.boolean().required().label("Custom value"),
    timeValues: Joi.array()
      .items(
        Joi.object({
          value: Joi.number()
            .custom((value, helper) => {
              if (!validatePrecision(value, 2)) {
                // Define the error message using helper.message()
                return helper.message(
                  "Number must have at most 2 decimal places" as any
                );
              }
              return value;
            })
            .positive()
            .min(1)
            .max(9999)
            // .required()
            .allow(null)
            .allow("")
            .optional()
            .label("Default amount"),
        })
      )
      .min(1)
      .max(3)
      .required()
      .custom((value, helper) => {
        if (!uniqueValues(value, "value")) {
          return helper.message(
            "Default dates must contain unique values for default date" as any
          );
        }
        return value;
      })
      .custom((value, helper) => {
        if (!atLeastOneItemIsFilled(value)) {
          return helper.message(
            "Default dates must contain at least one value" as any
          );
        }
        return value;
      })
      .label("Default dates")
      .messages({
        "array.includesRequiredKnowns":
          "Add at least one value to the quick access buttons",
      }),
    timeDefaultValue: Joi.string().optional().label("Default value"),
    timeCustomValue: Joi.boolean().required().label("Custom value"),
    comment: Joi.string().max(100).optional().allow("", null).label("Comment"),
  })
  .required();

const DeadlineCard = ({
  label,
  optionData,
  onSubmitCardData,
  playgroundDataHandler,
  handleCancelEdit,
  tutorial,
}: lmcOptionComponentProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    trigger,
  } = useForm<deadlineCardFormProps>({
    resolver: joiResolver(schema),
    // mode: "all",
  });

  const moneyFieldArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "moneyValues", // unique name for your Field Array
  });

  const timeFieldArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "timeValues", // unique name for your Field Array
  });

  useEffect(() => {
    if (optionData) {
      let denormalizedData = LmcUtil.unwrapper(
        "DEADLINE_CARD",
        optionData
      ) as deadlineCardFormProps;

      reset({ ...denormalizedData });
    }
  }, [optionData, reset]);

  const onSubmit = (data: deadlineCardFormProps) => {
    const newData = {
      ...data,
      moneyValues: data.moneyValues
        .filter((item: any) => item.value !== null)
        .filter((item: any) => item.value !== "")
        .map((v) => v.value),
      timeValues: data.timeValues
        .filter((item: any) => item.value !== null)
        .filter((item: any) => item.value !== "")
        .map((v) => v.value),
    };

    const normalizedData = LmcUtil.wrapper(
      "DEADLINE_CARD",
      newData
    ) as INormalizedLmcDataType;

    onSubmitCardData("DEADLINE_CARD", normalizedData).then(() => {
      reset();
    });
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      const newData = {
        ...value,
        moneyValues: value.moneyValues
          ?.filter((item: any) => item.value !== null)
          ?.filter((item: any) => item.value !== "")
          ?.map((v) => v?.value),
        timeValues: value.timeValues
          ?.filter((item: any) => item.value !== null)
          ?.filter((item: any) => item.value !== "")
          ?.map((v) => v?.value),
      };

      playgroundDataHandler({
        type: "DEADLINE_CARD",
        data: LmcUtil.wrapper(
          "DEADLINE_CARD",
          newData
        ) as INormalizedLmcDataType,
      });
    });
  }, [playgroundDataHandler, watch]);

  return (
    <div className="lmc-card point-card w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <Label
            htmlFor="moneyType"
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.deadlineCard.label1")}
          </Label>
          <Select
            color={!!errors.moneyType ? "failure" : ""}
            aria-label="Default select example"
            helperText={<> {errors.moneyType?.message}</>}
            {...register("moneyType", {
              onChange(event) {
                reset({
                  moneyType: event.target.value,
                  comment: "",
                  moneyCurrency: "",
                  moneyValues: [],
                  //moneyDefaultValue: "",
                  monyCustomValue: false,
                  timeCustomValue: false,
                  timeValues: [],
                  //timeDefaultValue: "",
                });
              },
            })}
          >
            <option value="">
              {t("dashboard.features.lmc.deadlineCard.placeholder1")}
            </option>
            <option value="amount">
              {t("dashboard.features.lmc.deadlineCard.amount")}
            </option>
            <option value="percentage">
              {t("dashboard.features.lmc.deadlineCard.percentage")}
            </option>
          </Select>
        </div>

        <div className="w-full h-px bg-gray-300 mb-3" />

        {watch("moneyType") && watch("moneyType") === "amount" && (
          <React.Fragment>
            <div className="mb-3">
              <Label
                htmlFor="moneyCurrency"
                className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                {t("dashboard.features.lmc.deadlineCard.label4")}
              </Label>
              <Select
                color={!!errors.moneyCurrency ? "failure" : ""}
                aria-label="Default select example"
                helperText={<> {errors.moneyCurrency?.message}</>}
                {...register("moneyCurrency")}
              >
                <option value="">
                  {t("dashboard.features.lmc.deadlineCard.placeholder4")}
                </option>
                {Object.entries(currencyMapper).map(
                  ([code, { symbol, name }]) => (
                    <option key={code} value={code}>
                      {`${name} (${symbol})`}
                    </option>
                  )
                )}
              </Select>
            </div>
            <div className="w-full h-px bg-gray-300 mb-3" />
          </React.Fragment>
        )}

        {moneyFieldArray.fields.map((item, index) => (
          <div className="mb-3" key={item.id}>
            <Label className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
              {t("dashboard.features.lmc.deadlineCard.key13") as string}
            </Label>
            <div className="grid grid-cols-7">
              <div
                className={`${
                  moneyFieldArray.fields.length > 1
                    ? "col-span-6"
                    : "col-span-7"
                }`}
              >
                <TextInput
                  helperText={
                    <>
                      {errors.moneyValues?.[index]?.value?.message ||
                      (watch("moneyType") === "percentage" &&
                        (watch("moneyValues")?.[index]?.value as number) > 100)
                        ? `"Default amount" must be less than or equal to 100`
                        : ""}
                    </>
                  }
                  color={
                    !!errors.moneyValues?.[index]?.value ||
                    (watch("moneyType") === "percentage" &&
                      (watch("moneyValues")?.[index]?.value as number) > 100)
                      ? "failure"
                      : ""
                  }
                  type="number"
                  min={1}
                  step=".01"
                  max={watch("moneyType") === "percentage" ? 100 : undefined}
                  placeholder={
                    t(
                      "dashboard.features.lmc.deadlineCard.placeholder2"
                    ) as string
                  }
                  {...register(`moneyValues.${index}.value`, {
                    onChange(event) {
                      trigger("moneyValues");
                    },
                  })}
                  onWheel={(e) => e.currentTarget.blur()}
                  // onBlur={() => trigger("moneyValues")}
                />
              </div>
              {moneyFieldArray.fields.length > 1 && (
                <div className="col-span-1">
                  <Button
                    color={""}
                    className="text-gray-300"
                    onClick={() => moneyFieldArray.remove(index)}
                  >
                    <HiTrash size={22} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}

        {watch("moneyValues")?.length < 3 && (
          <Button
            type="button"
            color="light"
            onClick={() => moneyFieldArray.append({ value: null })}
            className="mb-3 w-full"
          >
            {t("dashboard.features.lmc.deadlineCard.key1")}
          </Button>
        )}

        {!!errors.moneyValues && (
          <span className="text-sm text-red-600 block mb-3">
            {errors.moneyValues?.message?.toString()}
          </span>
        )}

        <div className="mb-3">
          <div className="flex items-center gap-2">
            <label className="switch shrink-0">
              <input type="checkbox" {...register("monyCustomValue")} />
              <div className="slider"></div>
            </label>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.features.lmc.deadlineCard.label5")}
            </span>
          </div>
        </div>

        <div className="w-full h-px bg-gray-300 my-4" />

        {timeFieldArray.fields.map((item, index) => (
          <div className="mb-3" key={item.id}>
            <Label className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
              {t("dashboard.features.lmc.deadlineCard.key14") as string}
            </Label>
            <div className="grid grid-cols-7 items-center gap-2">
              <div
                className={`${
                  timeFieldArray.fields.length > 1 ? "col-span-5" : "col-span-6"
                }`}
              >
                <TextInput
                  helperText={<>{errors.timeValues?.[index]?.value?.message}</>}
                  color={
                    !!errors.timeValues?.[index]?.value
                      ? // ||
                        // (watch("moneyType") === "percentage" &&
                        //   watch("timeValues")[index] > 100)
                        "failure"
                      : ""
                  }
                  type="number"
                  min={1}
                  //max={watch("moneyType") === "percentage" ? 100 : undefined}
                  placeholder={
                    t(
                      "dashboard.features.lmc.deadlineCard.placeholder2"
                    ) as string
                  }
                  {...register(`timeValues.${index}.value`, {
                    onChange(event) {
                      trigger("timeValues");
                    },
                  })}
                  onWheel={(e) => e.currentTarget.blur()}
                  // onBlur={() => trigger("timeValues")}
                />
              </div>
              <div className="col-span-1 text-gray-500 text-sm">
                {t("dashboard.features.lmc.deadlineCard.key15")}
              </div>
              {timeFieldArray.fields.length > 1 && (
                <div className="col-span-1">
                  <Button
                    color={""}
                    className="text-gray-300"
                    onClick={() => timeFieldArray.remove(index)}
                  >
                    <HiTrash size={22} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}

        {watch("timeValues")?.length < 3 && (
          <Button
            type="button"
            color="light"
            onClick={() => timeFieldArray.append({ value: null })}
            className="mb-3 w-full"
          >
            {t("dashboard.features.lmc.deadlineCard.key6")}
          </Button>
        )}

        {!!errors.timeValues && (
          <span className="text-sm text-red-600 block mb-3">
            {errors.timeValues?.message?.toString()}
          </span>
        )}

        <div className="mb-3">
          <div className="flex items-center gap-2">
            <label className="switch shrink-0">
              <input type="checkbox" {...register("timeCustomValue")} />
              <div className="slider"></div>
            </label>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.features.lmc.deadlineCard.label7")}
            </span>
          </div>
        </div>

        <div className="w-full h-px bg-gray-300 my-4" />

        <div className="mb-3">
          <Label
            htmlFor="comment"
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.amountCard.label4")}
          </Label>
          <Textarea
            color={!!errors.comment ? "failure" : ""}
            rows={3}
            placeholder={
              t("dashboard.features.lmc.amountCard.placeholder4") as string
            }
            {...register("comment")}
          />

          {errors.comment?.message}
        </div>
        <LMCButtons tutorial={tutorial} handleCancelEdit={handleCancelEdit} />
      </form>
    </div>
  );
};

export const DeadlineCardUi = ({
  data,
}: {
  data: INormalizedLmcDataType | null;
}) => {
  const { t } = useTranslation();

  return (
    <div className="self-stretch  flex-col justify-start items-center gap-6 flex">
      <h4 className="text-2xl font-bold text-gray-800">
        {t("dashboard.features.loginTemplate.tempPart.btn-01")}
      </h4>

      <div className="w-full bg-white rounded-2xl flex-col justify-start items-center gap-6 inline-flex">
        {/* <div className="self-stretch px-4 pt-4 flex-col justify-start items-center gap-2.5 flex">
        <div className="self-stretch p-4 rounded-lg border border-dashed border-gray-300 flex-col justify-center items-center gap-3 flex">
          <div className="self-stretch px-2.5 justify-around items-center inline-flex">
            <div className="flex-col justify-center items-center gap-1 inline-flex">
              <div className="text-center text-zinc-500  text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.deadlineCard.key7")}
              </div>
              <div className="text-center text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                25%
              </div>
            </div>
            <div className="flex-col justify-center items-center gap-1 inline-flex">
              <div className="text-center text-zinc-500  text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.deadlineCard.key8")}
              </div>
              <div className="text-center text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                Jul 14, 2024
              </div>
            </div>
          </div>
          <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
            <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
              <div className="text-center text-red-600 text-base font-semibold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.deadlineCard.key9")}
              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div className="self-stretch px-4 pb-5 flex-col justify-center items-center gap-3 flex pt-4">
          <div className="self-stretch justify-center items-start gap-3 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-center items-center gap-3 inline-flex">
              <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.deadlineCard.key10")}
              </div>

              {data?.options?.custom_value && (
                <div className="self-stretch h-11 flex-col justify-center items-center gap-4 flex">
                  <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                      <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                        {t("dashboard.features.lmc.deadlineCard.key11")}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {data?.pre_defined_value?.values?.map((v, index) => {
                if (
                  v > 0 &&
                  (data.pre_defined_value.type === "percentage"
                    ? v <= 100
                    : v <= 9999999999)
                )
                  return (
                    <div
                      key={index}
                      className="self-stretch h-11 flex-col justify-center items-center gap-4 flex"
                    >
                      <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                        <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                          <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                            {data.pre_defined_value.type === "amount"
                              ? currencyMapper[
                                  data.pre_defined_value.currency as string
                                ].symbol
                              : ""}
                            {data.pre_defined_value.type === "amount"
                              ? new Intl.NumberFormat().format(Math.abs(+v))
                              : Math.abs(+v)}
                            {data.pre_defined_value.type === "percentage"
                              ? "%"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                else return null;
              })}
            </div>
            <div className="grow shrink basis-0 flex-col justify-center items-center gap-3 inline-flex">
              <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.deadlineCard.key12")}
              </div>
              {data?.options?.pre_defined_time?.custom_value && (
                <div className="self-stretch h-11 flex-col justify-center items-center gap-4 flex">
                  <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                      <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                        {t("dashboard.features.lmc.deadlineCard.key11")}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {data?.options?.pre_defined_time?.values?.map(
                (t: number, index: number) => {
                  if (t > 0 && t <= 9999)
                    return (
                      <div
                        key={index}
                        className="self-stretch h-11 flex-col justify-center items-center gap-4 flex"
                      >
                        <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                          <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                            <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                              {Math.abs(+t)} Days
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  else return null;
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeadlineCard;
