import { Dropdown, Label, Spinner, Textarea, TextInput } from "flowbite-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { BiSolidEnvelope, BiTrash } from "react-icons/bi";
import { FaPencil, FaUserMinus } from "react-icons/fa6";
import useAuth from "../hooks/useAuth";
import { Copy } from "./Groups";
import UsersListComponent from "../components/pages/dashboard/users/UsersListComponent";
import { AiOutlineLoading } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import {
  useDeleteGroup,
  useDeleteUsersFromGroup,
  useGetGroup,
  useGetGroupUsers,
  useUpdateGroup,
} from "../hooks/groups";
import SendPersonalMessage from "../components/pages/dashboard/entities/SendPersonalMessage";
import {
  SEND_MESSAGE_TYPE,
  USER_LIST_COMPONENT_TYPE,
} from "../constants/types";
import UpgradeModal from "../components/modals/UpgradeModal";
import { PlansModal } from "../components/modals/PlansModal";

const schema = Joi.object({
  group_name: Joi.string().trim().min(2).max(20).required().label("Group name"),
  group_description: Joi.string()
    .trim()
    .min(2)
    .max(300)
    .required()
    .label("Group description"),
});
const limit = 10;

const ViewEntityGroup = () => {
  const { t } = useTranslation();
  const { user, customer_identification, userEndedSubscription } = useAuth();
  const { group_identification } = useParams();
  const navigate = useNavigate();
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());
  const [showSendMsgModal, setShowSendMsgModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(false);
  const [excludeModal, setExcludeModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const queryData = {
    groupId: group_identification as string,
    customer_identification: customer_identification,
    entityIdentification: user.entityIdentification,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const { mutate: deleteGroup, isLoading: isDeletingGroup } = useDeleteGroup();
  const { mutate: updateGroup, isLoading: isUpdatingGroup } = useUpdateGroup();
  const { mutate: removeUsers, isLoading: isRemoving } =
    useDeleteUsersFromGroup();
  const {
    isLoading,
    isError,
    data: groupData,
    refetch,
  } = useGetGroup(queryData);

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    isSuccess,
    data: dataUsers,
    refetch: refetchUsers,
  } = useGetGroupUsers({ ...queryData, limit: limit, pageParam: offset });

  const handleDeleteUserFromGroup = () => {
    removeUsers(
      {
        customer_identification: customer_identification,
        entityIdentification: user.entityIdentification,
        group_identification: group_identification,
        users: selectedRowIds.values().toArray(),
      },
      {
        onSuccess: () => {
          setExcludeModal(false);
          setSelectedRowIds(new Set());
          refetchUsers();
        },
      }
    );
  };

  if (isLoading || isLoadingUsers) return <Spinner size="sm" />;

  if (isError || isErrorUsers)
    return (
      <Button
        color="light"
        onClick={() => {
          refetch();
          refetchUsers();
        }}
        size="sm"
      >
        {t("dashboard.entities.viewEntityUser.btn-01")}
      </Button>
    );

  const handleDeleteGroup = () => {
    deleteGroup(
      {
        ...queryData,
      },
      {
        onSuccess: () => {
          setOpenModal(false);
          navigate(-1);
        },
      }
    );
  };

  const handleUpdateGroup = () => {
    reset(groupData);
    setShowUpdateGroupModal(true);
  };

  const onSubmitUpdate = (values: any) => {
    updateGroup(
      {
        ...queryData,
        data: values,
      },
      {
        onSuccess: () => {
          setShowUpdateGroupModal(false);
          refetch();
        },
      }
    );
  };

  return (
    <div className="entity-user-page">
      <div className="grid grid-cols-11">
        <div className="col-span-12">
          <div className="p-6 flex-col justify-start items-start gap-6 inline-flex bg-white w-full">
            <div className="self-stretch lg:justify-start lg:items-start justify-between items-center gap-2 inline-flex">
              <Link
                to={"/control-panel/groups"}
                className="gap-2 inline-flex items-center"
              >
                <div className="w-5 h-5 relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.70711 16.7071C9.31659 17.0976 8.68342 17.0976 8.2929 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.2929 3.29289C8.68342 2.90237 9.31659 2.90237 9.70712 3.29289C10.0976 3.68342 10.0976 4.31658 9.70712 4.70711L5.41422 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41422 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                </div>
                <div className="grow shrink basis-0 text-gray-500 text-sm font-semibold font-['Figtree'] leading-tight">
                  {t("dashboard.groupList.backToGroups")}
                </div>
              </Link>
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-4 flex">
              <div className="h-8 justify-start items-center gap-2 inline-flex">
                <div className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                  {groupData?.group_name}
                </div>
                <button
                  onClick={() => handleUpdateGroup()}
                  disabled={userEndedSubscription}
                  data-svg-wrapper
                  className="relative"
                >
                  <FaPencil className="text-gray-700" />
                </button>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch flex-col justify-start items-start flex">
                  {/* identification */}
                  <div className="self-stretch text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                    {t("dashboard.groupList.groupId")}:&nbsp;
                    <Copy target={group_identification as string} />
                  </div>
                </div>
              </div>

              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch flex-col justify-start items-start flex">
                  <div className="self-stretch text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                    {dataUsers?.pagination?.total}{" "}
                    {t("dashboard.groupList.userCount")}
                  </div>
                </div>
              </div>

              <div className="self-stretch justify-start xl:justify-between items-start gap-3 inline-flex flex-wrap w-full ">
                <div className="self-stretch justify-start items-start gap-3 inline-flex flex-wrap">
                  <button
                    onClick={() =>
                      user?.customer_top_plan?.includes("FREE")
                        ? setShowUpgradeModal(true)
                        : setShowSendMsgModal(true)
                    }
                    disabled={
                      userEndedSubscription || dataUsers?.data?.length <= 0
                    }
                    className={` ${
                      selectedRowIds.size > 0 ? "w-80" : "w-64"
                    } h-[38px] px-[17px] py-[9px] ${
                      dataUsers?.data?.length > 0 ? "bg-sky-500" : "bg-gray-300"
                    } rounded-md shadow justify-center text-white text-sm font-medium font-['Inter'] leading-tight flex items-center gap-2 truncate`}
                  >
                    <BiSolidEnvelope size={22} className="shrink-0" />
                    <span className="truncate">
                      {selectedRowIds?.size > 0
                        ? t("dashboard.groupList.sendToSelectedUsers")
                        : t("dashboard.groupList.sendToAll")}
                    </span>
                    {user?.customer_top_plan?.includes("FREE") && (
                      <img
                        title="Super User Manager"
                        loading="lazy"
                        src="/images/upgrade.svg"
                        alt="Upgrade"
                        className="img-fluid"
                      />
                    )}
                  </button>

                  {selectedRowIds.size > 0 && (
                    <button
                      onClick={() => {
                        setExcludeModal(true);
                      }}
                      className="xl:flex hidden w-full md:w-52 h-[38px] px-[17px] py-[9px] bg-white rounded-md shadow justify-center text-gray-700 text-sm font-medium font-['Inter'] leading-tight flex items-center gap-2 truncate border border-solid"
                    >
                      <FaUserMinus
                        size={18}
                        color="#9CA3AF"
                        className="shrink-0"
                      />
                      {t("dashboard.groupList.excludeFromGroup")}
                    </button>
                  )}
                </div>
                <Dropdown
                  label={""}
                  renderTrigger={() => (
                    <button className="shrink basis-0 h-[38px] px-[17px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-center items-center xl:hidden flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 6C8.89543 6 8 5.10457 8 4C8 2.89543 8.89543 2 10 2C11.1046 2 12 2.89543 12 4C12 5.10457 11.1046 6 10 6Z"
                          fill="#9CA3AF"
                        />
                        <path
                          d="M10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12Z"
                          fill="#9CA3AF"
                        />
                        <path
                          d="M10 18C8.89543 18 8 17.1046 8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18Z"
                          fill="#9CA3AF"
                        />
                      </svg>
                    </button>
                  )}
                  className="font-medium font-['Inter'] leading-tight"
                >
                  {selectedRowIds.size > 0 && (
                    <Dropdown.Item onClick={() => setExcludeModal(true)}>
                      {t("dashboard.groupList.excludeFromGroup")}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item onClick={() => setOpenModal(true)}>
                    {isDeletingGroup ? (
                      <Spinner />
                    ) : (
                      <>{t("dashboard.groupList.deleteGroup")}</>
                    )}
                  </Dropdown.Item>
                </Dropdown>
                <button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  disabled={userEndedSubscription}
                  className="xl:flex hidden w-full md:w-52 h-[38px] px-[17px] py-[9px] bg-white rounded-md shadow justify-center text-gray-700 text-sm font-medium font-['Inter'] leading-tight flex items-center gap-2 truncate border border-solid"
                >
                  {isDeletingGroup ? (
                    <Spinner />
                  ) : (
                    <>
                      <BiTrash size={22} className="shrink-0 text-red-500" />
                      {t("dashboard.groupList.deleteGroup")}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="px-2 pb-3">
            <UsersListComponent
              users={dataUsers?.data}
              isError={isErrorUsers}
              isLoading={isLoadingUsers}
              isSuccess={isSuccess}
              refetch={refetchUsers}
              selectedRowIds={selectedRowIds}
              setSelectedRowIds={setSelectedRowIds}
              type={USER_LIST_COMPONENT_TYPE.GROUPS}
              pagination={dataUsers?.pagination}
              offset={offset}
              setOffset={setOffset}
              limit={limit}
            />
          </div>
        </div>
      </div>

      <Modal
        show={excludeModal}
        size="md"
        onClose={() => setExcludeModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {t("dashboard.groupList.excludeUsersTitle")}
            </h3>
            <h2 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              <small>{t("dashboard.groupList.excludeUsersNote")}</small>
            </h2>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={handleDeleteUserFromGroup}
                isProcessing={isRemoving}
                disabled={isRemoving}
              >
                {t("dashboard.entities.viewEntityUser.key10")}
              </Button>
              <Button
                color="gray"
                onClick={() => {
                  setExcludeModal(false);
                }}
              >
                {t("dashboard.entities.viewEntityUser.key11")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete group modal */}
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {t("dashboard.groupList.key3")}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={handleDeleteGroup}>
                {t("dashboard.groupList.key4")}
              </Button>
              <Button
                color="gray"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                {t("dashboard.groupList.key5")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit group modal */}
      <Modal
        show={showUpdateGroupModal}
        onClose={() => setShowUpdateGroupModal(false)}
      >
        <Modal.Header className="border-0 text-zinc-900 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.groupList.key1")}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmitUpdate)}>
            <div className="mb-6">
              <Label
                htmlFor="group_name"
                value={t("dashboard.groupList.label-01").toString()}
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
              />
              <TextInput
                id="group_name"
                color={!!errors.group_name ? "failure" : ""}
                type="text"
                {...register("group_name")}
                helperText={<>{errors.group_name?.message as string}</>}
              />
            </div>

            <div className="mb-6">
              <Label
                htmlFor="group_description"
                value={t("dashboard.groupList.label-02").toString()}
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
              />
              <Textarea
                id="group_description"
                color={!!errors.group_description ? "failure" : ""}
                rows={6}
                helperText={<>{errors.group_description?.message as string}</>}
                {...register("group_description")}
              />
            </div>

            <div className="flex justify-end">
              <Button
                type="submit"
                disabled={isUpdatingGroup}
                isProcessing={isUpdatingGroup}
                processingSpinner={
                  <>
                    <AiOutlineLoading className="h-6 w-6 animate-spin" />
                  </>
                }
                className="bg-sky-500"
              >
                {t("dashboard.groupList.btn-update")}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <SendPersonalMessage
        show={showSendMsgModal}
        ctrFn={setShowSendMsgModal}
        toSendMsgUserId={""}
        entity_identification={user.entityIdentification as string}
        entity_user_identification={customer_identification}
        userName={"User name!"}
        group_identification={group_identification as string}
        users={selectedRowIds}
        onResolved={() => setSelectedRowIds(new Set())}
        type={
          selectedRowIds.size > 0
            ? SEND_MESSAGE_TYPE.MULTI
            : SEND_MESSAGE_TYPE.ALL
        }
      />
      <UpgradeModal
        setShowModal={setShowUpgradeModal}
        showModal={showUpgradeModal}
        setShowPlansModal={setShowPlansModal}
      />
      <PlansModal
        showPlansModal={showPlansModal}
        setShowPlansModal={setShowPlansModal}
      />
    </div>
  );
};

export default ViewEntityGroup;
