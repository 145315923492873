import React, { useState } from "react";
// import {
//   Button,
//   Col,
//   Collapse,
//   Form,
//   Modal,
//   Row,
//   Spinner,
// } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import * as Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../../../../services/http";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button, Label, Modal, Select, TextInput } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { FaChevronDown, FaChevronUp, FaUserPlus } from "react-icons/fa6";
import { queryClient } from "../../../../providers/QueryProvider";

type User = {
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  user_birth_date: string;
  user_country_code: string;
  user_user_name: string;
  user_type: string;
  user_role: string;
};

const CountryOptions = () => {
  return (
    <React.Fragment>
      <option value="AF">Afghanistan</option>
      <option value="AX">Aland Islands</option>
      <option value="AL">Albania</option>
      <option value="DZ">Algeria</option>
      <option value="AS">American Samoa</option>
      <option value="AD">Andorra</option>
      <option value="AO">Angola</option>
      <option value="AI">Anguilla</option>
      <option value="AQ">Antarctica</option>
      <option value="AG">Antigua And Barbuda</option>
      <option value="AR">Argentina</option>
      <option value="AM">Armenia</option>
      <option value="AW">Aruba</option>
      <option value="AU">Australia</option>
      <option value="AT">Austria</option>
      <option value="AZ">Azerbaijan</option>
      <option value="BS">Bahamas</option>
      <option value="BH">Bahrain</option>
      <option value="BD">Bangladesh</option>
      <option value="BB">Barbados</option>
      <option value="BY">Belarus</option>
      <option value="BE">Belgium</option>
      <option value="BZ">Belize</option>
      <option value="BJ">Benin</option>
      <option value="BM">Bermuda</option>
      <option value="BT">Bhutan</option>
      <option value="BO">Bolivia</option>
      <option value="BA">Bosnia And Herzegovina</option>
      <option value="BW">Botswana</option>
      <option value="BV">Bouvet Island</option>
      <option value="BR">Brazil</option>
      <option value="IO">British Indian Ocean Territory</option>
      <option value="BN">Brunei Darussalam</option>
      <option value="BG">Bulgaria</option>
      <option value="BF">Burkina Faso</option>
      <option value="BI">Burundi</option>
      <option value="KH">Cambodia</option>
      <option value="CM">Cameroon</option>
      <option value="CA">Canada</option>
      <option value="CV">Cape Verde</option>
      <option value="KY">Cayman Islands</option>
      <option value="CF">Central African Republic</option>
      <option value="TD">Chad</option>
      <option value="CL">Chile</option>
      <option value="CN">China</option>
      <option value="CX">Christmas Island</option>
      <option value="CC">Cocos (Keeling) Islands</option>
      <option value="CO">Colombia</option>
      <option value="KM">Comoros</option>
      <option value="CG">Congo</option>
      <option value="CD">Congo, Democratic Republic</option>
      <option value="CK">Cook Islands</option>
      <option value="CR">Costa Rica</option>
      <option value="CI">Cote DIvoire</option>
      <option value="HR">Croatia</option>
      <option value="CU">Cuba</option>
      <option value="CY">Cyprus</option>
      <option value="CZ">Czech Republic</option>
      <option value="DK">Denmark</option>
      <option value="DJ">Djibouti</option>
      <option value="DM">Dominica</option>
      <option value="DO">Dominican Republic</option>
      <option value="EC">Ecuador</option>
      <option value="EG">Egypt</option>
      <option value="SV">El Salvador</option>
      <option value="GQ">Equatorial Guinea</option>
      <option value="ER">Eritrea</option>
      <option value="EE">Estonia</option>
      <option value="ET">Ethiopia</option>
      <option value="FK">Falkland Islands (Malvinas)</option>
      <option value="FO">Faroe Islands</option>
      <option value="FJ">Fiji</option>
      <option value="FI">Finland</option>
      <option value="FR">France</option>
      <option value="GF">French Guiana</option>
      <option value="PF">French Polynesia</option>
      <option value="TF">French Southern Territories</option>
      <option value="GA">Gabon</option>
      <option value="GM">Gambia</option>
      <option value="GE">Georgia</option>
      <option value="DE">Germany</option>
      <option value="GH">Ghana</option>
      <option value="GI">Gibraltar</option>
      <option value="GR">Greece</option>
      <option value="GL">Greenland</option>
      <option value="GD">Grenada</option>
      <option value="GP">Guadeloupe</option>
      <option value="GU">Guam</option>
      <option value="GT">Guatemala</option>
      <option value="GG">Guernsey</option>
      <option value="GN">Guinea</option>
      <option value="GW">Guinea-Bissau</option>
      <option value="GY">Guyana</option>
      <option value="HT">Haiti</option>
      <option value="HM">Heard Island & Mcdonald Islands</option>
      <option value="VA">Holy See (Vatican City State)</option>
      <option value="HN">Honduras</option>
      <option value="HK">Hong Kong</option>
      <option value="HU">Hungary</option>
      <option value="IS">Iceland</option>
      <option value="IN">India</option>
      <option value="ID">Indonesia</option>
      <option value="IR">Iran, Islamic Republic Of</option>
      <option value="IQ">Iraq</option>
      <option value="IE">Ireland</option>
      <option value="IM">Isle Of Man</option>
      <option value="IL">Israel</option>
      <option value="IT">Italy</option>
      <option value="JM">Jamaica</option>
      <option value="JP">Japan</option>
      <option value="JE">Jersey</option>
      <option value="JO">Jordan</option>
      <option value="KZ">Kazakhstan</option>
      <option value="KE">Kenya</option>
      <option value="KI">Kiribati</option>
      <option value="KR">Korea</option>
      <option value="KP">North Korea</option>
      <option value="KW">Kuwait</option>
      <option value="KG">Kyrgyzstan</option>
      <option value="LA">Lao Peoples Democratic Republic</option>
      <option value="LV">Latvia</option>
      <option value="LB">Lebanon</option>
      <option value="LS">Lesotho</option>
      <option value="LR">Liberia</option>
      <option value="LY">Libyan Arab Jamahiriya</option>
      <option value="LI">Liechtenstein</option>
      <option value="LT">Lithuania</option>
      <option value="LU">Luxembourg</option>
      <option value="MO">Macao</option>
      <option value="MK">Macedonia</option>
      <option value="MG">Madagascar</option>
      <option value="MW">Malawi</option>
      <option value="MY">Malaysia</option>
      <option value="MV">Maldives</option>
      <option value="ML">Mali</option>
      <option value="MT">Malta</option>
      <option value="MH">Marshall Islands</option>
      <option value="MQ">Martinique</option>
      <option value="MR">Mauritania</option>
      <option value="MU">Mauritius</option>
      <option value="YT">Mayotte</option>
      <option value="MX">Mexico</option>
      <option value="FM">Micronesia, Federated States Of</option>
      <option value="MD">Moldova</option>
      <option value="MC">Monaco</option>
      <option value="MN">Mongolia</option>
      <option value="ME">Montenegro</option>
      <option value="MS">Montserrat</option>
      <option value="MA">Morocco</option>
      <option value="MZ">Mozambique</option>
      <option value="MM">Myanmar</option>
      <option value="NA">Namibia</option>
      <option value="NR">Nauru</option>
      <option value="NP">Nepal</option>
      <option value="NL">Netherlands</option>
      <option value="AN">Netherlands Antilles</option>
      <option value="NC">New Caledonia</option>
      <option value="NZ">New Zealand</option>
      <option value="NI">Nicaragua</option>
      <option value="NE">Niger</option>
      <option value="NG">Nigeria</option>
      <option value="NU">Niue</option>
      <option value="NF">Norfolk Island</option>
      <option value="MP">Northern Mariana Islands</option>
      <option value="NO">Norway</option>
      <option value="OM">Oman</option>
      <option value="PK">Pakistan</option>
      <option value="PW">Palau</option>
      <option value="PS">Palestinian Territory, Occupied</option>
      <option value="PA">Panama</option>
      <option value="PG">Papua New Guinea</option>
      <option value="PY">Paraguay</option>
      <option value="PE">Peru</option>
      <option value="PH">Philippines</option>
      <option value="PN">Pitcairn</option>
      <option value="PL">Poland</option>
      <option value="PT">Portugal</option>
      <option value="PR">Puerto Rico</option>
      <option value="QA">Qatar</option>
      <option value="RE">Reunion</option>
      <option value="RO">Romania</option>
      <option value="RU">Russian Federation</option>
      <option value="RW">Rwanda</option>
      <option value="BL">Saint Barthelemy</option>
      <option value="SH">Saint Helena</option>
      <option value="KN">Saint Kitts And Nevis</option>
      <option value="LC">Saint Lucia</option>
      <option value="MF">Saint Martin</option>
      <option value="PM">Saint Pierre And Miquelon</option>
      <option value="VC">Saint Vincent And Grenadines</option>
      <option value="WS">Samoa</option>
      <option value="SM">San Marino</option>
      <option value="ST">Sao Tome And Principe</option>
      <option value="SA">Saudi Arabia</option>
      <option value="SN">Senegal</option>
      <option value="RS">Serbia</option>
      <option value="SC">Seychelles</option>
      <option value="SL">Sierra Leone</option>
      <option value="SG">Singapore</option>
      <option value="SK">Slovakia</option>
      <option value="SI">Slovenia</option>
      <option value="SB">Solomon Islands</option>
      <option value="SO">Somalia</option>
      <option value="ZA">South Africa</option>
      <option value="GS">South Georgia And Sandwich Isl.</option>
      <option value="ES">Spain</option>
      <option value="LK">Sri Lanka</option>
      <option value="SD">Sudan</option>
      <option value="SR">Suriname</option>
      <option value="SJ">Svalbard And Jan Mayen</option>
      <option value="SZ">Swaziland</option>
      <option value="SE">Sweden</option>
      <option value="CH">Switzerland</option>
      <option value="SY">Syrian Arab Republic</option>
      <option value="TW">Taiwan</option>
      <option value="TJ">Tajikistan</option>
      <option value="TZ">Tanzania</option>
      <option value="TH">Thailand</option>
      <option value="TL">Timor-Leste</option>
      <option value="TG">Togo</option>
      <option value="TK">Tokelau</option>
      <option value="TO">Tonga</option>
      <option value="TT">Trinidad And Tobago</option>
      <option value="TN">Tunisia</option>
      <option value="TR">Turkey</option>
      <option value="TM">Turkmenistan</option>
      <option value="TC">Turks And Caicos Islands</option>
      <option value="TV">Tuvalu</option>
      <option value="UG">Uganda</option>
      <option value="UA">Ukraine</option>
      <option value="AE">United Arab Emirates</option>
      <option value="GB">United Kingdom</option>
      <option value="US">United States</option>
      <option value="UM">United States Outlying Islands</option>
      <option value="UY">Uruguay</option>
      <option value="UZ">Uzbekistan</option>
      <option value="VU">Vanuatu</option>
      <option value="VE">Venezuela</option>
      <option value="VN">Vietnam</option>
      <option value="VG">Virgin Islands, British</option>
      <option value="VI">Virgin Islands, U.S.</option>
      <option value="WF">Wallis And Futuna</option>
      <option value="EH">Western Sahara</option>
      <option value="YE">Yemen</option>
      <option value="ZM">Zambia</option>
      <option value="ZW">Zimbabw</option>
    </React.Fragment>
  );
};

const schema = Joi.object({
  users: Joi.array()
    .unique("user_email")
    .items({
      user_email: Joi.string().trim().required().label("Email"),
      user_first_name: Joi.string()
        .trim()
        .min(3)
        .max(30)
        .optional()
        .allow(null, "")
        .label("First name"),

      user_last_name: Joi.string()
        .trim()
        .min(3)
        .max(150)
        .optional()
        .allow(null, "")
        .label("Last name"),
      user_user_name: Joi.string()
        .trim()
        .min(3)
        .max(30)
        .optional()
        .allow(null, "")
        .label("Username"),
      user_role: Joi.string()
        .trim()
        .min(3)
        .max(30)
        .optional()
        .allow(null, "")
        .label("Role"),
      user_type: Joi.string()
        .trim()
        .min(3)
        .max(30)
        .optional()
        .allow(null, "")
        .label("Type"),
      user_birth_date: Joi.date()
        .optional()
        .allow(null, "")
        .label("Birth date"),
      user_country_code: Joi.string()
        .trim()
        .optional()
        .allow(null, "")
        .label("Country code"),
    })
    .min(1)
    .required(),
});

const AddUserManuallyModal = ({
  show,
  onHide,
  size,
  className,
  onBackClick,
}: {
  show: boolean;
  onHide: () => void;
  size?: "lg" | "sm" | "xl" | "2xl";
  className?: string | undefined;
  onBackClick: () => void;
}) => {
  const [t] = useTranslation();
  const { customer_identification, user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
    clearErrors,
  } = useForm<{ users: User[] }>({
    defaultValues: {
      users: [
        {
          user_email: "",
          user_first_name: "",
          user_last_name: "",
          user_birth_date: "",
          user_country_code: "",
          user_user_name: "",
          user_type: "",
          user_role: "",
        },
      ],
    },
    resolver: joiResolver(schema),
  });

  const { fields, remove } = useFieldArray({
    name: "users",
    control,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [collapses, setCollapses] = useState<{ [key: string]: boolean }>({});

  const AddUserManuallyMutation = useMutation<any, AxiosError<any, any>, any>(
    (data: User) =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/user/add/manually`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (data, variables, context) => {
        toast.success("User added successfully");

        queryClient.invalidateQueries("entity-users");
        queryClient.invalidateQueries(["roles_list", customer_identification]);
        queryClient.invalidateQueries(["types_list", customer_identification]);

        setIsLoading(false);

        handleCloseModal();
      },
      onError: (err) => {
        setIsLoading(false);

        toast.error(err.response?.data?.message || err.message);

        if (err.response && err.response.data.element) {
          const errPayload: User = err.response.data.element;

          !!errPayload.user_email &&
            setError(`users.${0}.user_email`, {
              type: "required",
              message: errPayload.user_email,
            });
          !!errPayload.user_birth_date &&
            setError(`users.${0}.user_birth_date`, {
              type: "required",
              message: errPayload.user_birth_date,
            });
          !!errPayload.user_country_code &&
            setError(`users.${0}.user_country_code`, {
              type: "required",
              message: errPayload.user_country_code,
            });
          !!errPayload.user_first_name &&
            setError(`users.${0}.user_first_name`, {
              type: "required",
              message: errPayload.user_first_name,
            });
          !!errPayload.user_last_name &&
            setError(`users.${0}.user_last_name`, {
              type: "required",
              message: errPayload.user_last_name,
            });
          !!errPayload.user_role &&
            setError(`users.${0}.user_role`, {
              type: "required",
              message: errPayload.user_role,
            });
          !!errPayload.user_type &&
            setError(`users.${0}.user_type`, {
              type: "required",
              message: errPayload.user_type,
            });
          !!errPayload.user_user_name &&
            setError(`users.${0}.user_user_name`, {
              type: "required",
              message: errPayload.user_user_name,
            });
        }
      },
    }
  );

  const handleCloseModal = () => {
    reset();
    onHide();
  };

  const onSubmit = (data: { users: User[] }) => {
    let copyData = data;

    copyData.users = copyData.users.map((user) => {
      return {
        ...user,
        user_birth_date: user.user_birth_date
          ? moment(user.user_birth_date).format("YYYY-MM-DD")
          : "",
      };
    });

    setIsLoading(true);

    // Data to submit to backend
    AddUserManuallyMutation.mutate(copyData.users[0]);
  };

  return (
    <Modal
      show={show}
      onClose={handleCloseModal}
      size={size}
      className={className}
    >
      <Modal.Header className="border-b-0" as={"div"}>
        <h3 className="text-gray-900 text-lg font-semibold font-['Figtree'] leading-7 mb-2">
          {t("dashboard.addUserManually.title")}
        </h3>
        <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.addUserManually.text-01")} <br />
          {t("dashboard.addUserManually.text-02")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => (
            <div key={item.id}>
              <div className="mb-3">
                <div className="mb-3">
                  <Label
                    htmlFor={`user_email_${item.id}`}
                    value={t("dashboard.addUserManually.field-01").toString()}
                    className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                  />

                  <TextInput
                    key={item.id}
                    id={`user_email_${item.id}`}
                    type="email"
                    placeholder={
                      t("dashboard.addUserManually.placeholder-01") as string
                    }
                    color={
                      errors?.users &&
                      (!!errors?.users[index]?.user_email ||
                        (errors?.users && !!errors?.users[index]))
                        ? "failure"
                        : ""
                    }
                    helperText={
                      <>
                        {errors?.users &&
                          errors?.users[index]?.user_email?.message}
                      </>
                    }
                    {...register(`users.${index}.user_email` as const, {
                      onChange: (e) => {
                        clearErrors(`users.${index}.user_email`);

                        if (
                          fields.find(
                            (item) => item.user_email === e.target.value
                          )
                        )
                          setError(`users.${index}.user_email`, {
                            type: "required",
                            message: "Duplicate email",
                          });
                      },
                    })}
                  />
                </div>

                <div className="flex justify-center mb-3">
                  <Button
                    onClick={() =>
                      setCollapses({
                        ...collapses,
                        [item.id]: !collapses[item.id],
                      })
                    }
                    aria-controls={`collapse_more_${item.id}`}
                    aria-expanded={collapses[item.id]}
                    color={""}
                    className="text-right text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
                  >
                    {collapses[item.id] ? (
                      <span className="flex items-center gap-2">
                        <FaChevronUp size={14} />
                        {t("dashboard.addUserManually.btn-01")}{" "}
                        {t("dashboard.addUserManually.btn-01-less")}
                      </span>
                    ) : (
                      <span className="flex items-center gap-2">
                        <FaChevronDown size={14} />
                        {t("dashboard.addUserManually.btn-01")}{" "}
                        {t("dashboard.addUserManually.btn-01-more")}
                      </span>
                    )}
                  </Button>
                </div>

                {collapses[item.id] && (
                  <div id={`collapse_more_${item.id}`}>
                    <div className="grid grid-cols-12 gap-3">
                      <div className="md:col-span-6 col-span-12">
                        <div>
                          <Label
                            htmlFor={`user_first_name_${item.id}`}
                            value={t(
                              "dashboard.addUserManually.field-02"
                            ).toString()}
                            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                          />

                          <TextInput
                            id={`user_first_name_${item.id}`}
                            type="text"
                            placeholder={
                              t(
                                "dashboard.addUserManually.placeholder-02"
                              ) as string
                            }
                            color={
                              errors?.users &&
                              !!errors?.users[index]?.user_first_name
                                ? "failure"
                                : ""
                            }
                            helperText={
                              <>
                                {errors?.users &&
                                  errors?.users[index]?.user_first_name
                                    ?.message}
                              </>
                            }
                            {...register(
                              `users.${index}.user_first_name` as const
                            )}
                          />
                        </div>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <div>
                          <Label
                            htmlFor={`user_last_name_${item.id}`}
                            value={t(
                              "dashboard.addUserManually.field-03"
                            ).toString()}
                            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                          />

                          <TextInput
                            id={`user_last_name_${item.id}`}
                            type="text"
                            placeholder={
                              t(
                                "dashboard.addUserManually.placeholder-03"
                              ) as string
                            }
                            color={
                              errors?.users &&
                              !!errors?.users[index]?.user_last_name
                                ? "failure"
                                : ""
                            }
                            helperText={
                              <>
                                {errors?.users &&
                                  errors?.users[index]?.user_last_name?.message}
                              </>
                            }
                            {...register(
                              `users.${index}.user_last_name` as const
                            )}
                          />
                        </div>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <div>
                          <Label
                            htmlFor={`user_user_name_${item.id}`}
                            value={t(
                              "dashboard.addUserManually.field-04"
                            ).toString()}
                            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                          />

                          <TextInput
                            id={`user_user_name_${item.id}`}
                            type="text"
                            placeholder={
                              t(
                                "dashboard.addUserManually.placeholder-04"
                              ) as string
                            }
                            color={
                              errors?.users &&
                              !!errors?.users[index]?.user_user_name
                                ? "failure"
                                : ""
                            }
                            helperText={
                              <>
                                {errors?.users &&
                                  errors?.users[index]?.user_user_name?.message}
                              </>
                            }
                            {...register(
                              `users.${index}.user_user_name` as const
                            )}
                          />
                        </div>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <div>
                          <Label
                            htmlFor={`user_country_code_${item.id}`}
                            value={t(
                              "dashboard.addUserManually.field-05"
                            ).toString()}
                            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                          />

                          <Select
                            id={`user_country_code_${item.id}`}
                            color={
                              errors?.users &&
                              !!errors?.users[index]?.user_country_code
                                ? "failure"
                                : ""
                            }
                            helperText={
                              <>
                                {errors?.users &&
                                  errors?.users[index]?.user_country_code
                                    ?.message}
                              </>
                            }
                            {...register(
                              `users.${index}.user_country_code` as const
                            )}
                          >
                            <option value="">
                              {t("dashboard.addUserManually.select-option")}
                            </option>
                            <CountryOptions />
                          </Select>
                        </div>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <div>
                          <Label
                            htmlFor={`user_role_${item.id}`}
                            value={t(
                              "dashboard.addUserManually.field-06"
                            ).toString()}
                            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                          />

                          <TextInput
                            id={`user_role_${item.id}`}
                            type="text"
                            placeholder={
                              t(
                                "dashboard.addUserManually.placeholder-06"
                              ) as string
                            }
                            color={
                              errors?.users && !!errors?.users[index]?.user_role
                                ? "failure"
                                : ""
                            }
                            helperText={
                              <>
                                {errors?.users &&
                                  errors?.users[index]?.user_role?.message}
                              </>
                            }
                            {...register(`users.${index}.user_role` as const)}
                          />
                        </div>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <div>
                          <Label
                            htmlFor={`user_type_${item.id}`}
                            value={t(
                              "dashboard.addUserManually.field-07"
                            ).toString()}
                            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                          />

                          <TextInput
                            id={`user_type_${item.id}`}
                            type="text"
                            placeholder={
                              t(
                                "dashboard.addUserManually.placeholder-07"
                              ) as string
                            }
                            color={
                              errors?.users && !!errors?.users[index]?.user_type
                                ? "failure"
                                : ""
                            }
                            helperText={
                              <>
                                {errors?.users &&
                                  errors?.users[index]?.user_type?.message}
                              </>
                            }
                            {...register(`users.${index}.user_type` as const)}
                          />
                        </div>
                      </div>
                      <div className="md:col-span-6 col-span-12">
                        <div className="mb-3">
                          <Label
                            htmlFor={`user_birth_date_${item.id}`}
                            value={t(
                              "dashboard.addUserManually.field-08"
                            ).toString()}
                            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
                          />

                          <TextInput
                            id={`user_birth_date_${item.id}`}
                            type="date"
                            placeholder=""
                            color={
                              errors?.users &&
                              !!errors?.users[index]?.user_birth_date
                                ? "failure"
                                : ""
                            }
                            helperText={
                              <>
                                {errors?.users &&
                                  errors?.users[index]?.user_birth_date
                                    ?.message}
                              </>
                            }
                            max={moment().format("YYYY-MM-DD")}
                            {...register(
                              `users.${index}.user_birth_date` as const
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {index !== 0 && (
                  <Button color="failure" onClick={() => remove(index)}>
                    {t("dashboard.addUserManually.btn-02")}
                  </Button>
                )}
              </div>
            </div>
          ))}
          <div className="justify-end items-center gap-2.5 flex">
            <Button
              color={"light"}
              className="font-['Inter']"
              size={"sm"}
              onClick={onHide}
            >
              {t("dashboard.addUserManually.key1")}
            </Button>

            <Button
              type="submit"
              disabled={isLoading}
              isProcessing={isLoading}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
              className="bg-sky-500 font-['Inter'] leading-none"
              size={"sm"}
            >
              <FaUserPlus size={20} className="mr-2" />
              {t("dashboard.addUserManually.btn-03")}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserManuallyModal;
