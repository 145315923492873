import { Spinner } from "flowbite-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import UserEntityPreferences from "../UserEntityPreferences";
import UserEntityData from "../UserEntityData";
import ManageCustomerGroups from "./ManageCustomerGroups";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import UserTracker from "./UserTracker";
import React, { useState } from "react";
import SendPersonalMessage from "./SendPersonalMessage";
import RoleTypeEditorNewVersion from "./RoleTypeEditorNewVersion";
import { Button, Dropdown, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle, HiThumbUp } from "react-icons/hi";
import { Copy } from "../../../../routes/Groups";
import { BiSolidEnvelope } from "react-icons/bi";
import { FaTrashCan } from "react-icons/fa6";
import { queryClient } from "../../../../providers/QueryProvider";
import UpgradeModal from "../../../modals/UpgradeModal";
import { PlansModal } from "../../../modals/PlansModal";

type EntityUserProfile = {
  user_identification: string;
  user_user_name: string | null;
  user_last_name: string | null;
  user_first_name: string | null;
  user_birth_date: string | null;
  user_avatar: string | null;
  user_creation_date_time: string;
  user_last_connection: string | null;
  user_phone: string | null;
  user_email: string;
  user_country_code: string;
  user_is_active: boolean;
  user_role: string;
  user_type: string;
  user_groups: [];
  user_account_status: string;
};

const ViewEntityUser = () => {
  const { t } = useTranslation();
  const { user, customer_identification, userEndedSubscription } = useAuth();
  const { user_identification } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateType = location.state?.navigateType;

  const [showSendMsgModal, setShowSendMsgModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userToDeleteId, setUserToDeleteId] = useState("");

  const {
    isLoading,
    isFetching,
    isError,
    data: res,
    refetch,
  } = useQuery(
    ["entity_user", user_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${user_identification}/profile`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const userDataQuery = useQuery(
    ["user-data", user_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${user_identification}/data`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const DeleteEntityUserMutation = useMutation<
    string,
    AxiosError<any, any>,
    any
  >(
    (customerId) =>
      HttpService.delete(
        `/${customer_identification}/${user.entityIdentification}/user/${customerId}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["count-users"]);

        toast.success("User removed");

        navigateType == "Groups"
          ? navigate(-1)
          : navigate("/control-panel/users");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const ResendValidationLinkMutation = useMutation<
    string,
    AxiosError<any, any>,
    any
  >(
    (userId) =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/user/${userId}/validation-link`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Validation link is sent.");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const GeneratePasswordMutation = useMutation<
    string,
    AxiosError<any, any>,
    any
  >(
    (userId) =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/user/${userId}/password/generate-password`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Password is generated.");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const ChangeValidationStatusMutation = useMutation<
    string,
    AxiosError<any, any>,
    any
  >(
    ({ status, userId }) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/user/${userId}/account/status`,
        { status },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("User ban status is changed.");
        refetch();
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleDeleteUser = () => {
    if (userToDeleteId) DeleteEntityUserMutation.mutate(userToDeleteId);
  };

  const handleResendValidationLink = (userId: string) => {
    ResendValidationLinkMutation.mutate(userId);
  };

  if (isLoading) return <Spinner size="sm" />;

  if (isError)
    return (
      <Button color="light" onClick={() => refetch()} size="sm">
        {t("dashboard.entities.viewEntityUser.btn-01")}
      </Button>
    );

  const userData: EntityUserProfile = res?.data.element;

  const banUnBanHandler = (userStatus: string, userId: string) => {
    ChangeValidationStatusMutation.mutate({
      status: userStatus === "BANNED" ? "OK" : "BANNED",
      userId,
    });
  };

  return (
    <div className="entity-user-page">
      <div className="grid grid-cols-11">
        <div className="lg:col-span-7 col-span-12">
          <div className="p-6 flex-col justify-start items-start gap-6 inline-flex bg-white w-full">
            <div className="self-stretch lg:justify-start lg:items-start justify-between items-center gap-2 inline-flex">
              <button
                //to={"/control-panel/users"}
                onClick={() => {
                  navigateType == "Groups"
                    ? navigate(-1)
                    : navigate("/control-panel/users");
                }}
                className="gap-2 inline-flex items-center"
              >
                <div className="w-5 h-5 relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.70711 16.7071C9.31659 17.0976 8.68342 17.0976 8.2929 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.2929 3.29289C8.68342 2.90237 9.31659 2.90237 9.70712 3.29289C10.0976 3.68342 10.0976 4.31658 9.70712 4.70711L5.41422 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41422 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                </div>
                <div className="grow shrink basis-0 text-gray-500 text-sm font-semibold font-['Figtree'] leading-tight">
                  {navigateType == "Groups"
                    ? "Back to group users"
                    : t("dashboard.entities.viewEntityUser.key1")}
                </div>
              </button>

              <Link
                to={`/control-panel/users/${userData.user_identification}/history`}
                className="text-sky-600 text-sm font-semibold font-['Figtree'] leading-tight lg:hidden inline-flex"
              >
                {t("dashboard.entities.viewEntityUser.key2")}
              </Link>
            </div>

            <div className="self-stretch flex-col justify-start items-start gap-4 flex">
              <div className="w-16 h-16 relative bg-gray-100 rounded-[32px]">
                <img
                  className="w-16 h-16 relative rounded-[32px]"
                  src={userData.user_avatar || "/images/avatar.png"}
                  alt="avatar"
                />
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch flex-col justify-start items-start flex">
                  <div
                    className="self-stretch text-gray-700 text-2xl font-bold font-['Figtree'] leading-none mb-2 text-wrap truncate"
                    title={
                      userData.user_first_name && userData.user_last_name
                        ? userData.user_first_name.toLocaleUpperCase() +
                          " " +
                          userData.user_last_name.toLocaleUpperCase()
                        : (userData.user_email as string)
                    }
                  >
                    {userData.user_first_name && userData.user_last_name
                      ? userData.user_first_name.toLocaleUpperCase() +
                        " " +
                        userData.user_last_name.toLocaleUpperCase()
                      : (userData.user_email as string)}
                  </div>

                  {/* username */}
                  {!!userData.user_user_name && (
                    <div className="self-stretch text-gray-500 text-sm font-normal font-['Figtree'] leading-none mb-2">
                      @{userData.user_user_name}
                    </div>
                  )}

                  {/* identification */}
                  <div className="self-stretch text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                    {t("dashboard.entities.viewEntityUser.key12")}:&nbsp;
                    <Copy target={userData.user_identification} />
                  </div>
                </div>

                <div className="justify-start items-start gap-4 inline-flex">
                  {userData.user_account_status === "BANNED" ? (
                    <div className="justify-start items-center gap-1 flex">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Ban">
                          <path
                            id="Icon"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.4766 14.8907C12.4958 15.5892 11.2959 16 10 16C6.68629 16 4 13.3137 4 10C4 8.70414 4.41081 7.50423 5.1093 6.52339L13.4766 14.8907ZM14.8908 13.4765L6.52354 5.1092C7.50434 4.41077 8.7042 4 10 4C13.3137 4 16 6.68629 16 10C16 11.2958 15.5892 12.4957 14.8908 13.4765Z"
                            fill="#F87171"
                          />
                        </g>
                      </svg>

                      <div className="text-red-600 text-sm font-normal font-['Inter'] leading-tight">
                        {t("dashboard.entities.viewEntityUser.key4")}
                      </div>
                    </div>
                  ) : userData.user_account_status === "OK" ? (
                    <center>
                      <div className="pl-2 pr-2.5 py-1 bg-emerald-100 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
                        <div className="w-2 h-2 relative">
                          <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-emerald-400 rounded-full" />
                        </div>
                        <div className="text-center text-emerald-800 text-sm font-medium font-['Inter'] leading-none">
                          {t("dashboard.usersList.item-11")}
                        </div>
                      </div>
                    </center>
                  ) : // <div className="justify-start items-center gap-1 flex">
                  //   <div className="w-5 h-5 pl-0.5 pr-[2.20px] py-[3px] justify-center items-center flex">
                  //     <div className="w-[15.80px] h-3.5 relative flex-col justify-start items-start flex">
                  //       <svg
                  //         xmlns="http://www.w3.org/2000/svg"
                  //         width="17"
                  //         height="14"
                  //         viewBox="0 0 17 14"
                  //         fill="none"
                  //       >
                  //         <path
                  //           d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z"
                  //           fill="#34D399"
                  //         />
                  //         <path
                  //           d="M6 8C9.31371 8 12 10.6863 12 14H0C0 10.6863 2.68629 8 6 8Z"
                  //           fill="#34D399"
                  //         />
                  //         <path
                  //           fillRule="evenodd"
                  //           clipRule="evenodd"
                  //           d="M14.7636 3.7636C15.115 3.41213 15.6849 3.41213 16.0363 3.7636C16.3878 4.11508 16.3878 4.68492 16.0363 5.0364L12.8363 8.2364C12.4849 8.58787 11.915 8.58787 11.5636 8.2364L9.96356 6.6364C9.61208 6.28492 9.61208 5.71508 9.96356 5.3636C10.315 5.01213 10.8849 5.01213 11.2363 5.3636L12.2 6.32721L14.7636 3.7636Z"
                  //           fill="#34D399"
                  //         />
                  //       </svg>
                  //     </div>
                  //   </div>
                  //   <div className="text-emerald-600 text-sm font-normal font-['Inter'] leading-tight">
                  //     {t("dashboard.entities.viewEntityUser.key3")}
                  //   </div>
                  // </div>
                  null}
                </div>
              </div>

              <div className="self-stretch justify-start items-start gap-3 inline-flex flex-wrap">
                <button
                  onClick={() =>
                    user?.customer_top_plan?.includes("FREE")
                      ? setShowUpgradeModal(true)
                      : setShowSendMsgModal(true)
                  }
                  disabled={userEndedSubscription}
                  className="grow shrink basis-0 h-[38px] px-[17px] py-[9px] bg-sky-500 rounded-md shadow justify-center text-white text-sm font-medium font-['Inter'] leading-tight flex items-center gap-2 truncate"
                >
                  <BiSolidEnvelope size={22} className="shrink-0" />
                  {t("dashboard.entities.viewEntityUser.sendMsg.actionBtn")}
                  {user?.customer_top_plan?.includes("FREE") && (
                    <img
                      title="Super User Manager"
                      loading="lazy"
                      src="/images/upgrade.svg"
                      alt="Upgrade"
                      className="img-fluid"
                    />
                  )}
                </button>

                <button
                  onClick={() =>
                    banUnBanHandler(
                      userData?.user_account_status,
                      userData.user_identification
                    )
                  }
                  disabled={
                    ChangeValidationStatusMutation.isLoading ||
                    isLoading ||
                    isFetching
                  }
                  className="grow shrink basis-0 h-[38px] px-[17px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-center items-center xl:flex hidden text-gray-700 text-sm font-medium font-['Inter'] leading-tight gap-2"
                >
                  {userData?.user_account_status === "BANNED" ? (
                    <React.Fragment>
                      <HiThumbUp size={22} className="text-[#34D399]" />
                      <span>
                        {t("dashboard.entities.viewEntityUser.btn-04")}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Ban">
                          <path
                            id="Icon"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.4766 14.8907C12.4958 15.5892 11.2959 16 10 16C6.68629 16 4 13.3137 4 10C4 8.70414 4.41081 7.50423 5.1093 6.52339L13.4766 14.8907ZM14.8908 13.4765L6.52354 5.1092C7.50434 4.41077 8.7042 4 10 4C13.3137 4 16 6.68629 16 10C16 11.2958 15.5892 12.4957 14.8908 13.4765Z"
                            fill="#F87171"
                          />
                        </g>
                      </svg>
                      <span>
                        {t("dashboard.entities.viewEntityUser.btn-05")}
                      </span>
                    </React.Fragment>
                  )}
                </button>

                <button
                  onClick={() => {
                    setUserToDeleteId(userData.user_identification);
                    setOpenModal(true);
                  }}
                  className="grow shrink basis-0 h-[38px] px-[17px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-center items-center xl:flex hidden text-gray-700 text-sm font-medium font-['Inter'] leading-tight gap-2 truncate"
                >
                  <FaTrashCan size={18} color="#9CA3AF" className="shrink-0" />
                  {t("dashboard.entities.viewEntityUser.key5")}
                </button>

                <Dropdown
                  label={""}
                  renderTrigger={() => (
                    <button className="shrink basis-0 h-[38px] px-[17px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-center items-center xl:hidden flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 6C8.89543 6 8 5.10457 8 4C8 2.89543 8.89543 2 10 2C11.1046 2 12 2.89543 12 4C12 5.10457 11.1046 6 10 6Z"
                          fill="#9CA3AF"
                        />
                        <path
                          d="M10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12Z"
                          fill="#9CA3AF"
                        />
                        <path
                          d="M10 18C8.89543 18 8 17.1046 8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18Z"
                          fill="#9CA3AF"
                        />
                      </svg>
                    </button>
                  )}
                  className="font-medium font-['Inter'] leading-tight"
                >
                  <Dropdown.Item
                    onClick={() =>
                      banUnBanHandler(
                        userData?.user_account_status,
                        userData.user_identification
                      )
                    }
                    disabled={
                      ChangeValidationStatusMutation.isLoading ||
                      isLoading ||
                      isFetching
                    }
                  >
                    {userData?.user_account_status === "BANNED" ? (
                      <React.Fragment>
                        <HiThumbUp size={22} className="text-[#34D399] mr-2" />
                        <span>
                          {t("dashboard.entities.viewEntityUser.btn-04")}
                        </span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2"
                        >
                          <g id="Ban">
                            <path
                              id="Icon"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.4766 14.8907C12.4958 15.5892 11.2959 16 10 16C6.68629 16 4 13.3137 4 10C4 8.70414 4.41081 7.50423 5.1093 6.52339L13.4766 14.8907ZM14.8908 13.4765L6.52354 5.1092C7.50434 4.41077 8.7042 4 10 4C13.3137 4 16 6.68629 16 10C16 11.2958 15.5892 12.4957 14.8908 13.4765Z"
                              fill="#F87171"
                            />
                          </g>
                        </svg>
                        <span>
                          {t("dashboard.entities.viewEntityUser.btn-05")}
                        </span>
                      </React.Fragment>
                    )}
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      setUserToDeleteId(userData.user_identification);
                      setOpenModal(true);
                    }}
                  >
                    <FaTrashCan
                      size={18}
                      color="#9CA3AF"
                      className="shrink-0 mr-2"
                    />
                    {t("dashboard.entities.viewEntityUser.key5")}
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>

            <div className="self-stretch h-px bg-gray-300" />

            <div className="self-stretch flex-col justify-start items-start gap-6 flex">
              <div className="grid grid-cols-12 self-stretch justify-start items-start gap-6">
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-03")}
                  </div>
                  <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {userData.user_first_name}
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-04")}
                  </div>
                  <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {userData.user_last_name}
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-02")}
                  </div>
                  <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {userData.user_user_name}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 self-stretch justify-start items-start gap-6">
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-1 inline-flex w-full">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-10")}
                  </div>
                  <div
                    className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal text-wrap truncate"
                    title={userData.user_email}
                  >
                    {userData.user_email}
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-1 inline-flex w-full">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-09")}
                  </div>
                  <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal text-wrap truncate">
                    {userData.user_phone}
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-1 inline-flex w-full">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-05")}
                  </div>
                  <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal text-wrap truncate">
                    {userData.user_birth_date
                      ? moment(userData.user_birth_date)
                          .utc()
                          .format("D MMMM, YYYY")
                      : ""}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 self-stretch justify-start items-start gap-6">
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-06")}
                  </div>
                  <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {moment(userData.user_creation_date_time)
                      .utc()
                      .format("MMMM D, YYYY | HH:mm:ss")}
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.entities.viewEntityUser.item-07")}
                  </div>
                  <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {userData.user_last_connection
                      ? moment(userData.user_last_connection)
                          .utc()
                          .format("MMMM D | HH:mm:ss")
                      : ""}
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                  <div className="self-stretch h-12 flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                      {t("dashboard.entities.viewEntityUser.key6")}
                    </div>
                    <div className="self-stretch text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                      {userData.user_is_active
                        ? t("dashboard.entities.viewEntityUser.status-01")
                        : t("dashboard.entities.viewEntityUser.status-02")}
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    {!userData.user_is_active && (
                      <button
                        className="text-sky-600 text-sm font-medium font-['Inter'] leading-tight text-left"
                        onClick={() =>
                          handleResendValidationLink(
                            userData.user_identification
                          )
                        }
                        disabled={
                          ResendValidationLinkMutation.isLoading ||
                          userEndedSubscription
                        }
                      >
                        {ResendValidationLinkMutation.isLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          t("dashboard.entities.viewEntityUser.btn-03")
                        )}
                      </button>
                    )}

                    <button
                      className="text-sky-600 text-sm font-medium font-['Inter'] leading-tight text-left"
                      onClick={() =>
                        GeneratePasswordMutation.mutate(
                          userData.user_identification
                        )
                      }
                      disabled={
                        GeneratePasswordMutation.isLoading ||
                        userEndedSubscription
                      }
                    >
                      {GeneratePasswordMutation.isLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        t("dashboard.entities.viewEntityUser.btn-02")
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="self-stretch h-px bg-gray-300" />

            <RoleTypeEditorNewVersion
              data={{ role: userData.user_role, type: userData.user_type }}
              entity_identification={user.entityIdentification as string}
              user_identification={customer_identification}
              customerId={user_identification as string}
            />

            {!user.isBasic && <div className="self-stretch h-px bg-gray-300" />}

            <ManageCustomerGroups
              data={userData.user_groups}
              entity_identification={user.entityIdentification as string}
              user_identification={customer_identification}
              customerId={user_identification as string}
            />

            {!user.isBasic && <div className="self-stretch h-px bg-gray-300" />}

            <UserEntityPreferences
              isEditable={true}
              isUser={true}
              entity_identification={user.entityIdentification as string}
              customerId={user_identification as string}
            />

            {!user.isBasic && <div className="self-stretch h-px bg-gray-300" />}

            <UserEntityData
              isEditable={true}
              isUser={true}
              entity_identification={user.entityIdentification as string}
              customerId={user_identification as string}
              query={userDataQuery}
            />
          </div>
        </div>
        <div className="lg:col-span-4 col-span-12 lg:grid hidden">
          <UserTracker />
        </div>
      </div>

      <SendPersonalMessage
        show={showSendMsgModal}
        ctrFn={setShowSendMsgModal}
        toSendMsgUserId={user_identification as string}
        entity_identification={user.entityIdentification as string}
        entity_user_identification={customer_identification}
        userName={
          userData?.user_user_name ||
          userData?.user_email ||
          userData?.user_first_name + " " + userData?.user_last_name
        }
      />

      <UpgradeModal
        setShowModal={setShowUpgradeModal}
        showModal={showUpgradeModal}
        setShowPlansModal={setShowPlansModal}
      />
      <PlansModal
        showPlansModal={showPlansModal}
        setShowPlansModal={setShowPlansModal}
      />

      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {t("dashboard.entities.viewEntityUser.key9")}
            </h3>
            <h2 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {t("dashboard.entities.viewEntityUser.key9-1")}
            </h2>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={handleDeleteUser}>
                {t("dashboard.entities.viewEntityUser.key10")}
              </Button>
              <Button
                color="gray"
                onClick={() => {
                  setOpenModal(false);
                  setUserToDeleteId("");
                }}
              >
                {t("dashboard.entities.viewEntityUser.key11")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewEntityUser;
