import { toast } from "react-toastify";
import { AxiosError, AxiosResponse } from "axios";
import { Helmet } from "react-helmet-async";
import { Button, Spinner } from "flowbite-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import SwaggerInfos from "./SwaggerInfos";
import EntityStatusChanger from "./EntityStatusChanger";
import EntityPreferencesPreview from "./EntityPreferencesPreview";
import EntityPreferences from "../entities/EntityPreferences";
import WebsiteCondition from "./WebsiteCondition";
import ExportData from "./ExportData";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { premiumPlans } from "../../../Plans";
import { queryClient } from "../../../../providers/QueryProvider";

const Develop = () => {
  const [t] = useTranslation();
  const { customer_identification, user, setUser, userEndedSubscription } =
    useAuth();
  const { state } = useLocation();

  const UpdateTokenMutation = useMutation<any, AxiosError<any, any>, any>(
    () =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/access-token`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        queryClient.clear();

        if (typeof window !== "undefined") {
          let currentAuthFromStorage = localStorage.getItem("_umd_service");

          if (currentAuthFromStorage) {
            let parsedValue = JSON.parse(currentAuthFromStorage);
            parsedValue["accessToken"] = res.data.element.accessToken;
            localStorage.setItem("_umd_service", JSON.stringify(parsedValue));
          }

          setUser({ ...user, accessToken: res.data.element.accessToken });

          window.navigator.clipboard.writeText(res.data.element.accessToken);

          toast.info("The token was generated and copied successfully");
        }
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const entityPreferencesQuery = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const entityQuery = useQuery(
    ["entities", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      enabled: entityPreferencesQuery.isSuccess,
    }
  );

  const userDataQuery = useQuery(
    ["user-data", customer_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/data`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  useEffect(() => {
    if (
      state &&
      state.section &&
      state.section === "linksConfig" &&
      entityQuery.isSuccess
    ) {
      const elm = document.getElementById("linksConfig");

      if (elm) elm.scrollIntoView({ behavior: "smooth" });
    }

    return () => {};
  }, [entityQuery.isSuccess, state]);

  let entityContent;

  if (entityQuery.isLoading) entityContent = <Spinner size="sm" />;

  if (entityQuery.isError)
    entityContent = (
      <Button onClick={() => entityQuery.refetch()}>Reload</Button>
    );

  let entityPreferencesContent;

  if (entityPreferencesQuery.isLoading)
    entityPreferencesContent = <Spinner size="sm" />;

  if (entityPreferencesQuery.isError)
    entityPreferencesContent = (
      <Button onClick={() => entityPreferencesQuery.refetch()}>Reload</Button>
    );

  if (entityQuery.isSuccess && entityPreferencesQuery.isSuccess) {
    entityContent = (
      <EntityPreferencesPreview
        entityPreferencesData={entityPreferencesQuery.data?.data?.element}
        entityData={entityQuery?.data?.data?.element}
      />
    );
    entityPreferencesContent = (
      <EntityPreferences
        data={entityPreferencesQuery.data?.data?.element}
        user_identification={customer_identification}
        entity_identification={user.entityIdentification}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Settings | Super User Manager</title>
        <meta name="description" content="Settings panel" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/develop`}
        />
      </Helmet>

      <div className="container mx-auto py-6 flex flex-col justify-center items-start gap-5">
        <h2 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.develop.title")}
        </h2>
        <div className="w-full">
          <EntityStatusChanger />
        </div>
        <div className="w-full p-5 bg-white rounded-lg shadow">
          {entityContent}
        </div>

        {!user.isBasic && (
          <>
            <div
              className="w-full p-5 bg-white rounded-lg shadow"
              id="linksConfig"
            >
              {entityPreferencesContent}
            </div>

            {userDataQuery.isSuccess && (
              <div className="w-full p-5 bg-white rounded-lg shadow">
                <SwaggerInfos
                  query={userDataQuery}
                  disabled={userEndedSubscription}
                  UpdateTokenMutation={UpdateTokenMutation}
                />
              </div>
            )}

            {!user?.customer_top_plan?.includes("FREE") &&
              !premiumPlans.includes(user?.customer_top_plan) && (
                <div className="w-full p-5 bg-white rounded-lg shadow">
                  <ExportData />
                </div>
              )}
          </>
        )}

        <div className="w-full">
          <WebsiteCondition />
        </div>
      </div>
    </>
  );
};

export default Develop;
