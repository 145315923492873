import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { queryClient } from "../providers/QueryProvider";
import UsersListComponent from "../components/pages/dashboard/users/UsersListComponent";
import { USER_LIST_COMPONENT_TYPE } from "../constants/types";
import { useGetSearchUsers } from "../hooks/users";
import { BiPlus } from "react-icons/bi";
import AddToGroupModal from "../components/pages/dashboard/users/AddToGroupModal";

const SearchPage = () => {
  const { user, customer_identification } = useAuth();
  const [t] = useTranslation();
  const location: any = useLocation();

  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());
  const [limit, setLimit] = useState<10 | 30 | 50>(
    localStorage.getItem("umd_users_list_limit") &&
      [10, 30, 50].includes(
        Number(localStorage.getItem("umd_users_list_limit")) as any
      )
      ? (Number(localStorage.getItem("umd_users_list_limit")) as any)
      : 10
  );
  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [showModal, setShowModal] = useState(false);

  const queryData = {
    customer_identification: customer_identification,
    entityIdentification: user.entityIdentification,
  };
  const {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch: refetchUsers,
  } = useGetSearchUsers({
    ...queryData,
    query: location.state.query,
    limit: limit,
    pageParam: offset,
  });

  useEffect(() => {
    if (location.state?.query) {
      // queryClient
      //   .fetchQuery(["search-user", location.state.query], () =>
      //     HttpService.get(
      //       `/${customer_identification}/${
      //         user.entityIdentification
      //       }/user/search?query=${encodeURIComponent(
      //         location.state.query ?? ""
      //       )}`,
      //       {
      //         auth: HttpService.getToken(),
      //       }
      //     )
      //   )
      //   .then((value) => {
      //     setData(value.data.element);
      //   })
      //   .catch((err: any) => {
      //     toast.error(err.response?.data?.message || err.message);
      //   })
      //   .finally(() => {});
    }
  }, [
    location,
    customer_identification,
    queryClient,
    user.entityIdentification,
  ]);

  // const handleCopy = (identification: string) => {
  //   navigator.clipboard.writeText(identification);

  //   toast.info("Identification copied!");
  // };

  let content;

  if (isLoading) content = <div className="p-3 text-center">Loading...</div>;

  if (isError)
    content = (
      <Button
        color="light"
        size="sm"
        onClick={() => queryClient.refetchQueries("")}
      >
        Refetch
      </Button>
    );

  if (isSuccess)
    content =
      data.length > 0 ? (
        <div className="overflow-x-auto">
          <UsersListComponent
            users={data}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            refetch={refetchUsers}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
            type={USER_LIST_COMPONENT_TYPE.USERS}
            //  pagination={data?.pagination}
            offset={offset}
            setOffset={setOffset}
            limit={data?.length}
          />
        </div>
      ) : (
        <div className="p-3 text-center">
          <h4>{t("dashboard.searchPage.text-02")}</h4>
          <p>
            {t("dashboard.searchPage.text-03")}{" "}
            {location.state?.query ? (
              <b>{location.state?.query}</b>
            ) : (
              "this search key"
            )}
          </p>
          {/* <Link to={'/control-panel/users'}>
        <i className='bi bi-arrow-left me-2'></i>
        <span>{t("dashboard.searchPage.btn")}</span>
      </Link> */}
        </div>
      );

  return (
    <div className="users-page">
      <Helmet>
        <title>Search users | Super User Manager</title>
        <meta name="description" content="Search users" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/search`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        {location.state?.query ? (
          <div className="flex items-center gap-3 mb-6 justify-between">
            <div className="flex items-center gap-3 ">
              <Link
                to={"/control-panel/users"}
                className="btn btn-link text-decoration-none text-dark d-flex align-items-center me-3 px-0"
              >
                <AiOutlineArrowLeft />
                {/* <span>{t("dashboard.searchPage.btn")}</span> */}
              </Link>
              <h4 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                {t("dashboard.searchPage.text-04")}
                <b className="ms-2"> {location.state?.query}</b>
              </h4>
            </div>

            {selectedRowIds?.size > 1 && (
              <div
                role="button"
                onClick={() => setShowModal(true)}
                className="px-2 py-3 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center gap-1 inline-flex overflow-hidden"
              >
                <div data-svg-wrapper className="relative">
                  <BiPlus className="text-gray-500" size={18} />
                </div>
                <div className="text-gray-700 text-sm font-medium font-['Figtree'] leading-tight">
                  {t("dashboard.entities.viewEntityUser.key14")}
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="p-3">{t("dashboard.searchPage.text-05")}</p>
        )}
        {content}
      </div>

      <AddToGroupModal
        userList={selectedRowIds}
        show={showModal}
        onHide={() => setShowModal(false)}
        onResolved={() => setSelectedRowIds(new Set())}
      />
    </div>
  );
};

export default SearchPage;
