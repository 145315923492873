import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import AuthProvider from "./providers/AuthProvider";
import ErrorBoundary from "./components/public/ErrorBoundary";
import LocalStorageProvider from "./providers/LocalStorageProvider";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <LocalStorageProvider>
        <BrowserRouter>
          <HelmetProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </HelmetProvider>
        </BrowserRouter>
      </LocalStorageProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
