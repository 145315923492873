import { useQuery } from "react-query";
import { queryKeys } from "../../constants/queryKeys";
import HttpService from "../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const useGetPlans = ({
  customer_identification,
  entityIdentification,
}: {
  customer_identification: string;
  entityIdentification: string;
}) => {
  return useQuery<any, any, any>(
    [queryKeys.plans, customer_identification, entityIdentification],
    async () => {
      return await HttpService.get(
        `/${customer_identification}/${entityIdentification}/billing/plan-status`
      ).then((data) => {
        return data?.data.element;
      });
    },
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};
