import { Modal, Spinner, TextInput } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useDeleteOwner } from "../../hooks/users";
import useAuth from "../../hooks/useAuth";

export default function DeleteOwnerModal({
  showDeleteModal,
  setShowDeleteModal,
}: {
  showDeleteModal: boolean;
  setShowDeleteModal: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const schema = Joi.object({
    confirm: Joi.string()
      .trim()
      .required()
      .valid("CONFIRM")
      .messages({
        "any.only": t("dashboard.deleteOwner.key7") as string,
      }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });
  const { user, customer_identification } = useAuth();

  const { mutate, isLoading } = useDeleteOwner();

  const onSubmit = (data: any) => {
    mutate({
      entity_identification: user.entityIdentification,
      user_identification: user.userIdentification,
      customer_identification: customer_identification,
      validation: data.confirm,
    });
  };

  return (
    <Modal show={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
      <Modal.Body className="p-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-col md:flex p-6 pb-1  gap-3 justify-items-center">
            <div data-svg-wrapper className="relative mb-3">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="40" rx="20" fill="#FEE2E2" />
                <path
                  d="M16.667 20.0433C18.3295 20.0433 19.5837 18.7891 19.5837 17.1266C19.5837 15.4641 18.3295 14.21 16.667 14.21C15.0045 14.21 13.7503 15.4641 13.7503 17.1266C13.7503 18.7891 15.0045 20.0433 16.667 20.0433ZM17.5003 20.8333H15.8337C13.5362 20.8333 11.667 22.7025 11.667 25V25.8333H21.667V25C21.667 22.7025 19.7978 20.8333 17.5003 20.8333ZM26.9112 16.9108L25.0003 18.8216L23.0895 16.9108L21.9112 18.0891L23.8212 19.9991L21.9103 21.91L23.0887 23.0883L24.9995 21.1775L26.9112 23.0891L28.0895 21.9108L26.1787 20L28.0895 18.0891L26.9112 16.9108Z"
                  fill="#E34A51"
                />
              </svg>
            </div>

            <div className="h-auto flex-col justify-start items-start gap-2 inline-flex">
              <div className="self-stretch text-gray-900 text-lg font-semibold font-['Figtree'] leading-7">
                {t("dashboard.deleteOwner.key1")}
              </div>
              <div className="self-stretch text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                {t("dashboard.deleteOwner.key2")}
              </div>

              <div className=" w-full mt-4 pb-4">
                <TextInput
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  autoComplete="off"
                  id="confirm"
                  placeholder={"CONFIRM"}
                  color={!!errors.confirm ? "failure" : ""}
                  type="text"
                  {...register("confirm")}
                  helperText={<>{errors.confirm?.message as string}</>}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-auto md:h-16 px-6 py-3 bg-gray-50 justify-end items-center gap-3 flex flex-col-reverse md:flex-row ">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="w-full md:w-[75px] px-4 py-2 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center flex overflow-hidden"
            >
              <div className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
                {t("dashboard.deleteOwner.key3")}
              </div>
            </button>
            <div className=" w-full  md:w-[75px] px-4 py-2 bg-red-600 rounded-md shadow-sm justify-center items-center flex overflow-hidden">
              <button
                type="submit"
                disabled={isLoading}
                onClick={handleSubmit(onSubmit)}
                className="text-white text-sm font-normal font-['Figtree'] leading-tight"
              >
                {isLoading ? <Spinner /> : t("dashboard.deleteOwner.key4")}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
