import { AxiosError } from "axios";
import { useMutation } from "react-query";
import HttpService from "../../services/http";
import { toast } from "react-toastify";
import { queryKeys } from "../../constants/queryKeys";
import { queryClient } from "../../providers/QueryProvider";

type SendMessagesToAllProps = {
  customer_identification: string;
  entity_identification: string;
  group_identification: string;
  data: any;
};

type SendMessageProps = {
  customer_identification: string;
  entity_identification: string;
  toSendMsgUserId: string;
  data: any;
};

export const useSendMessagesToAll = () => {
  return useMutation<SendMessagesToAllProps, AxiosError<any, any>, any>(
    ({
      customer_identification,
      entity_identification,
      group_identification,
      data,
    }: SendMessagesToAllProps) =>
      HttpService.post(
        `${customer_identification}/${entity_identification}/user/group/${group_identification}/message/bulk`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Message sent to all successfully");
        queryClient.invalidateQueries([queryKeys.messages]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useSendMessagesToUsers = () => {
  return useMutation<SendMessagesToAllProps, AxiosError<any, any>, any>(
    ({
      customer_identification,
      entity_identification,
      group_identification,
      data,
    }: SendMessagesToAllProps) =>
      HttpService.post(
        `${customer_identification}/${entity_identification}/user/group/${group_identification}/message`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Message sent to users successfully");
        queryClient.invalidateQueries([queryKeys.messages]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useSendMessageToOne = () => {
  return useMutation<SendMessageProps, AxiosError<any, any>, any>(
    ({
      customer_identification,
      entity_identification,
      toSendMsgUserId,
      data,
    }: SendMessageProps) =>
      HttpService.post(
        `/${customer_identification}/${entity_identification}/user/${toSendMsgUserId}/message`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Message is sent.");
        queryClient.invalidateQueries([queryKeys.messages]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};
