import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import HttpService from "../services/http";
import { Helmet } from "react-helmet-async";
import UserEntityData from "../components/pages/dashboard/UserEntityData";
import UserEntityPreferences from "../components/pages/dashboard/UserEntityPreferences";
import ProfileBasicInfo from "../components/pages/dashboard/profile/ProfileBasicInfo";
import ProfileEmailChange from "../components/pages/dashboard/profile/ProfileEmailChange";
import ProfilePasswordChange from "../components/pages/dashboard/profile/ProfilePasswordChange";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DeleteOwnerModal from "../components/modals/DeleteOwnerModal";

const Profile = () => {
  const { t } = useTranslation();
  const { user, customer_identification } = useAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const userDataQuery = useQuery(
    ["user-data", customer_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/data`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  return (
    <div>
      <Helmet>
        <title>Profile | Super User Manager</title>
        <meta name="description" content="Your profile" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/profile`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        <h1 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-none mb-5">
          {t("dashboard.profile.key1")}
        </h1>

        <div className="flex flex-col gap-5">
          <ProfileBasicInfo />

          <ProfileEmailChange />

          <ProfilePasswordChange />

          {!user.isBasic && (
            <div className="px-5 pt-5 pb-8 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
              <UserEntityPreferences
                entity_identification={user.entityIdentification}
                isUser={false}
                customerId={customer_identification}
                isEditable={true}
              />
            </div>
          )}

          {!user.isBasic && (
            <div className="px-5 pt-5 pb-8 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
              <UserEntityData
                query={userDataQuery}
                entity_identification={user.entityIdentification}
                isUser={false}
                customerId={customer_identification}
                isEditable={true}
              />
            </div>
          )}

          <div className="p-6 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)] shadow flex-col justify-start items-start gap-4 inline-flex">
            <div className="flex-col justify-start items-start gap-4 flex">
              <div className="flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                  {t("dashboard.deleteOwner.key5")}
                </div>
                <div className="self-stretch text-gray-700 text-base font-normal font-['Figtree'] leading-normal">
                  {t("dashboard.deleteOwner.key6")}
                </div>
              </div>
              <div
                role="button"
                onClick={() => setShowDeleteModal(true)}
                className="px-5 py-1.5 bg-red-500 rounded-md shadow-sm justify-center items-center inline-flex overflow-hidden"
              >
                <div className="text-white text-base font-normal font-['Figtree'] leading-normal">
                  {t("dashboard.deleteOwner.key5")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteOwnerModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
    </div>
  );
};

export default Profile;
