import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { Button, Label, TextInput } from "flowbite-react";
import { useMutation, useQueryClient } from "react-query";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import { AxiosError, AxiosResponse } from "axios";
import useAuth from "../../../../hooks/useAuth";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { queryClient } from "../../../../providers/QueryProvider";
const schema = Joi.object({
  entity_name: Joi.string()
    .trim()
    .min(2)
    .max(30)
    .required()
    .label("Service name"),
  entity_description: Joi.string()
    .trim()
    .max(250)
    .optional()
    .allow(null, "")
    .label("Service description"),
}).required();

const UpdateEntity = ({ data }: { data: any }) => {
  const [t] = useTranslation();
  const { customer_identification, user } = useAuth();

  const UpdateEntityMutation = useMutation<any, AxiosError<any, any>>(
    (data) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/entity`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        toast.success(res.data.message);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["entities", user.entityIdentification]);
      },
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      entity_name: data?.entity_name ?? "",
      entity_description: data?.entity_description ?? "",
    },
  });

  useEffect(() => {
    setValue("entity_name", data?.entity_name);
    setValue("entity_description", data?.entity_description);
  }, [data, setValue]);

  const onSubmit = (data: any) => {
    UpdateEntityMutation.mutate(data);
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="pb-2 flex justify-start items-start flex-wrap md:flex-nowrap md:gap-5 border-b border-solid ">
          <div className="mb-3 w-full md:w-[300px]">
            <Label
              htmlFor="entity_name"
              className=" text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("dashboard.entities.updateEntity.label-01")}
            </Label>
            <TextInput
              id="entity_name"
              defaultValue={data?.entity_name}
              type="string"
              {...register("entity_name")}
              color={
                !!errors.entity_name ||
                !!UpdateEntityMutation.error?.response?.data?.element
                  ?.entity_name
                  ? "failure"
                  : undefined
              }
              helperText={
                errors.entity_name?.message ||
                UpdateEntityMutation.error?.response?.data?.element?.entity_name
              }
            />
          </div>

          <div className="mb-3 w-full md:w-3/4">
            <Label
              htmlFor="entity_description"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("dashboard.entities.updateEntity.label-02")}
            </Label>
            <TextInput
              id="entity_description"
              defaultValue={data?.entity_description}
              type="string"
              {...register("entity_description")}
              color={
                !!errors.entity_description ||
                !!UpdateEntityMutation.error?.response?.data?.element
                  ?.entity_description
                  ? "failure"
                  : undefined
              }
              helperText={
                errors.entity_description?.message ||
                UpdateEntityMutation.error?.response?.data?.element
                  ?.entity_description
              }
            />
          </div>
        </div>

        <Button
          type="submit"
          color="blue"
          className="mt-5 mr-0 ml-auto bg-sky-500 hover:bg-sky-700"
          disabled={
            UpdateEntityMutation.isLoading ||
            (watch("entity_name") === data?.entity_name &&
              watch("entity_description") === data?.entity_description)
          }
          isProcessing={UpdateEntityMutation.isLoading}
        >
          {t("dashboard.entities.updateEntity.btn-01")}
        </Button>
      </form>
    </div>
  );
};

export default UpdateEntity;
