import { SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ModalCompleteServiceInfo from "../components/pages/dashboard/home/ModalCompleteServiceInfo";
import useAuth from "../hooks/useAuth";
import HttpService from "../services/http";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";
import useAppLocalStorage from "../hooks/useAppLocalStorage";
import EntityCheck from "../components/pages/dashboard/EntityCheck";
import EntityStatus from "../components/pages/dashboard/EntityStatus";
import UsersStatsChart from "../components/pages/dashboard/UsersStatsChart";
import GroupAndUsersCounter from "../components/pages/dashboard/GroupAndUsersCounter";
import { Button, Modal } from "flowbite-react";
import Intro from "../components/pages/dashboard/home/Intro";
import { useTranslation } from "react-i18next";
import { queryClient } from "../providers/QueryProvider";

const Dashboard = () => {
  const { t } = useTranslation();
  const localStorage = useAppLocalStorage();

  const { user, customer_identification, setUser } = useAuth();
  const navigate = useNavigate();

  const [showCompleteInfoModal, setShowCompleteInfoModal] = useState(false);
  const [showWeakPassInfoModal, setShowWeakPassInfoModal] = useState(
    user.requiredPasswordUpdate || false
  );
  const [showNoBasicHintModal, setShowNoBasicHintModal] = useState(false);
  const [step, setStep] =
    useState<SetStateAction<"intro" | "noBasic" | "form">>("intro");

  const { isSuccess, data: res } = useQuery(
    ["user-preferences", customer_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: ({ data }) => {
        let sessionData: any = localStorage.getItem("_umd_service");

        if (sessionData && data?.element?.user_data_preferences?.is_basic) {
          const newAuthData = {
            ...JSON.parse(sessionData),
            isBasic: data.element.user_data_preferences.is_basic,
          };

          localStorage.setItem("_umd_service", JSON.stringify(newAuthData));

          setUser({
            ...user,
            isBasic: data.element.user_data_preferences.is_basic,
          });
        }
      },
    }
  );

  const UpdatePreferencesMutation = useMutation<any, AxiosError<any, any>, any>(
    (json) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/preferences`,
        { user_data: json },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "user-preferences",
          customer_identification,
        ]);
      },
    }
  );

  useQuery(
    "entity-stats",
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/status`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (result) => {
        let status = result?.data?.element?.entityStatus;

        if (status === "NEW") {
          UpdatePreferencesMutation.mutate({
            ...res?.data?.element?.user_data_preferences,
            is_basic: true,
          });

          setUser({ ...user, isBasic: true });

          // setShowCompleteInfoModal(true);
        }
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      enabled: isSuccess,
    }
  );

  const renderStepInfo = () => {
    switch (step) {
      case "intro":
        return (
          <Intro
            handleChangeStep={setStep}
            handleCloseModal={() => setShowCompleteInfoModal(false)}
            showNoBasicFn={() => setShowNoBasicHintModal(true)}
          />
        );
      case "form":
        return (
          <ModalCompleteServiceInfo
            hadleChangeStep={setStep}
            handleCloseModal={() => setShowCompleteInfoModal(false)}
          />
        );
      default:
        break;
    }
  };

  const renderWeakPassInfo = () => {
    return (
      <div className="text-center">
        <img
          title="Super User Manager"
          loading="lazy"
          src={
            "https://cdn.1cdn.app/application/UMD/22102511094783_weak_pass.svg"
          }
          className="img-fluid mb-3"
          alt="welcome to dashboard"
        />
        <h2 className="text-dl-blue">Your password is weak!</h2>
        <p style={{ color: "#033768" }}>
          To protect your account, change your password to a stronger one.
        </p>
        <Button color="info" onClick={() => goToLevelUpPassword()}>
          Change password
        </Button>
      </div>
    );
  };

  const goToLevelUpPassword = () => {
    handleCancel();
    navigate("/level-up-password", { replace: true });
  };

  const handleCancel = () => {
    setShowWeakPassInfoModal(false);
    setUser({ ...user, requiredPasswordUpdate: false });

    if (typeof window !== "undefined") {
      let parsedCredential = JSON.parse(
        localStorage.getItem("_umd_service") as string
      );

      if (parsedCredential) {
        parsedCredential.requiredPasswordUpdate = false;
        localStorage.setItem("_umd_service", JSON.stringify(parsedCredential));
      }
    }
  };

  return (
    <div className="home-page">
      <Helmet>
        <title>Home | Super User Manager</title>
        <meta
          name="description"
          content="Super User Manager dashboard - control panel"
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        <div className="grid grid-cols-11 gap-5">
          <div className="lg:col-span-4 col-span-12 flex flex-1">
            <EntityStatus />
          </div>
          {!user.isBasic && (
            <div className="lg:col-span-7 col-span-12 flex flex-1">
              <EntityCheck entity_identification={user.entityIdentification} />
            </div>
          )}
          <div className="lg:col-span-7 col-span-12 flex flex-1">
            <UsersStatsChart />
          </div>
          <div className="lg:col-span-4 col-span-12 flex flex-1">
            <GroupAndUsersCounter />
          </div>
        </div>
      </div>

      {/* Complete service infos modal */}
      <Modal
        show={showCompleteInfoModal}
        onClose={() => setShowCompleteInfoModal(false)}
        size={"lg"}
      >
        {renderStepInfo()}
      </Modal>

      {/* Change password to stronger one modal */}
      <Modal
        show={showWeakPassInfoModal && !showCompleteInfoModal}
        onClose={() => handleCancel()}
      >
        <Modal.Body>{renderWeakPassInfo()}</Modal.Body>
      </Modal>

      {/* Hint is_basic future change modal*/}
      <Modal
        show={showNoBasicHintModal}
        onClose={() => setShowNoBasicHintModal(false)}
      >
        <Modal.Body className="no-basic-hint p-4">
          <h2 className="text-gray-900 text-lg font-semibold font-['Figtree'] leading-7 mb-2">
            {t("dashboard.onBoarding.key10")}
          </h2>
          <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight">
            {t("dashboard.onBoarding.key11")}
          </p>
          <div className="text-end mt-3"></div>
        </Modal.Body>
        <Modal.Footer className="w-full px-6 py-3 bg-gray-50 rounded-bl-lg rounded-br-lg justify-end items-center gap-3 inline-flex">
          <Button
            className="bg-sky-500"
            onClick={() => setShowNoBasicHintModal(false)}
          >
            {t("dashboard.onBoarding.key12")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
