export const enum SEND_MESSAGE_TYPE {
  SINGLE = "Single",
  MULTI = "Multi",
  ALL = "All",
}

export const enum USER_LIST_COMPONENT_TYPE {
  USERS = "Users",
  GROUPS = "Groups",
}
