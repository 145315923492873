import React from "react";
import { useLmcUserView } from "../../hooks/useLmcUserView";
import { useTranslation } from "react-i18next";
import { currencyMapper } from "../../../dashboard/lmc/AmountCard";

const LmcRewardCurrency = ({ amount = 0 }: { amount?: number }) => {
  const { t } = useTranslation();

  const {
    state: { entity },
  } = useLmcUserView();

  return (
    <div className="w-full py-20 px-4 flex flex-col justify-start items-center gap-4">
      <h4 className="w-[158px] text-center text-gray-50 text-xl font-semibold font-['Figtree'] leading-7">
        {t("lmcUser.key40")}
      </h4>
      <h2 className="text-center text-emerald-300 text-6xl font-extrabold font-['Figtree'] leading-[60px]">{`${
        currencyMapper[
          entity.process_data?.pre_defined_value.currency as string
        ].symbol
      }${amount}`}</h2>
    </div>
  );
};

export default LmcRewardCurrency;
