import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import {
  INormalizedLmcDataType,
  IncrementalCardFormProps,
  lmcOptionComponentProps,
} from "./types";
import LmcUtil from "./util";
import { Label, Textarea } from "flowbite-react";
import LMCButtons from "../../../public/LMCButtons";

const schema = Joi.object()
  .keys({
    comment: Joi.string().max(100).optional().allow("", null).label("Comment"),
  })
  .required();

const IncrementalCard = ({
  label,
  optionData,
  onSubmitCardData,
  playgroundDataHandler,
  handleCancelEdit,
  tutorial
}: lmcOptionComponentProps) => {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm<IncrementalCardFormProps>({
    resolver: joiResolver(schema),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (optionData) {
      let denormalizedData = LmcUtil.unwrapper(
        "INCREMENTAL_CARD",
        optionData
      ) as IncrementalCardFormProps;

      reset({ ...denormalizedData });
    }
  }, [optionData, reset]);

  const onSubmit = (data: IncrementalCardFormProps) => {
    const normalizedData = LmcUtil.wrapper(
      "INCREMENTAL_CARD",
      data
    ) as INormalizedLmcDataType;

    onSubmitCardData("INCREMENTAL_CARD", normalizedData).then(() => {
      reset();
    });
  };

  useEffect(() => {
    playgroundDataHandler({
      type: "INCREMENTAL_CARD",
      data: LmcUtil.wrapper(
        "INCREMENTAL_CARD",
        getValues()
      ) as INormalizedLmcDataType,
    });

    watch((value, { name, type }) => {
      playgroundDataHandler({
        type: "INCREMENTAL_CARD",
        data: LmcUtil.wrapper(
          "INCREMENTAL_CARD",
          value
        ) as INormalizedLmcDataType,
      });
    });
  }, [getValues, playgroundDataHandler, watch]);

  return (
    <div className="lmc-card point-card w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <Label
            htmlFor={"comment"}
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.incrementalCard.label1")}
          </Label>
          <Textarea
            color={!!errors.comment ? "failure" : ""}
            rows={3}
            placeholder={
              t("dashboard.features.lmc.incrementalCard.placeholder1") as string
            }
            helperText={<>{errors.comment?.message}</>}
            {...register("comment")}
          />
        </div>

        <LMCButtons tutorial={tutorial} handleCancelEdit={handleCancelEdit} />
      </form>
    </div>
  );
};

export const IncrementalCardUi = ({
  data,
}: {
  data: INormalizedLmcDataType | null;
}) => {
  const { t } = useTranslation();

  return (
    <div className="self-stretch  flex-col justify-start items-center gap-6 flex">
      <h4 className="text-2xl font-bold text-gray-800">
        {t("dashboard.features.loginTemplate.tempPart.btn-01")}
      </h4>

      <div className="self-stretch  bg-white rounded-2xl shadow flex-col justify-start items-center gap-6 flex">
        {/* <div className="self-stretch px-4 pt-4 flex-col justify-start items-center gap-2.5 flex">
          <div className="self-stretch p-4 rounded-lg border border-dashed border-gray-300 flex-col justify-center items-center gap-3 flex">
            <div className="flex-col justify-center items-center gap-1 flex">
              <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
                {t("dashboard.features.lmc.incrementalCard.key1")}
              </div>
              <div className="text-center text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
                12
              </div>
            </div>
            <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
              <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                <div className="text-center text-red-600 text-base font-semibold font-['Figtree'] leading-normal">
                  {t("dashboard.features.lmc.incrementalCard.key2")}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="self-stretch px-4 pb-5 flex-col justify-center items-center gap-3 flex pt-4">
          <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
            {t("dashboard.features.lmc.incrementalCard.key3")}
          </div>

          <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
            <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
              <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                +1
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncrementalCard;
