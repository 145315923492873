import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import HttpService from "../../services/http";
import { toast } from "react-toastify";
import { queryClient } from "../../providers/QueryProvider";
import { queryKeys } from "../../constants/queryKeys";
import { User } from "../../components/pages/dashboard/users/UsersListComponent";

type GroupProps = {
  customer_identification: string;
  entityIdentification: string;
  groupId: string;
  pageParam?: number;
  limit?: number;
};

interface GroupUpdateProps extends GroupProps {
  data: any;
}

type CreateGroupProps = {
  customer_identification: string;
  entityIdentification: string;
  data: any;
};

type Groups = {
  pageParam?: number;
  customer_identification: string;
  entityIdentification: string;
  limit: number;
  sortOrder?: "DESC" | "ASC" | "";
};

type DeleteGroupsProps = {
  customer_identification: string;
  entityIdentification: string;
  groups: any;
};

type ExcludeUsersFromGroupProps = {
  customer_identification: string;
  entityIdentification: string;
  group_identification: string;
  users: any;
};
export const useDeleteGroup = () => {
  return useMutation<GroupProps, AxiosError<any, any>, any>(
    ({ customer_identification, entityIdentification, groupId }: GroupProps) =>
      HttpService.delete(
        `/${customer_identification}/${entityIdentification}/group/${groupId}`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Group deleted successfully");
        queryClient.invalidateQueries([
          queryKeys.groups,
          queryKeys.group_users,
        ]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useUpdateGroup = () => {
  return useMutation<GroupUpdateProps, AxiosError<any, any>, any>(
    ({
      customer_identification,
      entityIdentification,
      groupId,
      data,
    }: GroupUpdateProps) =>
      HttpService.patch(
        `/${customer_identification}/${entityIdentification}/group/${groupId}`,
        {
          group_name: data.group_name,
          group_description: data.group_description,
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Group updated successfully");
        queryClient.invalidateQueries([queryKeys.groups, queryKeys.group]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useGetGroup = ({
  groupId,
  customer_identification,
  entityIdentification,
}: GroupProps) => {
  return useQuery<any, any, any>(
    queryKeys.group,
    async () => {
      return await HttpService.get(
        `/${customer_identification}/${entityIdentification}/group/${groupId}`,
        {
          auth: HttpService.getToken(),
        }
      ).then((data) => {
        return data?.data?.element;
      });
    },
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useGetGroupUsers = ({
  groupId,
  customer_identification,
  entityIdentification,
  pageParam,
  limit,
}: GroupProps) => {
  return useQuery<any, any, any>(
    [
      queryKeys.group_users,
      groupId,
      customer_identification,
      entityIdentification,
      pageParam,
      limit,
    ],
    async () => {
      return await HttpService.get(
        `/${customer_identification}/${entityIdentification}/group/${groupId}/users?limit=${limit}&page=${pageParam}`,
        {
          auth: HttpService.getToken(),
        }
      ).then((data) => {
        let result: Array<User> = [];
        data?.data?.element?.users?.map((item: any, index: number) => {
          result.push({
            id: index,
            user_identification: item?.userIdentification,
            user_account_status: item?.userAccountStatus,
            user_creation_date_time: item?.userCreationDateTime,
            user_email: item?.userEmail,
            user_is_active: item?.userIsActive,
            user_role: item?.userRole,
            user_type: item?.userType,
          });
        });
        return {
          data: result || [],
          pagination: data?.data?.element?.pagination,
        };
      });
    },
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useGetGroups = ({
  limit,
  pageParam,
  customer_identification,
  entityIdentification,
  sortOrder,
}: Groups) => {
  return useQuery<any, any, any>(
    [
      queryKeys.groups,
      customer_identification,
      entityIdentification,
      limit,
      pageParam,
      sortOrder,
    ],
    async () => {
      return await HttpService.get(
        `/${customer_identification}/${entityIdentification}/group?limit=${limit}&page=${pageParam}&sortOrder=${sortOrder}`,
        {
          auth: HttpService.getToken(),
        }
      ).then((data) => {
        return data?.data?.element;
      });
    },
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useCreateroup = () => {
  return useMutation<CreateGroupProps, AxiosError<any, any>, any>(
    ({
      customer_identification,
      entityIdentification,
      data,
    }: CreateGroupProps) =>
      HttpService.post(
        `${customer_identification}/${entityIdentification}/group`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Group created successfully");
        queryClient.invalidateQueries([queryKeys.groups]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useDeleteGroups = () => {
  return useMutation<DeleteGroupsProps, AxiosError<any, any>, any>(
    ({
      customer_identification,
      entityIdentification,
      groups,
    }: DeleteGroupsProps) =>
      HttpService.delete(
        `/${customer_identification}/${entityIdentification}/group/bulk`,
        {
          data: { groups: groups },
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Groups deleted successfully");
        queryClient.invalidateQueries([
          queryKeys.groups,
          queryKeys.group_users,
        ]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};

export const useDeleteUsersFromGroup = () => {
  return useMutation<ExcludeUsersFromGroupProps, AxiosError<any, any>, any>(
    ({
      customer_identification,
      entityIdentification,
      group_identification,
      users,
    }: ExcludeUsersFromGroupProps) =>
      HttpService.delete(
        `/${customer_identification}/${entityIdentification}/user/group/${group_identification}/bulk`,
        {
          data: { users: users },
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Users removed successfully");
        queryClient.invalidateQueries([
          queryKeys.groups,
          queryKeys.group_users,
          queryKeys.users,
        ]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );
};
