import { Button, Modal, Spinner } from "flowbite-react";
import Plans from "../Plans";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useGetPlans } from "../../hooks/plans";

export const PlansModal = ({
  showPlansModal,
  setShowPlansModal,
}: {
  showPlansModal: boolean;
  setShowPlansModal: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { customer_identification, user } = useAuth();
  const { isLoading, isError, isSuccess, data, refetch } = useGetPlans({
    customer_identification: customer_identification,
    entityIdentification: user.entityIdentification,
  });

  let content;
  if (isLoading) content = <Spinner size="sm" />;
  if (isError)
    content = (
      <Button onClick={() => refetch()} size="sm">
        {t("dashboard.billings.btn-05")}
      </Button>
    );

  if (isSuccess)
    content = (
      <Plans
        user_identification={customer_identification}
        entity_identification={user.entityIdentification}
        isInBilling={true}
        hasPremiumPlan={[
          "UMD_PREMIUM_BUSINESS_MONTH",
          "UMD_PREMIUM_BUSINESS_ANNUAL",
        ].some(
          (r: string) => data?.planList?.map((p: any) => p.plan).indexOf(r) >= 0
        )}
      />
    );
  return (
    <Modal
      show={showPlansModal}
      onClose={() => setShowPlansModal(false)}
      size="7xl"
    >
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer className="w-full">
        <Button
          className="ml-auto mr-0 bg-sky-500 hover:bg-sky-700"
          onClick={() => setShowPlansModal(false)}
        >
          {t("dashboard.billings.plansModal.btn-01")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
