import { Modal } from "flowbite-react";
import { useTranslation } from "react-i18next";

export default function UpgradeModal({
  showModal,
  setShowModal,
  setShowPlansModal,
}: {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  setShowPlansModal: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <Modal show={showModal} onClose={() => setShowModal(false)}>
      <Modal.Body className="p-0">
        <div className="block md:flex p-6  gap-3 justify-items-center">
          <img
            title="Super User Manager"
            loading="lazy"
            src="/images/upgradeWhite.svg"
            alt="Upgrade"
            className="img-fluid w-[56px] h-[56px] "
          />

          <div className="h-auto flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-center md:text-left self-stretch  text-gray-900 text-lg font-semibold font-['Figtree'] leading-7">
              {t("dashboard.upgrade.key1")}
            </div>
            <div className="text-center md:text-left self-stretch  text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.upgrade.key2")}
            </div>
          </div>
        </div>
        <div className="w-full h-auto md:h-16 px-6 py-3 bg-gray-50 justify-end items-center gap-3 flex flex-col-reverse md:flex-row ">
          <button
            onClick={() => setShowModal(false)}
            className="w-full md:w-[75px] px-4 py-2 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center flex overflow-hidden"
          >
            <div className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {t("dashboard.deleteOwner.key3")}
            </div>
          </button>
          <div className="w-full md:w-[150px] h-9 px-4 py-2 bg-sky-600 rounded-md shadow-sm justify-center items-center inline-flex overflow-hidden">
            <button
              type="submit"
              onClick={() => {
                setShowPlansModal(true);
                setShowModal(false);
              }}
              className="text-white text-sm font-semibold font-['Figtree'] leading-tight"
            >
              {t("dashboard.upgrade.key3")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
