import moment from "moment-timezone";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

type Props = {
  currentTimeZone: string;
  onChange: (timezone: string) => void;
};

const TimeZoneSelector: FC<Props> = ({ currentTimeZone, onChange }) => {
  const { t } = useTranslation();

  const options = moment.tz.names().map((zone) => {
    return {
      value: zone,
      label: "(UTC" + moment().tz(zone).format("Z") + ") " + zone,
    };
  });

  return (
    <ReactSelect
      className="w-64 py-1 bg-white text-gray-700"
      options={options}
      maxMenuHeight={30}
      value={
        currentTimeZone
          ? {
              value: moment.tz.names().find((item) => item === currentTimeZone),
              label:
                "(UTC" +
                moment().tz(currentTimeZone).format("Z") +
                ") " +
                currentTimeZone,
            }
          : {
              value: moment.tz
                .names()
                .find((item) => item === moment.tz.guess()),
              label:
                "(UTC" +
                moment().tz(moment.tz.guess()).format("Z") +
                ") " +
                moment.tz.guess(),
              // " (" +
              // moment().tz(moment.tz.guess()).format("hh:mm a ") +
              // ")"
            }
      }
      onChange={(e) => {
        onChange(e?.value as string);
      }}
    />
  );
};

export default TimeZoneSelector;
