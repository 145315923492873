import { useEffect, useState } from "react";
import { Button, Label, TextInput, Select, Textarea } from "flowbite-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import HttpService from "../../../../services/http";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";
import { queryClient } from "../../../../providers/QueryProvider";
export type EntityPreferencesTypes = {
  data: any;
  user_identification: string;
  entity_identification: string;
};

export const schema = Joi.object({
  web_site_login_page: Joi.string()
    .trim()
    .uri()
    .required()
    .label("Login page address"),
  web_site_reset_password_page: Joi.string()
    .trim()
    .uri()
    .required()
    .label("Reset password page address"),
  cdn_logo_project_path: Joi.string().trim().required().label("Logo path"),
  webhook_url: Joi.string()
    .trim()
    .uri()
    .optional()
    .allow(null, "")
    .label("Webhook URL"),
  email_address_sender: Joi.string()
    .trim()
    .required()
    .label("Sender email address"),
  password_validation_regex_level: Joi.string()
    .trim()
    .valid("level1", "level2", "level3", "level4", "level5", "level6")
    .required()
    .label("Login password security level"),
  allowed_user_email_domain: Joi.string()
    .trim()
    .optional()
    .allow(null, "")
    .label("Whitelist email domains"),
}).required();

const EntityPreferences = ({
  entity_identification,
  user_identification,
  data,
}: EntityPreferencesTypes) => {
  const { t } = useTranslation();

  const localStorage = useAppLocalStorage();

  const [updateErrors, setUpdateErrors] = useState<any>({});

  const { userEndedSubscription } = useAuth();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      web_site_login_page: data?.web_site_login_page ?? "",
      web_site_reset_password_page: data?.web_site_reset_password_page ?? "",
      cdn_logo_project_path: data?.cdn_logo_project_path ?? "",
      webhook_url: data?.webhook_url ?? "",
      email_address_sender: data?.email_address_sender ?? "",
      password_validation_regex_level:
        data?.password_validation_regex_level ?? "",
      allowed_user_email_domain: data?.allowed_user_email_domain ?? "",
    },
  });

  const {
    data: passwordLevelsObj,
    isSuccess: isPassLevelsSuccess,
    isError: isPassLevelsError,
    refetch: passLevelRefetch,
  } = useQuery(
    "password-levels",
    () => HttpService.get("/auth/password-levels"),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  useEffect(() => {
    setValue("web_site_login_page", data?.web_site_login_page);
    setValue(
      "web_site_reset_password_page",
      data?.web_site_reset_password_page
    );
    setValue("cdn_logo_project_path", data?.cdn_logo_project_path);
    setValue("webhook_url", data?.webhook_url);
    setValue("email_address_sender", data?.email_address_sender);
    setValue(
      "password_validation_regex_level",
      data?.password_validation_regex_level
    );
    setValue(
      "allowed_user_email_domain",
      data?.allowed_user_email_domain?.join("\n")
    );
  }, [data, setValue]);

  const UpdateEntityPreferences = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/entity/preferences`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        toast.success("Preferences updated successfully");

        localStorage.setItem(
          "_umd_password_level",
          res.data.element.password_validation_regex_level
        );

        queryClient.invalidateQueries([
          "entity_preferences",
          entity_identification,
        ]);
        queryClient.invalidateQueries("entity-check");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setUpdateErrors(err.response?.data.element);
      },
    }
  );

  const onUpdatePreferences = (data: any) => {
    if (data.allowed_user_email_domain && data.allowed_user_email_domain !== "")
      data.allowed_user_email_domain =
        data.allowed_user_email_domain.split("\n");
    else data.allowed_user_email_domain = [];

    UpdateEntityPreferences.mutate({
      entity_data_preferences: {
        ...data,
      },
      delete_keys:
        !data.webhook_url || data.webhook_url === ""
          ? ["webhook_url"]
          : undefined,
    } as any);
  };

  let content;

  if (isPassLevelsError)
    content = <Button onClick={() => passLevelRefetch()}> Retry</Button>;

  if (isPassLevelsSuccess)
    content = (
      <form onSubmit={handleSubmit(onUpdatePreferences)}>
        <h3 className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.entities.entityPreferences.title")}
        </h3>

        <div className="pb-2 border-b border-solid">
          {/* Your own login page URL */}
          <div className="mb-3 w-full">
            <Label
              htmlFor="web_site_login_page"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("dashboard.entities.entityPreferences.label-01")}
            </Label>
            <TextInput
              id="web_site_login_page"
              type="url"
              color={
                !!errors.web_site_login_page ||
                !!updateErrors?.web_site_login_page?.message
                  ? "failure"
                  : undefined
              }
              defaultValue={data?.web_site_login_page}
              {...register("web_site_login_page")}
              helperText={
                errors.web_site_login_page?.message ||
                updateErrors?.web_site_login_page?.message
              }
            />
          </div>

          {/* Your own reset password page URL */}
          <div className="mb-3 w-full">
            <Label
              htmlFor="web_site_reset_password_page"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("dashboard.entities.entityPreferences.label-02")}
            </Label>
            <TextInput
              id="web_site_reset_password_page"
              type="url"
              color={
                !!errors.web_site_reset_password_page ||
                !!updateErrors?.web_site_reset_password_page?.message
                  ? "failure"
                  : undefined
              }
              defaultValue={data?.web_site_reset_password_page}
              {...register("web_site_reset_password_page")}
              helperText={
                errors.web_site_reset_password_page?.message ||
                updateErrors?.web_site_reset_password_page?.message
              }
            />
          </div>

          {/* Webhook URL */}
          <div className="mb-3 w-full">
            <Label
              htmlFor="Webhook_url"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              Webhook URL
            </Label>
            <TextInput
              id="Webhook_url"
              type="url"
              color={
                !!errors.webhook_url || !!updateErrors?.webhook_url?.message
                  ? "failure"
                  : undefined
              }
              defaultValue={data?.webhook_url}
              {...register("webhook_url")}
              helperText={
                errors.webhook_url?.message ||
                updateErrors?.webhook_url?.message
              }
            />
          </div>

          {/* Your sender email address which will be used in emails to your users  */}
          <div className="mb-3 w-full">
            <Label
              htmlFor="email_address_sender"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("dashboard.entities.entityPreferences.label-03")}
            </Label>
            <TextInput
              id="email_address_sender"
              type="email"
              color={
                !!errors.email_address_sender ||
                !!updateErrors?.email_address_sender?.message
                  ? "failure"
                  : undefined
              }
              defaultValue={data?.email_address_sender}
              {...register("email_address_sender")}
              helperText={
                errors.email_address_sender?.message ||
                updateErrors?.email_address_sender?.message
              }
            />
          </div>

          {/* The minimum password level of your users */}
          <div className="mb-3 w-full">
            <Label
              htmlFor="password_validation_regex_level"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("dashboard.entities.entityPreferences.label-04")}
            </Label>
            <Select
              id="password_validation_regex_level"
              color={
                !!errors.password_validation_regex_level ||
                !!updateErrors?.password_validation_regex_level?.message
                  ? "failure"
                  : undefined
              }
              defaultValue={
                isPassLevelsSuccess && data?.password_validation_regex_level
              }
              {...register("password_validation_regex_level")}
              helperText={
                errors.password_validation_regex_level?.message ||
                updateErrors?.password_validation_regex_level?.message
              }
            >
              <option value="">
                {t("dashboard.entities.entityPreferences.select-option")}
              </option>
              {isPassLevelsSuccess &&
                Object.keys(passwordLevelsObj?.data?.element).map(
                  (key, index) => (
                    <option key={key} value={key}>
                      {key} ={" "}
                      {passwordLevelsObj?.data?.element[key]?.description}
                    </option>
                  )
                )}
            </Select>
          </div>

          {/* Whitelist email domains  */}
          <div className="mb-3 w-full">
            <Label
              htmlFor="allowed_user_email_domain"
              className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
            >
              {t("dashboard.entities.entityPreferences.label-05")}
            </Label>
            <Textarea
              id="allowed_user_email_domain"
              defaultValue={data?.allowed_user_email_domain}
              {...register("allowed_user_email_domain")}
              rows={10}
              helperText={
                errors.allowed_user_email_domain?.message ||
                updateErrors?.allowed_user_email_domain?.message
              }
              placeholder={
                t(
                  "dashboard.entities.entityPreferences.label-05-placeholder"
                ) as string
              }
            />
          </div>
          <Label className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
            {t("dashboard.entities.entityPreferences.label-05-helper-01")}
          </Label>
          <br />
          <Label className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
            {t("dashboard.entities.entityPreferences.label-05-helper-02")}
          </Label>

          <TextInput
            type="hidden"
            defaultValue={data?.cdn_logo_project_path}
            {...register("cdn_logo_project_path")}
          />
        </div>

        <Button
          type="submit"
          color="blue"
          className="mt-5 mr-0 ml-auto bg-sky-500 hover:bg-sky-700"
          disabled={
            UpdateEntityPreferences.isLoading ||
            userEndedSubscription ||
            !isValid ||
            JSON.stringify({
              web_site_login_page: watch("web_site_login_page"),
              web_site_reset_password_page: watch(
                "web_site_reset_password_page"
              ),
              cdn_logo_project_path: watch("cdn_logo_project_path"),
              email_address_sender: watch("email_address_sender"),
              password_validation_regex_level: watch(
                "password_validation_regex_level"
              ),
              webhook_url: watch("webhook_url"),
              allowed_user_email_domain: watch("allowed_user_email_domain"),
            }) ===
              JSON.stringify({
                web_site_login_page: data?.web_site_login_page,
                web_site_reset_password_page:
                  data?.web_site_reset_password_page,
                cdn_logo_project_path: data?.cdn_logo_project_path,
                email_address_sender: data?.email_address_sender,
                password_validation_regex_level:
                  data?.password_validation_regex_level,
                webhook_url: data?.webhook_url,
                allowed_user_email_domain: data?.allowed_user_email_domain,
              })
          }
          isProcessing={UpdateEntityPreferences.isLoading}
        >
          {t("dashboard.entities.entityPreferences.btn-01")}
        </Button>
      </form>
    );

  return <div>{content}</div>;
};

export default EntityPreferences;
