import moment from "moment";
import { useLmcUserView } from "../../hooks/useLmcUserView";
import { useTranslation } from "react-i18next";
import { currencyMapper } from "../../../dashboard/lmc/AmountCard";

const LmcRewardDeadline = ({
  percent = 0,
  expiryInfo,
}: {
  percent?: number;
  expiryInfo: {
    deadline: string;
    creationDate: string;
  };
}) => {
  const { t } = useTranslation();

  const {
    state: { entity },
  } = useLmcUserView();

  return (
    <div className="w-full py-20 px-4 flex flex-col justify-start items-center gap-4">
      <h4 className="text-center text-gray-50 text-xl font-semibold font-['Figtree'] leading-7">
        {t("lmcUser.key41")}
      </h4>
      <h2 className="text-center text-emerald-300 text-6xl font-extrabold font-['Figtree'] leading-[60px]">
        {entity.process_data?.pre_defined_value.type === "percentage"
          ? `-${percent}%`
          : `${
              currencyMapper[
                entity.process_data?.pre_defined_value.currency as string
              ].symbol
            }${percent}`}
      </h2>
      <h4 className="mt-2 text-center text-gray-50 text-xl font-semibold font-['Figtree'] leading-7">
        {t("lmcUser.key42")}
      </h4>
      <h2 className="text-center text-gray-50 text-3xl font-bold font-['Figtree'] leading-9">
        {expiryInfo.creationDate && moment(expiryInfo.creationDate).isValid()
          ? moment(expiryInfo.creationDate)
              .add(expiryInfo.deadline, "days")
              .format("ll")
          : t("lmcUser.key43")}
      </h2>
    </div>
  );
};

export default LmcRewardDeadline;
