import { group } from "console";

export const queryKeys = {
  groups: "groups",
  group_users: "group-users",
  group: "group",
  users: "entity-users",
  messages: "messages",
  searchUsers: "searchUsers",
  plans: "plans",
  entityPreferences: "entityPreferences",
};
