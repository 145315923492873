import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import useAuth from "../hooks/useAuth";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../hooks/useWindowSize";
import { FaArrowRight, FaCopy, FaPlus } from "react-icons/fa6";
import {
  Button,
  Checkbox,
  Dropdown,
  Label,
  Modal,
  Table,
  TableHead,
  Textarea,
  TextInput,
} from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useCreateroup, useDeleteGroups, useGetGroups } from "../hooks/groups";
import Pagination from "../components/public/Pagination";
import { BiTrash } from "react-icons/bi";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const schema = Joi.object({
  group_name: Joi.string().trim().min(2).max(20).required().label("Group name"),
  group_description: Joi.string()
    .trim()
    .min(2)
    .max(300)
    .required()
    .label("Group description"),
});

export const Copy = ({ target }: { target: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(target);

    toast.info("Copied");

    setIsCopied(true);
  };

  useEffect(() => {
    let tF: any;

    if (isCopied) {
      tF = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(tF);
    };
  }, [isCopied]);

  return (
    <React.Fragment>
      {target}
      <button onClick={handleCopyToClipboard} className="inline-flex ml-2">
        <FaCopy color={isCopied ? "#5CAEF9" : "#9CA3AF"} />
      </button>
    </React.Fragment>
  );
};

const Groups = () => {
  const [t] = useTranslation();
  const [size] = useWindowSize();
  const { user, customer_identification, userEndedSubscription } = useAuth();

  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [limit, setLimit] = useState<10 | 30 | 50>(
    localStorage.getItem("umd_groups_list_limit") &&
      [10, 30, 50].includes(
        Number(localStorage.getItem("umd_groups_list_limit")) as any
      )
      ? (Number(localStorage.getItem("umd_groups_list_limit")) as any)
      : 10
  );
  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());
  const [openModal, setOpenModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const {
    isLoading,
    isError,
    isSuccess,
    data: res,
    refetch,
  } = useGetGroups({
    customer_identification: customer_identification,
    entityIdentification: user.entityIdentification,
    limit: limit,
    pageParam: offset,
    sortOrder: sortOrder,
  });
  const { mutate: createGroup, isLoading: isCreatingGroup } = useCreateroup();
  const { mutate: deleteGroups, isLoading: isDeletingGroup } =
    useDeleteGroups();

  useEffect(() => {
    setLimit(res?.pagination.limit ?? limit);
    setOffset(res?.pagination.offset ?? offset);
  }, [res]);

  const handleShowAddGroup = () => {
    reset({
      group_name: "",
      group_description: "",
    });
    setShowCreateGroupModal(true);
  };

  const onSubmit = (values: any) => {
    createGroup(
      {
        customer_identification: customer_identification,
        entityIdentification: user.entityIdentification,
        data: values,
      },
      {
        onSuccess: () => {
          reset();
          setShowCreateGroupModal(false);
        },
      }
    );
  };

  const toggleRowSelection = (rowId: string) => {
    setSelectedRowIds((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(rowId)) {
        newSelected.delete(rowId);
      } else {
        newSelected.add(rowId);
      }
      return newSelected;
    });
  };
  const handlePageChange = (pageNumber: number) => {
    setOffset(pageNumber * limit);
  };

  const calculateCurrentPage = useCallback(() => {
    const totalItems = res?.pagination.total;

    if (limit <= 0) {
      throw new Error("Limit must be a positive integer.");
    }

    if (offset < 0) {
      throw new Error("Offset cannot be negative.");
    }

    // Ensure we don't have an invalid offset exceeding total items
    let newOffset = Math.min(offset, totalItems - 1);

    // Calculate the current page based on the offset and limit
    const currentPage = Math.floor(newOffset / limit);

    return currentPage;
  }, [limit, offset, res?.pagination.total]);

  const handleDeleteGroup = () => {
    deleteGroups(
      {
        customer_identification: customer_identification,
        entityIdentification: user.entityIdentification,
        groups: selectedRowIds.values().toArray(),
      },
      {
        onSuccess: () => {
          setOpenModal(false);
          setSelectedRowIds(new Set());
          refetch();
        },
      }
    );
  };

  let content;

  if (isLoading) content = <div className="p-4">Loading...</div>;

  if (isError)
    content = (
      <div className="p-4">
        {" "}
        <Button color="info" onClick={() => refetch()} size="sm">
          {t("dashboard.groupList.btn-01")}
        </Button>
      </div>
    );
  if (isSuccess)
    content = (
      <React.Fragment>
        <div className="overflow-x-auto">
          <Table striped>
            <TableHead>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                {/* # */}
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setSelectedRowIds(
                      isChecked
                        ? new Set(
                            res?.groups?.map(
                              (item: any, index: number) =>
                                item?.group_identification
                            )
                          )
                        : new Set()
                    );
                  }}
                  checked={
                    selectedRowIds.size === res?.groups?.length &&
                    res?.groups?.length > 0
                  }
                  className="appearance-none checked:bg-blue-600 focus:ring-none"
                />
              </Table.HeadCell>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                {t("dashboard.groupList.item-02")}
              </Table.HeadCell>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                {size > 600 ? t("dashboard.groupList.item-01") : "ID"}
              </Table.HeadCell>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                {t("dashboard.groupList.description")}
              </Table.HeadCell>
              <Table.HeadCell className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5">
                {/* {t("dashboard.groupList.item-03")} */}
              </Table.HeadCell>
            </TableHead>

            <Table.Body>
              {res?.groups?.length > 0 ? (
                res?.groups?.map((item: any, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell className="px-3 py-2.5">
                      <Checkbox
                        onChange={() =>
                          toggleRowSelection(item.group_identification)
                        }
                        checked={selectedRowIds.has(item.group_identification)}
                        id={item.group_identification}
                        className="appearance-none checked:bg-blue-600 focus:ring-none"
                      />
                      {/* {index + 1} */}
                    </Table.Cell>
                    <Table.Cell
                      className="px-3 py-2.5 truncate"
                      style={{ maxWidth: 100 }}
                    >
                      {item.group_name}
                    </Table.Cell>
                    <Table.Cell
                      className="px-3 py-2.5 truncate"
                      style={{ maxWidth: 100 }}
                    >
                      {item.group_identification}
                    </Table.Cell>
                    <Table.Cell
                      className="px-3 py-2.5 truncate"
                      style={{ maxWidth: 100 }}
                    >
                      {item?.group_description}
                    </Table.Cell>

                    <Table.Cell
                      className="px-3 py-2.5"
                      style={{ justifyContent: "start" }}
                    >
                      <Link to={`${item.group_identification}`} className="">
                        <FaArrowRight />
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell
                    className="px-3 py-2.5"
                    colSpan={4}
                    align="center"
                  >
                    {t("dashboard.groupList.text-01")}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-between items-center flex-wrap my-6 gap-6">
          <div>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.usersList.key2")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {offset + 1}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key3")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {res?.groups?.length ? res?.groups?.length + offset : 0}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key4")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {res?.pagination.total ?? 0}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key5")}
            </span>
          </div>

          {isSuccess && (
            <Pagination
              pageCount={res?.pagination.total_pages || 0}
              currentPage={calculateCurrentPage()}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </React.Fragment>
    );

  const title = (
    <h1 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
      {t("dashboard.groupList.title-01")}
    </h1>
  );
  const addGroupButton = (
    <button
      className="pl-[11px] pr-[13px] py-[9px] bg-sky-500 rounded-md shadow justify-center items-center gap-2 flex"
      disabled={userEndedSubscription}
      onClick={handleShowAddGroup}
    >
      <div className="shrink-0">
        <FaPlus size={14} color="white" />
      </div>
      <div className="text-white text-sm font-medium font-['Inter'] leading-none">
        {t("dashboard.groupList.btn-03")}
      </div>
    </button>
  );
  const otherButtons = (
    <>
      {/* Delete groups*/}
      {selectedRowIds?.size > 0 && (
        <div
          role="button"
          onClick={() => setOpenModal(true)}
          className="px-2 py-3 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center gap-1 inline-flex overflow-hidden"
        >
          <div data-svg-wrapper className="relative">
            <BiTrash className="text-red-500" size={18} />
          </div>
          <div className="text-gray-700 text-sm font-medium font-['Figtree'] leading-tight">
            {t("dashboard.groupList.deleteGroups")}
          </div>
        </div>
      )}
      {/* limit */}
      <Dropdown
        label={`${limit}`}
        dismissOnClick
        color={"light"}
        className="z-[1]"
        // size={"sm"}
      >
        {[10, 30, 50].map((item: any) => (
          <Dropdown.Item
            key={item}
            onClick={() => {
              localStorage.setItem("umd_groups_list_limit", item.toString());
              setLimit(item);
              setOffset(0);
            }}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown>
      {/* Sort */}
      <Dropdown
        label={
          !sortOrder
            ? t("dashboard.usersList.item-07")
            : sortOrder === "ASC"
            ? "Asc"
            : "Desc"
        }
        dismissOnClick
        color={"light"}
        // size={"sm"}
      >
        {["ASCENDING", "DESCENDING"].map((item: any) => (
          <Dropdown.Item
            key={item}
            onClick={() =>
              setSortOrder(
                item === "ASCENDING"
                  ? "ASC"
                  : item === "DESCENDING"
                  ? "DESC"
                  : ""
              )
            }
          >
            {item === "ASCENDING"
              ? t("dashboard.groupList.ascending")
              : t("dashboard.groupList.descending")}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  );
  return (
    <div className="groups-page">
      <Helmet>
        <title>Groups | Super User Manager</title>
        <meta name="description" content="List of groups" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/groups`}
        />
      </Helmet>

      <div className="container mx-auto py-6">
        <div className="block md:hidden flex items-center justify-between mb-6">
          {title}
          <div className="flex flex-wrap items-md-center justify-end gap-4">
            {addGroupButton}
          </div>
        </div>

        <div className="block md:hidden  flex items-center justify-end mb-6 flex-wrap gap-1">
          {otherButtons}
        </div>

        <div className="hidden md:flex  items-center justify-between mb-6">
          {title}
          <div className="flex flex-wrap items-md-center justify-end gap-4">
            {otherButtons}
            {addGroupButton}
          </div>
        </div>

        {content}
      </div>

      {/* Create group modal */}
      <Modal
        show={showCreateGroupModal}
        onClose={() => setShowCreateGroupModal(false)}
      >
        <Modal.Header className="border-0 text-zinc-900 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.groupList.key2")}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <Label
                htmlFor="group_name"
                value={t("dashboard.groupList.label-01").toString()}
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
              />

              <TextInput
                id="group_name"
                placeholder={`${t("dashboard.groupList.placeholder-01")}`}
                color={!!errors.group_name ? "failure" : ""}
                type="text"
                {...register("group_name")}
                helperText={<>{errors.group_name?.message as string}</>}
              />
            </div>

            <div className="mb-6">
              <Label
                htmlFor="group_description"
                value={t("dashboard.groupList.label-02").toString()}
                className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3 block"
              />

              <Textarea
                id="group_description"
                placeholder={`${t("dashboard.groupList.placeholder-02")}`}
                color={!!errors.group_description ? "failure" : ""}
                rows={6}
                helperText={<>{errors.group_description?.message as string}</>}
                {...register("group_description")}
              />
            </div>

            <div className="flex justify-end">
              <Button
                type="submit"
                disabled={isCreatingGroup}
                isProcessing={isCreatingGroup}
                className="bg-sky-500"
                processingSpinner={
                  <>
                    <AiOutlineLoading className="h-6 w-6 animate-spin" />
                  </>
                }
              >
                {t("dashboard.groupList.btn-create")}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Delete groups modal */}
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {t("dashboard.groupList.deleteGroupsTitle")}
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={handleDeleteGroup}
                isProcessing={isDeletingGroup}
                disabled={isDeletingGroup}
              >
                {t("dashboard.groupList.key4")}
              </Button>
              <Button
                color="gray"
                onClick={() => {
                  setOpenModal(false);
                  setSelectedRowIds(new Set());
                }}
              >
                {t("dashboard.groupList.key5")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Groups;
