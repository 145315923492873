import { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Spinner, Table } from "flowbite-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useAuth from "../../../hooks/useAuth";
import HttpService from "../../../services/http";
import { toast } from "react-toastify";
import Plans, { decideGeneralName, PlanNames } from "../../Plans";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  HiChevronDown,
  HiDownload,
  HiPlus,
  HiStar,
  HiTrash,
} from "react-icons/hi";
import moment from "moment";
import ConfirmModal from "../../public/ConfirmModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { queryClient } from "../../../providers/QueryProvider";

type Card = {
  paymentMethodId: string;
  brand: string;
  country: string;
  expDate: string;
  last4: string;
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Billing = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { customer_identification, user, upgradeNow, onOffUpgradeNow } =
    useAuth();

  const [selectedPlan, setSelectedPlan] = useState("My plans");
  const [selectedPlanLabel, setSelectedPlanLabel] =
    useState<string>("My plans");
  const [selectedPlanStatus, setSelectedPlanStatus] = useState<
    undefined | "ACTIVE" | "SUCCEEDED" | "CANCELED" | "ENDED"
  >(undefined);
  const [selectedActivePlanDefaultCardId, setSelectedActivePlanDefaultCardId] =
    useState();
  const [planCards, setPlanCards] = useState<Card[] | []>([]);
  const [showPlanHistoryModal, setShowPlanHistoryModal] = useState(false);
  const [planHistoryData, setPlanHistoryData] = useState<any[] | []>([]);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [fetchPlanCardsLoading, setFetchPlanCardsLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmDeleteCard, setShowConfirmDeleteCard] = useState(false);
  const [selectedCardPaymentMethodId, setSelectedCardPaymentMethodId] =
    useState<string>("");

  useEffect(() => {
    if (upgradeNow) {
      setShowPlansModal(true);
      onOffUpgradeNow(false);
    }
  }, [upgradeNow, onOffUpgradeNow]);

  const {
    isLoading,
    isError,
    data: res,
    refetch,
  } = useQuery("owner-plans", () =>
    HttpService.get(
      `/${customer_identification}/${user.entityIdentification}/billing/plan-status`
    )
  );

  const CancelPlanSubscriptionMutation = useMutation<
    null,
    AxiosError<any, any>,
    any
  >(
    () =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/billing/${selectedPlan}/cancel-subscription`
      ),
    {
      onSuccess: (data: any) => {
        setShowCancelModal(false);
        toast.success(t("dashboard.billings.toast-01"));

        queryClient.invalidateQueries("owner-plans");
        queryClient.invalidateQueries(["plan-cards", selectedPlan]);
        queryClient.invalidateQueries(["plan-detailed-info", selectedPlan]);

        setPlanCards([]);
        setSelectedPlan("My plans");
        setSelectedPlanStatus(undefined);
        setSelectedPlanLabel("My plans");
      },
      onError: (err) => {
        setShowCancelModal(false);
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateSubscriptionCardMutation = useMutation<
    string,
    AxiosError<any, any>,
    any
  >(
    (payment_method_id) =>
      HttpService.put(
        `/${customer_identification}/${user.entityIdentification}/billing/${selectedPlan}/${payment_method_id}/update-subscription-card`,
        null
      ),
    {
      onSuccess: (res: any) => {
        toast.success(t("dashboard.billings.toast-02"));

        queryClient.invalidateQueries(["plan-cards", selectedPlan]);
        queryClient.invalidateQueries(["plan-detailed-info", selectedPlan]);

        handleFetchPlanCards(
          selectedPlan,
          selectedPlanStatus,
          selectedPlanLabel
        );
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const RemovePaymentMethodMutation = useMutation<
    string,
    AxiosError<any, any>,
    any
  >(
    (payment_method_id) =>
      HttpService.delete(
        `/${customer_identification}/${user.entityIdentification}/billing/${selectedPlan}/${payment_method_id}/remove-payment-method`
      ),
    {
      onSuccess: (res: any) => {
        toast.success(t("dashboard.billings.toast-03"));

        setShowConfirmDeleteCard(false);
        setSelectedCardPaymentMethodId("");

        queryClient.invalidateQueries(["plan-cards", selectedPlan]);
        queryClient.invalidateQueries(["plan-detailed-info", selectedPlan]);

        handleFetchPlanCards(
          selectedPlan,
          selectedPlanStatus,
          selectedPlanLabel
        );
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const requestInvoiceHistoryDetails = useMutation<
    string,
    AxiosError<any, any>,
    any
  >((invoiceId) => HttpService.get(`/billing/${invoiceId}/invoice`), {
    onSuccess: (res: any) => {
      // Trigger the download
      const link = document.createElement("a");
      link.href = res.data.element.invoicePDFLink;
      document.body.appendChild(link);
      link.click();
      link.setAttribute("disabled", "true");
      document.body.removeChild(link);
    },
    onError: (err) => {
      toast.error(err.response?.data.message || err.message);
    },
  });

  const handleFetchPlanCards = (
    plan: string,
    status: "ACTIVE" | "CANCELED" | "SUCCEEDED" | "ENDED" | undefined,
    description: string
  ) => {
    setPlanCards([]);
    setSelectedPlan(plan);
    setSelectedPlanLabel(description);
    setSelectedPlanStatus(status);
    setFetchPlanCardsLoading(true);

    if (!plan.includes("FREE") && status !== "CANCELED" && status !== "ENDED") {
      // fetch plan cards
      queryClient
        .fetchQuery(["plan-cards", plan], () =>
          HttpService.get(
            `/${customer_identification}/${user.entityIdentification}/billing/${plan}/subscription-payment-card-detail`
          )
        )
        .then((value) => {
          if (value?.data?.element?.length > 0) {
            let planCardsDetail: any[] = [];
            value.data.element.map((cardDetail: any) => {
              const { expMonth, expYear, ...rest } = cardDetail;
              return planCardsDetail.push({
                ...rest,
                expDate: `${month[+expMonth]}, ${expYear}`,
              });
            });
            setPlanCards([...planCardsDetail]);

            queryClient
              .fetchQuery(["plan-detailed-info", plan], () =>
                HttpService.get(
                  `/${customer_identification}/${user.entityIdentification}/billing/${plan}/plan-detailed-info`
                )
              )
              .then((value) => {
                setFetchPlanCardsLoading(false);
                if (value.data.element.default_payment_method)
                  setSelectedActivePlanDefaultCardId(
                    value.data.element.default_payment_method
                  );
              });
          } else {
            toast.info(t("dashboard.billings.toast-04"));
            setFetchPlanCardsLoading(false);
          }
        })
        .catch((err) => {
          setFetchPlanCardsLoading(false);
          toast.error(err.response.data.message || err.message);
        });
    } else {
      setFetchPlanCardsLoading(false);
      toast.info(t("dashboard.billings.toast-04"));
    }
  };

  const handleShowPlanHistory = () => {
    queryClient
      .fetchQuery(["plan-card-history", selectedPlan], () =>
        HttpService.get(
          `/${customer_identification}/${user.entityIdentification}/billing/${selectedPlan}/subscription-detail`
        )
      )
      .then((value) => {
        setPlanHistoryData(value.data.element);
        setShowPlanHistoryModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data.element || err.message);
        setShowPlanHistoryModal(false);
      });
  };

  const handleCancelSelectedPlanSubscription = () => {
    CancelPlanSubscriptionMutation.mutate(null);
  };

  const handleMakeAsDefault = (paymentMethodId: string) => {
    UpdateSubscriptionCardMutation.mutate(paymentMethodId);
  };

  const handleRemoveCard = () => {
    if (selectedCardPaymentMethodId)
      RemovePaymentMethodMutation.mutate(selectedCardPaymentMethodId);
  };

  useEffect(() => {
    if (
      searchParams &&
      searchParams.has("plan-name") &&
      searchParams.has("status") &&
      searchParams.get("status") === "true"
    ) {
      navigate(
        `/control-panel?plan=${decideGeneralName(
          searchParams.get("plan-name") as PlanNames
        )}&plan_name=${searchParams.get("plan-name")}&status=true`,
        { replace: true }
      );
    }
  }, [navigate, searchParams]);

  const columns: ColumnDef<Card>[] = [
    {
      header: () => t("dashboard.billings.column-01"),
      accessorKey: "brand",
    },
    {
      header: () => t("dashboard.billings.column-02"),
      accessorKey: "country",
    },
    {
      header: () => t("dashboard.billings.column-03"),
      accessorKey: "expDate",
    },
    {
      header: () => t("dashboard.billings.column-05"),
      accessorKey: "last4",
      cell: (props) => {
        return (
          <>
            <span>**** **** **** {props.row.original.last4}</span>
          </>
        );
      },
    },
    {
      id: "Actions",
      cell: (props) => {
        return (
          <>
            <div className="flex justify-center items-center gap-4 text-gray-400">
              {selectedActivePlanDefaultCardId ===
              props.row.original.paymentMethodId ? (
                <div className="p-1 cursor-pointer">
                  {UpdateSubscriptionCardMutation.variables ===
                    props.row.original.paymentMethodId &&
                  UpdateSubscriptionCardMutation.isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <HiStar size={20} className="text-sky-500" />
                  )}
                </div>
              ) : (
                <div
                  className="p-1 cursor-pointer"
                  onClick={() =>
                    handleMakeAsDefault(props.row.original.paymentMethodId)
                  }
                >
                  {UpdateSubscriptionCardMutation.variables ===
                    props.row.original.paymentMethodId &&
                  UpdateSubscriptionCardMutation.isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <HiStar size={20} />
                  )}
                </div>
              )}
              <div
                className="p-1 cursor-pointer"
                onClick={() => {
                  setSelectedCardPaymentMethodId(
                    props.row.original.paymentMethodId
                  );
                  setShowConfirmDeleteCard(true);
                }}
              >
                {RemovePaymentMethodMutation.variables ===
                  props.row.original.paymentMethodId &&
                RemovePaymentMethodMutation.isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <>
                    <HiTrash size={20} />
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const table = useReactTable({
    data: planCards,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) return <Spinner size="sm" />;

  if (isError)
    return (
      <Button onClick={() => refetch()} size="sm">
        {t("dashboard.billings.btn-05")}
      </Button>
    );

  let planList = res?.data.element.planList;

  const renderSelectedPlanLabelTR = (selectedPlanLabel: string) => {
    switch (selectedPlanLabel) {
      case "My plans":
        return t("dashboard.billings.dropDown_01");
      default:
        return selectedPlanLabel;
    }
  };

  return (
    <div className="w-full px-4 py-6 flex-col justify-start items-start gap-5 inline-flex">
      <Helmet>
        <title>Billing | Super User Manager</title>
        <meta
          name="description"
          content={t("dashboard.billings.description") as string}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/control-panel/billing`}
        />
      </Helmet>

      <div className="w-full flex items-start md:items-center justify-between">
        <h1 className=" text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.billings.title")}
        </h1>
        <div className="flex items-end md:items-center justify-start md:justify-end flex-col-reverse md:flex-row flex-wrap gap-3">
          <Dropdown
            label=""
            dismissOnClick={true}
            renderTrigger={() => (
              <div className="w-fit h-[42px] pl-[17px] pr-[13px] py-[9px] bg-white cursor-pointer rounded-md shadow border border-gray-300 text-gray-700 text-sm font-medium font-['Figtree'] leading-tight flex justify-center items-center gap-2">
                {renderSelectedPlanLabelTR(selectedPlanLabel)}
                <HiChevronDown size={20} />
              </div>
            )}
          >
            {planList?.map((item: any, index: number) => (
              <Dropdown.Item
                key={index}
                onClick={() =>
                  handleFetchPlanCards(item.plan, item.status, item.description)
                }
              >
                {item.description}
              </Dropdown.Item>
            ))}
          </Dropdown>
          <Button
            className="bg-sky-500"
            onClick={() => setShowPlansModal(true)}
          >
            <div className="flex justify-center items-center gap-2">
              <HiPlus size={20} />
              {t("dashboard.billings.btn-09")}
            </div>
          </Button>
        </div>
      </div>

      <div className="w-full h-px bg-gray-300"></div>

      {selectedPlan && selectedPlan !== "My plans" && (
        <div className="w-full flex justify-between items-start lg:items-center">
          <h3 className="w-fit">
            <span className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
              {t("dashboard.billings.label-01")}
            </span>
            <span
              className={`${
                selectedPlanStatus === "ACTIVE" ||
                selectedPlanStatus === "SUCCEEDED"
                  ? "text-emerald-800"
                  : "text-red-800"
              }  text-lg font-semibold font-['Figtree'] leading-7`}
            >
              {selectedPlanStatus}
            </span>
          </h3>
          <div className="w-fit flex justify-end items-center flex-wrap gap-3">
            {selectedPlanStatus === "CANCELED" ? (
              <Button
                color={"light"}
                disabled={!planList || planList?.length < 0 || !selectedPlan}
                onClick={handleShowPlanHistory}
              >
                {t("dashboard.billings.btn-06")}
              </Button>
            ) : (
              <>
                <Button
                  color={"light"}
                  disabled={!planList || planList?.length < 0 || !selectedPlan}
                  onClick={handleShowPlanHistory}
                >
                  {t("dashboard.billings.btn-06")}
                </Button>
                {selectedPlan.includes("FREE") ? null : (
                  <>
                    <Button
                      color={"light"}
                      disabled={
                        !planList || planList?.length < 0 || !selectedPlan
                      }
                      href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/subscription/${selectedPlan}/${user.entityIdentification}/${customer_identification}/card-updating?&language_code=en&&redirect_page=${process.env.REACT_APP_HOST_URL}/control-panel/billing&template_image_url=${process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL}`}
                    >
                      {t("dashboard.billings.btn-07")}
                    </Button>
                    <Button
                      color={"light"}
                      disabled={
                        !planList || planList?.length < 0 || !selectedPlan
                      }
                      onClick={() => setShowCancelModal(true)}
                    >
                      {t("dashboard.billings.btn-08")}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {planCards?.length <= 0 && selectedPlan === "My plans" ? (
        fetchPlanCardsLoading ? (
          <Spinner />
        ) : (
          <div className="w-full h-[370px] flex-col justify-center items-center gap-6 inline-flex">
            <h2 className="text-center text-gray-900 text-4xl font-bold font-['Figtree'] leading-10">
              {t("dashboard.billings.text-03")}
            </h2>
            <p className="text-center text-gray-500 text-base font-normal font-['Figtree'] leading-normal">
              {t("dashboard.billings.text-04")}
            </p>
          </div>
        )
      ) : (
        <div className="w-full overflow-x-auto rounded-lg">
          <div className="w-full min-w-[700px] rounded-lg shadow border border-gray-200">
            <Table striped className="w-full">
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Head key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </Table.Head>
              ))}
              <Table.Body className="divide-y">
                {queryClient.getQueryState(["plan-cards", selectedPlan])
                  ?.status === "loading" ? (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell align="center" colSpan={5}>
                      <Spinner size="sm" />
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  table?.getRowModel()?.rows?.map((row) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={row.id}
                      style={{ verticalAlign: "middle" }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <Table.Cell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      )}

      {/* Cancel subscription confirmation Modal */}
      <ConfirmModal
        show={showCancelModal}
        onClose={setShowCancelModal}
        title={t("dashboard.billings.text-01") as string}
        description={t("dashboard.billings.text-02") as string}
        cancelBtnName={t("dashboard.billings.btn-02") as string}
        cancelBtnFn={setShowCancelModal}
        confirmBtnName={t("dashboard.billings.btn-01") as string}
        confirmBtnFn={handleCancelSelectedPlanSubscription}
        isLoading={CancelPlanSubscriptionMutation.isLoading}
        ConfirmBtnColor="red"
      />

      {/* Confirm card delete */}
      <ConfirmModal
        show={showConfirmDeleteCard}
        onClose={setShowConfirmDeleteCard}
        title={t("dashboard.billings.DeleteCardConfirm.key1") as string}
        description={""}
        cancelBtnName={t("dashboard.billings.DeleteCardConfirm.key2") as string}
        cancelBtnFn={setShowConfirmDeleteCard}
        confirmBtnName={
          t("dashboard.billings.DeleteCardConfirm.key3") as string
        }
        confirmBtnFn={handleRemoveCard}
        isLoading={RemovePaymentMethodMutation.isLoading}
        ConfirmBtnColor="red"
      />

      {/*  */}

      {/* Plan history */}
      <Modal
        show={showPlanHistoryModal}
        onClose={() => setShowPlanHistoryModal(false)}
        size="3xl"
        className="plans-history-modal"
      >
        <Modal.Header className="border-none">{selectedPlanLabel}</Modal.Header>
        <Modal.Body>
          <div className="w-full overflow-x-auto">
            <Table>
              <Table.Head>
                <Table.HeadCell>
                  {t("dashboard.billings.planHistoryModal.column-01")}
                </Table.HeadCell>
                <Table.HeadCell>
                  {t("dashboard.billings.planHistoryModal.column-03")}
                </Table.HeadCell>
                <Table.HeadCell>
                  {t("dashboard.billings.planHistoryModal.column-02")}
                </Table.HeadCell>
                {!selectedPlanLabel.includes("Free") && (
                  <Table.HeadCell>
                    {t("dashboard.billings.planHistoryModal.column-04")}
                  </Table.HeadCell>
                )}
              </Table.Head>
              <Table.Body className="divide-y">
                {planHistoryData?.map((item: any, index: number) => (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {moment(item.paymentDateTime).format(
                        "MMMM Do YYYY | H:mm:ss"
                      )}
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {moment(item.paymentNextDate).isValid()
                        ? moment(item.paymentNextDate).format("MMMM Do YYYY")
                        : ""}
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {item.paymentStatusEnum}
                    </Table.Cell>
                    {!selectedPlanLabel.includes("Free") && (
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                        <button
                          className="border-none ring-0 outline-none"
                          disabled={requestInvoiceHistoryDetails.isLoading}
                          onClick={() =>
                            requestInvoiceHistoryDetails.mutate(
                              item.paymentDetailMap.invoice_id
                            )
                          }
                        >
                          <HiDownload size={20} color="black" />
                        </button>
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      {/* Plans modal */}
      <Modal
        show={showPlansModal}
        onClose={() => setShowPlansModal(false)}
        size="7xl"
      >
        <Modal.Body>
          <Plans
            user_identification={customer_identification}
            entity_identification={user.entityIdentification}
            isInBilling={true}
            hasPremiumPlan={[
              "UMD_PREMIUM_BUSINESS_MONTH",
              "UMD_PREMIUM_BUSINESS_ANNUAL",
            ].some(
              (r: string) => planList.map((p: any) => p.plan).indexOf(r) >= 0
            )}
          />
        </Modal.Body>
        <Modal.Footer className="w-full">
          <Button
            className="ml-auto mr-0 bg-sky-500 hover:bg-sky-700"
            onClick={() => setShowPlansModal(false)}
          >
            {t("dashboard.billings.plansModal.btn-01")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Billing;
