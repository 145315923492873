import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { redirectLinksFormProps } from "./types";
import { Button, TextInput } from "flowbite-react";
import { v4 as uuidv4 } from "uuid";
import { BiLinkExternal, BiTrash } from "react-icons/bi";

const RedirectionLinks = ({ data, OnSubmit }: any) => {
  const { t } = useTranslation();
  const schema = Joi.object().keys({
    redirectionLinks: Joi.array()
      .items(
        Joi.object({
          id: Joi.string(),
          label: Joi.string()
            .required()
            .messages({
              "string.empty": t(
                "dashboard.features.lmc.redirectLinks.titleRequiredText"
              ) as string,
            }),
          link: Joi.string()
            .required()
            .pattern(/^https:\/\/.+/i)
            .messages({
              "string.empty": t(
                "dashboard.features.lmc.redirectLinks.linkRequiredText"
              ) as string,
              "string.pattern.base": t(
                "dashboard.features.lmc.redirectLinks.LinkValid"
              ) as string,
            }),
        })
      )
      .max(3)
      .label("redirectionLinks"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<redirectLinksFormProps>({
    resolver: joiResolver(schema),
    defaultValues: { redirectionLinks: [] },
    // mode: "all",
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "redirectionLinks",
  });

  useEffect(() => {
    if (data) {
      reset({ redirectionLinks: data || [] });
    }
  }, [data, reset]);

  const onSubmit = (formData: any) => {
    OnSubmit(formData).then(() => {
      reset();
    });
  };

  return (
    <div className="w-full mt-2">
      {watch("redirectionLinks")?.length === 0 && (
        <div className="self-stretch text-gray-00 text-s font-bold font-['Figtree'] leading-none flex items-center mb-2">
          <BiLinkExternal color="#0ea5e9" />
          <span className="ml-2">
            {t("dashboard.features.lmc.redirectLinks.RedirectionLinkPromotion")}
          </span>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields?.map((item, index) => (
          <div key={item?.id}>
            <div className="h-24 justify-center items-center gap-3 inline-flex w-full mb-4">
              <div className="grow grid shrink basis-0 flex-col gap-3 w-full">
                <div className="h-9 w-full self-stretch justify-start items-start gap-5 inline-flex grow shrink basis-0 flex-col  mb-2">
                  <TextInput
                    color={
                      !!errors.redirectionLinks &&
                      !!errors.redirectionLinks[index]?.label
                        ? "failure"
                        : undefined
                    }
                    defaultValue={item?.label}
                    onWheel={(e) => e.currentTarget.blur()}
                    placeholder={
                      t(
                        "dashboard.features.lmc.redirectLinks.linkTitle"
                      ) as string
                    }
                    {...register(`redirectionLinks.${index}.label`)}
                    className="w-full text-gray-800 text-sm font-normal font-['Figtree'] leading-tight"
                  />
                </div>

                <div className="h-9 w-full self-stretch justify-start items-start gap-5 inline-flex grow shrink basis-0 flex-col  ">
                  <TextInput
                    color={
                      !!errors.redirectionLinks &&
                      !!errors.redirectionLinks[index]?.link
                        ? "failure"
                        : undefined
                    }
                    onWheel={(e) => e.currentTarget.blur()}
                    {...register(`redirectionLinks.${index}.link`)}
                    type="url"
                    placeholder="https://"
                    className="w-full text-gray-800 text-sm font-normal font-['Figtree'] leading-tight"
                    style={{ borderColor: "#d1d5db" }}
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  remove(index);
                  // onSubmit();
                }}
                role="button"
                data-svg-wrapper
                className="border h-full w-[44px] justify-center items-center flex rounded-md border-gray-300"
              >
                <BiTrash size={22} className="text-gray-400" />
              </div>
            </div>

            {!!errors.redirectionLinks && (
              <p className="text-red-600 text-sm mb-3">
                {errors.redirectionLinks[index]?.label?.message as any}
                <br />
                {errors.redirectionLinks[index]?.link?.message as any}
              </p>
            )}
          </div>
        ))}
        {watch("redirectionLinks")?.length < 3 && (
          <button
            type="button"
            onClick={() => {
              append({
                id: uuidv4(),
                label: "",
                link: "",
              });
            }}
            className="w-full h-9 px-4 py-2 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center inline-flex overflow-hidden"
          >
            <div className="text-sky-600 text-sm font-medium font-['Inter'] leading-tight">
              {t("dashboard.features.lmc.redirectLinks.addLinkButton")}
            </div>
          </button>
        )}

        <Button
          disabled={!!errors.redirectionLinks}
          type="submit"
          color="blue"
          className={`w-full my-5 mr-0 ml-auto ${
            errors.redirectionLinks
              ? "bg-gray-300 hover:bg-gray-500"
              : "bg-sky-500 hover:bg-sky-700"
          } `}
        >
          {t("lmcUser.key56")}
        </Button>
      </form>

      <div className="self-stretch h-px bg-gray-300 block mt-4" />
    </div>
  );
};
export default RedirectionLinks;
