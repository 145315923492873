import { AxiosError, AxiosResponse } from "axios";
import { Button } from "flowbite-react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { queryClient } from "../../../../providers/QueryProvider";
export type ServiceAvatarUpdateTypes = {
  data: any;
  user_identification: string;
  entity_identification: string;
  onChangeNewLogo: (path: string) => void;
};

export const umdBase64 =
  process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL;

const ServiceAvatarUpdate = ({
  data,
  entity_identification,
  user_identification,
}: ServiceAvatarUpdateTypes) => {
  const [t] = useTranslation();

  const inputFileRef = useRef<any>(null);

  const CdnAvatarMutation = useMutation<any, AxiosError<any, any>>(
    (data: any) =>
      HttpService.post(
        `/${user_identification}/${entity_identification}/cdn/avatar`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateEntityPreferences = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/entity/preferences`,
        data,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        toast.success(res.data.message);

        queryClient.invalidateQueries([
          "entity_preferences",
          entity_identification,
        ]);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleTriggerFileInput = () => {
    let inputFileElement = document.getElementById("avatarInput");

    inputFileRef.current.value = "";

    inputFileElement?.click();
  };

  const handleUploadAvatar = (files: any) => {
    if (files.length > 0) {
      if (files[0].size > 3 * 1048576) return toast.error("File too large");

      CdnAvatarMutation.mutate({ image: files[0] } as any, {
        onSuccess: (res: AxiosResponse) => {
          UpdateEntityPreferences.mutate({
            entity_data_preferences: {
              cdn_logo_project_path: res.data.element[0].cdnPath,
            },
            //delete_keys: [],
          });

          inputFileRef.current.value = "";
        },
      });
    }
  };

  const handleDeleteServiceAvatar = () => {
    UpdateEntityPreferences.mutate({
      entity_data_preferences: { cdn_logo_project_path: umdBase64 },
      //delete_keys: [],
    });
  };

  return (
    <div className="flex flex-col justify-start items-start gap-2">
      <p className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
        {t("dashboard.develop.serviceAvatarUpdate.logo")}
      </p>
      <div className="flex justify-start items-center">
        <div className={`w-12 h-12 bg-gray-100 rounded-md overflow-hidden`}>
          <img
            title="Super User Manager"
            loading="lazy"
            className="object-cover w-12 h-12"
            src={data?.cdn_logo_project_path}
            alt="send"
          />
        </div>
        <Button
          className="ml-5"
          color="light"
          size="sm"
          onClick={handleTriggerFileInput}
          isProcessing={CdnAvatarMutation.isLoading}
        >
          {t("dashboard.develop.serviceAvatarUpdate.btn-01")}
        </Button>
        {data.cdn_logo_project_path !== umdBase64 && (
          <Button
            className="ml-4"
            color="light"
            size="sm"
            onClick={handleDeleteServiceAvatar}
            isProcessing={UpdateEntityPreferences.isLoading}
          >
            <span>{t("dashboard.develop.serviceAvatarUpdate.btn-02")}</span>
          </Button>
        )}
      </div>
      <input
        type={"file"}
        hidden
        id="avatarInput"
        accept=".jpg,.png,.jpeg"
        onChange={(e) => handleUploadAvatar(e.target.files)}
        ref={inputFileRef}
      />
    </div>
  );
};

export default ServiceAvatarUpdate;
