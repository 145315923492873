import { lmcOptionType } from "./types";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useRef, useState } from "react";
import QRCodeStyling from "livebook-qr-code";

const ResultLmcCard = ({
                         lmc,
                         preferencesData,
                         processId,
                       }: {
  lmc: lmcOptionType;
  preferencesData: { [key: string]: any };
  processId: string;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpeningNewTab, setIsOpeningNewTab] = useState(false);
  const [qrCode, setQrCode] = useState<QRCodeStyling | null>(null);

  useEffect(() => {
    const qrInstance = new QRCodeStyling({
      width: 800,
      height: 800,
      type: "svg",
      qrOptions: { errorCorrectionLevel: "H" },
      imageOptions: { crossOrigin: "anonymous", margin: 5 },
      dotsOptions: { color: "#000000", type: "square" },
      backgroundOptions: { color: "#FFFFFF" },
    });

    setQrCode(qrInstance);
  }, []);

  const qrInitializer = useCallback(async () => {
    let targetSrc;

    try {
      await fetch(
          process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL as string
      ).then((res) => {
        if (res.status === 200) {
          targetSrc = process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL;
        }
      });
    } catch (error) {
      targetSrc = "/images/logo.png";
    }

    if (qrCode) {
      qrCode.update({
        data: `${process.env.REACT_APP_HOST_URL}/process/${processId}`,
        image: targetSrc,
        dotsOptions: { color: "#000000", type: "square" },
      });

      if (ref.current) {
        ref.current.innerHTML = "";
        const tempQR = new QRCodeStyling({
          width: 250,
          height: 250,
          type: "svg",
          qrOptions: { errorCorrectionLevel: "H" },
          imageOptions: { crossOrigin: "anonymous", margin: 5 },
          dotsOptions: { color: "#000000", type: "square" },
          backgroundOptions: { color: "#FFFFFF" },
        });
        tempQR.update({
          data: `${process.env.REACT_APP_HOST_URL}/process/${processId}`,
          image: targetSrc,
        });
        tempQR.append(ref.current);
      }
    }
  }, [qrCode, processId]);

  useEffect(() => {
    qrInitializer();
  }, [qrInitializer]);

  const handleDownloadQrCode = async () => {
    if (qrCode) {
      const blob = await qrCode.getRawData("png");
      if (blob) {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${lmc}_${processId}.png`;
        link.click();
      }
    }
  };

  const handleOpenInNewTab = async () => {
    setIsOpeningNewTab(true);
    if (qrCode) {
      const blob = await qrCode.getRawData("png");
      if (blob) {
        setIsOpeningNewTab(false);
        window.open(URL.createObjectURL(blob), "_blank");
      }
    }
  };

  return (
      <div className="w-full">
        <center className="w-full mb-4">
          <div ref={ref} id={`qr-${processId}`} style={{ width: 250, height: 250 }} />
        </center>

        <div className="flex gap-3 lg:place-content-between justify-center lg:flex-row md:flex-col flex-row flex-wrap">
          <Button size={"sm"} color="light" onClick={handleDownloadQrCode}>
            {t("dashboard.features.lmc.qrModal.downloadQrBtn")}
          </Button>
          <Button
              size={"sm"}
              color="light"
              onClick={handleOpenInNewTab}
              isProcessing={isOpeningNewTab}
          >
            {t("dashboard.features.lmc.qrModal.openOtherTab")}
          </Button>
        </div>
      </div>
  );
};

export default ResultLmcCard;
