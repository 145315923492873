import React from "react";
import { LanguageChanger } from "../dashboard/Layout";
import { useTranslation } from "react-i18next";

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  return (
    <div className="auth-layout bg-gray-100 ">
      <div className="w-full h-[72px] py-4 bg-gray-700 justify-center items-center inline-flex">
        {/* <div className="self-stretch justify-start items-center gap-3 inline-flex">
          <div className="justify-start items-center gap-10 flex">
            <div className="w-10 h-10 relative">
              <div className="w-10 h-10 left-0 top-0 absolute bg-sky-500 rounded-[40px] border border-white border-opacity-10" />
              <div className="w-5 h-6 left-[10px] top-[8px] absolute"></div>
            </div>
          </div>
          <div className="text-white text-xl font-extrabold font-['Figtree'] leading-7">
            Super User Manager
          </div>
        </div> */}
        <img
          src={
            "https://cdn.1cdn.app/application/UMD/24042216091381_Logo.svg"
          }
          alt="Logo"
          className="w-[280px]"
        />
      </div>
      <div className="w-full min-h-[calc(100vh-135px)] px-8 pt-12 pb-2 flex-col justify-center items-center gap-8 inline-flex">
        {children}
      </div>
      <div className="flex justify-center items-center gap-8 py-4">
        <LanguageChanger i18n={i18n} className="bg-gray-400" />
      </div>
    </div>
  );
};

export default AuthLayout;
