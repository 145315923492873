import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillYoutube } from "react-icons/ai";
import { ITutorialDataType } from "../pages/dashboard/lmc/types";

const YoutubeButton = ({ tutorial }: { tutorial: ITutorialDataType }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  let userLanguage = (language?.split("-")[0] || "en") as "fr" | "en";

  if (userLanguage !== "fr" && userLanguage !== "en") {
    userLanguage = "en";
  }

  const getVideoUrl = () => {
    if (!tutorial.video) {
      return "https://www.youtube.com/embed/esMC2SQZxXQ";
    }

    if (tutorial.video[userLanguage]) {
      return tutorial.video[userLanguage];
    }

    if (tutorial.video.en) {
      return tutorial.video.en;
    }
    if (tutorial.video.fr) {
      return tutorial.video.fr;
    }

    return "https://www.youtube.com/embed/esMC2SQZxXQ";
  };

  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <div
        role="button"
        onClick={() => setShow(true)}
        className="pl-3 pr-4 py-2 bg-white rounded-md shadow-sm border border-gray-300 justify-center items-center gap-1 flex overflow-hidden "
      >
        <AiFillYoutube className="text-red-500 w-6 h-6 flex-shrink-0" />
        <div className="block">
          {t("dashboard.features.lmc.general.tutorial")}
        </div>
      </div>

      <Modal
        size={"lg"}
        show={show}
        dismissible
        onClose={() => setShow(false)}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <Modal.Header className="border-0 items-start">
          <span className="text-zinc-900 text-xl font-['Sofia Sans'] items-start justify-between">
            {t("dashboard.features.lmc.general.tutorial")}
          </span>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={getVideoUrl()}
            width="100%"
            className="md:h-[28rem] sm:h-full"
            style={{ border: "none" }}
            title="Tutorial Iframe"
          ></iframe>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default YoutubeButton;
