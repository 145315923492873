import { getCookie, setCookie } from "typescript-cookie";

const utmManager = (searchParams: URLSearchParams): void => {
  const utm_datas: any = {
    bp_id: searchParams.has("bp_id") ? searchParams.get("bp_id") : "",
  };

  if (window.navigator.cookieEnabled) {
    for (const key in utm_datas) {
      if (getCookie("utm_datas") && key == "bp_id") {
        try {
          const utm_datas_cookie = JSON.parse(getCookie("utm_datas") as string);

          if (utm_datas_cookie.bp_id) {
            delete utm_datas[key];
          }
        } catch (error) {}
      }

      if (utm_datas[key] === "") {
        delete utm_datas[key];
      }
    }

    if (Object.keys(utm_datas).length > 0)
      setCookie("utm_datas", JSON.stringify(utm_datas), { expires: 30 });
  }
};

const Utils = {
  utmManager,
};

export default Utils;
