import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError, AxiosResponse } from "axios";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { useTranslation } from "react-i18next";
import { Modal, Spinner } from "flowbite-react";
import { BiSolidPlusCircle } from "react-icons/bi";
import { queryClient } from "../../../../providers/QueryProvider";
import { queryKeys } from "../../../../constants/queryKeys";
import { useGetGroups } from "../../../../hooks/groups";

type AddToGroupModalProps = {
  userList: Set<string>;
  show: boolean;
  onHide: () => void;
  onResolved: () => void;
};

const schema = Joi.object({
  group_identification: Joi.string().trim().required().label("Group"),
});
const AddToGroupModal = ({
  userList,
  show,
  onHide,
  onResolved,
}: AddToGroupModalProps) => {
  const [t] = useTranslation();

  const { user, customer_identification } = useAuth();
  const { register, handleSubmit } = useForm({
    resolver: joiResolver(schema),
  });
  const { data: groupsRes } = useGetGroups({
    customer_identification: customer_identification,
    entityIdentification: user.entityIdentification,
    limit: 50,
    sortOrder: "DESC",
    pageParam: 0,
  });

  const AddUsersToSpecifiedGroup = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/user/group/${data.group_identification}/bulk`,
        {
          users: userList.values().toArray(),
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (res: AxiosResponse) => {
        toast.success(res.data.message);
        queryClient.invalidateQueries(["entity_user"]);
        queryClient.invalidateQueries(queryKeys.group_users);
        queryClient.invalidateQueries(queryKeys.users);
        queryClient.invalidateQueries(queryKeys.searchUsers);
        onResolved();
        onHide();
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const onSubmit = (values: any) => {
    AddUsersToSpecifiedGroup.mutate(values);
  };

  return (
    <Modal
      show={show}
      onClose={() => {
        onResolved();
        onHide();
      }}
      size={"3xl"}
    >
      <Modal.Header className="border-b-0" as={"div"}>
        <div className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.entities.viewEntityUser.key14")}
        </div>
        <div className="text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.entities.viewEntityUser.key15")}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="w-full">
          <div className="self-stretch flex-col justify-start items-start gap-4 flex">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
              <div className="self-stretch justify-start items-start gap-3 flex flex-col md:flex-row ">
                <select
                  aria-label="ownerGroups"
                  className="w-full pl-[17px] pr-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-between items-center inline-flex"
                  {...register("group_identification")}
                >
                  <option value="">
                    {t("dashboard.entities.manageCustomerGroups.selectText")}
                  </option>
                  {groupsRes?.groups?.map((gr: any, index: number) => (
                    <option value={gr.group_identification} key={index}>
                      {gr.group_name}
                    </option>
                  ))}
                </select>

                <button
                  type="submit"
                  disabled={
                    groupsRes?.groups.length <= 0 ||
                    AddUsersToSpecifiedGroup.isLoading
                  }
                  className="h-11 pl-3  pr-4 py-2 bg-sky-500 rounded-md shadow-sm justify-center items-center gap-1.5 inline-flex overflow-hidden w-full md:w-48"
                >
                  {AddUsersToSpecifiedGroup.isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div data-svg-wrapper className="relative">
                        <BiSolidPlusCircle className="text-white" />
                      </div>
                      <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                        {t("dashboard.entities.viewEntityUser.key14")}
                      </div>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddToGroupModal;
