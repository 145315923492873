import { Dispatch, SetStateAction } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import useAppLocalStorage from "../../../../hooks/useAppLocalStorage";
import { useForm } from "react-hook-form";
import { Button, Modal } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../../providers/QueryProvider";

type IntroTypes = {
  handleChangeStep: Dispatch<SetStateAction<"intro" | "noBasic" | "form">>;
  handleCloseModal: () => void;
  showNoBasicFn: () => void;
};

const Intro = (props: IntroTypes) => {
  const { t } = useTranslation();
  const { customer_identification, user, setUser } = useAuth();
  const localStorage = useAppLocalStorage();

  const { register, handleSubmit, setValue, watch } = useForm<{
    is_basic: boolean | string;
  }>();

  const {
    isLoading,
    isSuccess,
    data: res,
  } = useQuery(
    ["user-preferences", customer_identification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdatePreferencesMutation = useMutation<any, AxiosError<any, any>, any>(
    (json) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/preferences`,
        { user_data: json },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: () => {
        toast.success("Preferences are updated");

        queryClient.invalidateQueries([
          "user-preferences",
          customer_identification,
        ]);

        props.handleCloseModal();

        props.showNoBasicFn();

        let sessionData: any = localStorage.getItem("_umd_service");

        if (sessionData) {
          const newAuthData = { ...JSON.parse(sessionData), isBasic: true };

          localStorage.setItem("_umd_service", JSON.stringify(newAuthData));
        }
      },
      onError: (err: any) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const onSubmit = (data: { is_basic: boolean | string }) => {
    if (!data.is_basic) props.handleChangeStep("form");
    else {
      if (isSuccess) {
        UpdatePreferencesMutation.mutate({
          ...res.data.element.user_data_preferences,
          is_basic: true,
        });

        setUser({ ...user, isBasic: true });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <h2 className="text-gray-900 text-lg font-semibold font-['Figtree'] leading-7 mb-2">
          {t("dashboard.onBoarding.key1")}
        </h2>
        <p className="text-gray-900 text-sm font-normal font-['Figtree'] leading-tight mb-5">
          {t("dashboard.onBoarding.key2")}
        </p>

        <div className="grid grid-cols-12 gap-3">
          <div className="md:col-span-6 col-span-12 flex flex-1">
            <div
              className={`w-full p-6 bg-white rounded-lg shadow border flex-col justify-start items-start inline-flex ${
                watch("is_basic") === false ? "border-sky-300 " : ""
              }`}
            >
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="w-4 h-5 justify-center items-center inline-flex">
                  <input
                    type="radio"
                    id="basic-check-1"
                    name="is_basic"
                    onChange={() => setValue("is_basic", false)}
                  />
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch text-gray-900 text-base font-normal font-['Figtree'] leading-normal">
                    {t("dashboard.onBoarding.key3")}
                  </div>
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.onBoarding.key4")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-6 col-span-12 flex flex-1">
            <div
              className={`w-full p-6 bg-white rounded-lg shadow border flex-col justify-start items-start inline-flex ${
                watch("is_basic") === "on" ? "border-sky-300 " : ""
              }`}
            >
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="w-4 h-5 justify-center items-center inline-flex">
                  <input
                    type="radio"
                    id="basic-check-2"
                    disabled={isLoading}
                    {...register("is_basic")}
                  />
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch text-gray-900 text-base font-normal font-['Figtree'] leading-normal">
                    {t("dashboard.onBoarding.key5")}
                  </div>
                  <div className="self-stretch text-gray-500 text-xs font-normal font-['Figtree'] leading-none">
                    {t("dashboard.onBoarding.key6")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="w-full px-6 py-3 bg-gray-50 rounded-bl-lg rounded-br-lg justify-end items-center gap-3 inline-flex">
        <Button
          type="submit"
          className="bg-sky-500"
          disabled={
            watch("is_basic") === null || watch("is_basic") === undefined
          }
        >
          {t("dashboard.onBoarding.key7")}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Intro;
