import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { useMutation, useQuery } from "react-query";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import { Timer } from "../../../../routes/auth/ForgotPassword";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Button, Label, Modal, TextInput } from "flowbite-react";

const schema = Joi.object({
  user_email: Joi.string().trim().required().label("Current email"),
  new_user_email: Joi.string()
    .trim()
    .required()
    .regex(/^[^\s@`!@#$%^&*()=]+@[^\s@`!@#$%^&*()=]+\.[^\s@`!@#$%^&*()=]+$/)
    .messages({
      "string.pattern.base": "Must be a valid email address",
    })
    .label("New email"),
  new_user_email_confirmation: Joi.any()
    .valid(Joi.ref("new_user_email"))
    .required()
    .messages({ "any.only": "Confirm email must match" }),
});

const ProfileEmailChange = () => {
  const [t] = useTranslation();
  const { customer_identification, user } = useAuth();

  const [show, setShow] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [isResendRequest, setIsResendRequest] = useState(false);

  const profileQuery = useQuery("profile", () =>
    HttpService.get(
      `/${customer_identification}/${user.entityIdentification}/user/${customer_identification}/profile`,
      {
        auth: HttpService.getToken(),
      }
    )
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    trigger,
    setValue,
    reset,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      user_email: "",
      new_user_email: "",
      new_user_email_confirmation: "",
    },
  });

  const UpdateEmailMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.patch(
        `/${customer_identification}/${user.entityIdentification}/entity/update-email`,
        data
      ),
    {
      onSuccess: (data) => {
        // Swal.fire({
        //   icon: "success",
        //   title: "Validation link is sent",
        //   text: "The validation email has been sent to your new email address. You need to confirm your new email to make the changes applied. Please check your mailbox.",
        //   confirmButtonColor: "rgba(92, 174, 249, 1)",
        // });

        toast.success(data.data.message || "Validation link is sent");
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const ResendValidationEmailMutation = useMutation<
    any,
    AxiosError<any, any>,
    any
  >(
    () =>
      HttpService.post(
        `/${customer_identification}/${user.entityIdentification}/entity/resend-validation-email`,
        null
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setShowTimer(false);
      },
    }
  );

  const handleResendValidationLink = () => {
    if (!isValid) return trigger();

    setIsResendRequest(true);

    let newEmailInfos = getValues();

    if (
      newEmailInfos.new_user_email &&
      newEmailInfos.new_user_email_confirmation
    )
      UpdateEmailMutation.mutate(getValues());
    else {
      if (profileQuery.isSuccess)
        ResendValidationEmailMutation.mutate(undefined, {
          onSuccess: () => {
            toast.success(
              `Validation email is sent to your current email address: ${profileQuery.data.data.element.user_email}`
            );

            setShowTimer(true);
            setIsResendRequest(false);
          },
        });
    }
  };

  const onSubmit = (values: any) => {
    UpdateEmailMutation.mutate(values);
  };

  useEffect(() => {
    if (profileQuery.isSuccess && profileQuery.data.data.element.user_email)
      reset({ user_email: profileQuery.data.data.element.user_email });
  }, [profileQuery.data, profileQuery.isSuccess, reset, setValue]);

  return (
    <div className="px-5 pt-5 pb-8 bg-white rounded-lg shadow flex-col justify-start items-start gap-5 inline-flex">
      <div className="self-stretch h-14 flex-col justify-start items-start gap-2 flex">
        <div className="text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
          {t("dashboard.profile.key5")}
        </div>
        <div className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
          {t("dashboard.profile.key6")}
        </div>
      </div>

      <div className="flex items-center gap-3 flex-wrap">
        <div className="justify-start items-start gap-5 flex">
          <div className="lg:w-[318px] w-full flex-col justify-start items-start inline-flex">
            <div className="self-stretch px-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-start items-center inline-flex">
              <div className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
                {profileQuery.isSuccess &&
                  profileQuery.data.data.element.user_email}
              </div>
            </div>
          </div>
        </div>

        <Button
          className="bg-sky-500 p-0"
          type="submit"
          onClick={() => setShow(true)}
        >
          {t("dashboard.profile.key7")}
        </Button>
      </div>

      <Modal show={show} onClose={() => setShow(false)} size={"md"}>
        <Modal.Header className="border-b-0">
          {t("dashboard.profile.key7")}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
              <Label
                htmlFor="user_email"
                className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                {t("dashboard.userProfile.changeEmailForm.label-01")}
              </Label>
              <TextInput
                className="shadow"
                id="user_email"
                type="email"
                color={
                  !!errors.user_email ||
                  !!UpdateEmailMutation.error?.response?.data?.element
                    ?.user_email
                    ? "failure"
                    : ""
                }
                disabled
                helperText={
                  <>
                    {errors.user_email?.message ||
                      UpdateEmailMutation.error?.response?.data?.element
                        ?.user_email}
                  </>
                }
                {...register("user_email")}
              />
            </div>

            <div className="mb-5">
              <Label
                htmlFor="new_user_email"
                className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                {t("dashboard.userProfile.changeEmailForm.label-02")}
              </Label>
              <TextInput
                className="shadow"
                id="new_user_email"
                type="email"
                color={
                  !!errors.new_user_email ||
                  !!UpdateEmailMutation.error?.response?.data?.element
                    ?.new_user_email
                    ? "failure"
                    : ""
                }
                helperText={
                  <>
                    {errors.new_user_email?.message ||
                      UpdateEmailMutation.error?.response?.data?.element
                        ?.new_user_email}
                  </>
                }
                {...register("new_user_email")}
              />
            </div>

            <div className="mb-5">
              <Label
                htmlFor="new_user_email_confirmation"
                className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
              >
                {t("dashboard.userProfile.changeEmailForm.label-03")}
              </Label>
              <TextInput
                className="shadow"
                id="new_user_email_confirmation"
                type="email"
                color={
                  !!errors.new_user_email_confirmation ||
                  !!UpdateEmailMutation.error?.response?.data?.element
                    ?.new_user_email_confirmation
                    ? "failure"
                    : ""
                }
                helperText={
                  <>
                    {errors.new_user_email_confirmation?.message ||
                      UpdateEmailMutation.error?.response?.data?.element
                        ?.new_user_email_confirmation}
                  </>
                }
                {...register("new_user_email_confirmation")}
              />
            </div>

            <Button
              type="button"
              color={"light"}
              className="shadow w-full mb-4"
              onClick={handleResendValidationLink}
              disabled={showTimer || ResendValidationEmailMutation.isLoading}
              isProcessing={ResendValidationEmailMutation.isLoading}
            >
              {showTimer ? (
                <Timer setShowTimer={setShowTimer} ttl={120} />
              ) : (
                t("dashboard.userProfile.changeEmailForm.btn-02")
              )}
            </Button>

            <div className="flex items-center justify-around gap-4">
              <Button
                color="light"
                type="button"
                className="p-0 w-[50%] shadow"
                onClick={() => setShow(false)}
              >
                {t("dashboard.profile.key8")}
              </Button>

              <Button
                type="submit"
                className="p-0 w-[50%] bg-sky-500 shadow"
                disabled={!isResendRequest && UpdateEmailMutation.isLoading}
                isProcessing={!isResendRequest && UpdateEmailMutation.isLoading}
              >
                {t("dashboard.userProfile.changeEmailForm.btn-01")}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfileEmailChange;
