import { Button } from "flowbite-react";
import YoutubeButton from "./YoutubeButton";
import { useTranslation } from "react-i18next";
import { ITutorialDataType } from "../pages/dashboard/lmc/types";

const LMCButtons = ({
  handleCancelEdit,
  tutorial,
}: {
  handleCancelEdit: () => void;
  tutorial?: ITutorialDataType | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <div className="block xl:flex mb-3  gap-4 place-content-between">
      {tutorial && <YoutubeButton tutorial={tutorial} />}

      <div className="flex justify-between xl:justify-end gap-2 mt-4 xl:mt-0">
        <Button
          color={"light"}
          onClick={handleCancelEdit}
          className="px-0 py-1"
        >
          {t("dashboard.features.lmc.general.cancel")}
        </Button>

        <Button type="submit" className="bg-sky-500 px-0 py-1">
          {t("dashboard.features.lmc.general.save")}
        </Button>
      </div>
    </div>
  );
};

export default LMCButtons;
