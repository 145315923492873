import useIsAuthenticate from "./hooks/useIsAuthenticate";
import { useNavigate, useParams } from "react-router-dom";
import { useLmcUserView } from "./hooks/useLmcUserView";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import HttpService from "../../../services/http";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { LmcActionKind } from "./contexts";
import SocialAuth from "./SocialAuth";
import { IResolveParams } from "reactjs-social-login";
import { Button } from "flowbite-react";
import LmcUserStartPage from "./LmcUserStartPage";
import { useTranslation } from "react-i18next";
import { LanguageChanger } from "../dashboard/Layout";

const LmcUserViewLogin = (props: any) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { processId } = useParams();
  const isAuthenticated = useIsAuthenticate();

  const [startPage, setStartPage] = useState(true);
  const {
    state: {
      entity: { process_data, entity_data },
    },
    dispatch,
  } = useLmcUserView();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ user_email: string; user_password: string }>();

  const LoginLmcUserMutation = useMutation<any, AxiosError<any, any>, any>(
    (data) =>
      HttpService.post(
        `/auth/${entity_data?.entity_identification}/login`,
        data
      )
  );

  const socialAuthLoginMutation = useMutation<
    any,
    AxiosError<any, any>,
    { type: "login"; providerPayload: any }
  >((data) =>
    HttpService.post(
      `/auth/${entity_data?.entity_identification}/social/login`,
      data
    )
  );
  const handleLoginStartSocialAuth = () => {};

  const handleResolveSocialAuth = (
    provider: IResolveParams["provider"],
    data: IResolveParams["data"]
  ) => {
    const cpPayload = {
      access_token:
        provider === "google"
          ? data?.access_token
          : data?.authorization?.id_token,
      provider,
    };

    socialAuthLoginMutation.mutate(
      { type: "login", providerPayload: cpPayload },
      {
        onSuccess(data, variables, context) {
          dispatch({
            type: LmcActionKind.LOGIN,
            payload: data.data.element,
          });
        },
        onError(error, variables, context) {
          const code = error.response?.data?.code;

          if (code && code === "RSUSKO026")
            navigate(`/process/${processId}/register`, { replace: true });

          toast.error(error.response?.data.message || error.message);
        },
      }
    );
  };

  const handleRejectSocialAuth = (err: any) => {
    if (err.err.error !== "popup_closed_by_user")
      toast.error(t("lmcUser.key15"));
  };

  useEffect(() => {
    if (isAuthenticated)
      navigate(`/process/${processId}/dashboard`, { replace: true });
  }, [isAuthenticated, navigate, processId]);

  return (
    <div className="w-full md:w-[400px] mx-auto bg-neutral-900 flex flex-col justify-start items-center min-h-screen relative">
      <header className="w-full h-[268px] px-4 pt-5 pb-8 bg-gray-800 rounded-[32px] rounded-tr-[32px]">
        <div className="flex justify-end">
          <LanguageChanger i18n={i18n} className="bg-transparent" />
        </div>

        <div className="flex-col w-full justify-center items-center inline-flex">
          <div className="w-16 h-16 overflow-hidden">
            <img
              src={entity_data?.entity_logo}
              alt={entity_data?.entity_name}
              className="w-16 h-16 object-cover"
            />
          </div>
          <h1 className="mt-4 text-center text-gray-200 text-2xl font-bold font-['Figtree']">
            {t("lmcUser.key16")} {entity_data?.entity_name}
          </h1>
          <p className="mt-2 text-center text-gray-200 text-base font-normal font-['Figtree'] leading-normal">
            {t("lmcUser.key17")}
          </p>
        </div>
      </header>

      <main className="w-full p-4 flex flex-col justify-start items-center gap-4 mb-16">
        <div className="w-full flex-col justify-between items-center flex">
          <SocialAuth
            className="w-full"
            providers={["Google", "Apple"]}
            onLoginStartSocialAuthStart={handleLoginStartSocialAuth}
            onResolveSocialAuth={handleResolveSocialAuth}
            onRejectSocialAuth={handleRejectSocialAuth}
            redirectUrl={{
              Apple: `${process.env.REACT_APP_APPLE_CALLBACK}`,
            }}
            providersIcon={{
              Apple: (
                <div className="bg-gray-700 rounded-[100px] px-[10px] w-full h-11 flex flex-row justify-center items-center cursor-pointer relative mb-4">
                  <img
                    src="../../images/apple.svg"
                    alt="Sign up with Google"
                    className="w-6 h-6 absolute top-[10px] left-[10px]"
                  />
                  <p className="text-gray-50 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("lmcUser.key18")}
                  </p>
                </div>
              ),
              Google: (
                <div className="bg-gray-700 rounded-[100px] px-[10px] w-full h-11 flex flex-row justify-center items-center cursor-pointer relative">
                  <img
                    src="../../images/google.svg"
                    alt="Sign up with Google"
                    className="w-6 h-6 absolute top-[10px] left-[10px]"
                  />
                  <p className="text-gray-50 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("lmcUser.key19")}
                  </p>
                </div>
              ),
            }}
          />
        </div>

        <div className="my-4">
          <p className="text-center text-gray-200 text-xs font-bold font-['Figtree'] uppercase leading-none">
            {t("lmcUser.key20")}
          </p>
        </div>

        <Button
          className="w-full h-11 bg-sky-400 rounded-[100px] text-neutral-900"
          disabled={LoginLmcUserMutation.isLoading}
          isProcessing={LoginLmcUserMutation.isLoading}
          onClick={() => navigate(`/process/${processId}/email-login`)}
        >
          {t("lmcUser.key25")}
        </Button>

        <div className="h-px border border-gray-800 mt-12 w-full "></div>

        {process_data?.redirectionLinks?.map((item, index) => {
          return (
            <a
              href={item?.link}
              target="_blank"
              id={item?.id}
              className="h-11 bg-gray-700 rounded-full flex-col justify-center items-center gap-2 inline-flex overflow-hidden w-full"
            >
              <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                <div className="text-center text-gray-50 text-base font-semibold font-['Figtree'] leading-normal">
                  {item?.label}
                </div>
              </div>
            </a>
          );
        })}
      </main>

      {startPage && <LmcUserStartPage setStartPage={setStartPage} />}
    </div>
  );
};

export default LmcUserViewLogin;
