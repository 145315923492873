import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import LmcUserViewDashboardLayout from "./LmcUserViewDashboardLayout";
import LmcDashboard from "./LmcDashboard";
import LmcUserViewProfile from "./LmcUserViewProfile";
import LmcUserReward from "./LmcUserReward";
import LmcUserViewMessageItem from "./LmcUserViewMessageItem";
import LmcPrivateRoute from "./components/LmcPrivateRoute";
import LmcUserViewLogin from "./LmcUserViewLogin";
import LmcUserViewRegister from "./LmcUserViewRegister";
import { useQuery } from "react-query";
import HttpService from "../../../services/http";
import LoadingPage from "../../public/LoadingPage";
import { LmcActionKind } from "./contexts";
import { useLmcUserView } from "./hooks/useLmcUserView";
import { Button } from "flowbite-react";
import { useTranslation } from "react-i18next";
import LmcUserViewForgotPassword from "./LmcUserViewForgotPassword";
import LmcUserViewResetPassword from "./LmcUserViewResetPassword";
import LmcUserViewEmailLogin from "./LmcUserViewEmailLogin";

export const lmcInputClass = `w-full [&_*]:bg-gray-900 [&_*]:rounded-none [&_*]:border-0 [&_input]:border-b-2 [&_input]:border-gray-800 
                              [&_input]:text-gray-500 [&_input]:text-base [&_input]:font-normal [&_input]:font-['Figtree'] [&_input]:leading-normal 
                              [&_input:focus]:ring-0 [&_input:focus]:border-gray-800 [&_input:focus]:text-gray-500 [&_input:focus]:rounded-[5px]`;

const LmcUserViewApp = () => {
  const { i18n, t } = useTranslation();
  const { processId } = useParams();
  const { dispatch } = useLmcUserView();

  const { isLoading, isError, isSuccess, refetch } = useQuery(
    "process-data",
    () => HttpService.get(`/entity/process/${processId}`),
    {
      onSuccess({ data }) {
        dispatch({
          type: LmcActionKind.ENTITY,
          payload: data.element,
        });

        if (
          data.element?.process_data?.language &&
          ["en", "fr"].includes(data.element.process_data.language)
        )
          i18n.changeLanguage(data.element.process_data.language);
      },
    }
  );

  let content;

  if (isLoading) content = <LoadingPage />;

  if (isError)
    content = (
      <div className="flex flex-col items-center justify-center min-h-screen p-7">
        <h4>{t("lmcUser.key1")}</h4>
        <Button color="light" onClick={() => refetch()}>
          {t("lmcUser.key2")}
        </Button>
      </div>
    );

  if (isSuccess)
    content = (
      <div className="w-full bg-gray-900 relative lmcUserView">
        <Routes>
          <Route path="login" element={<LmcUserViewLogin />} />
          <Route path="email-login" element={<LmcUserViewEmailLogin />} />

          <Route path="register" element={<LmcUserViewRegister />} />
          <Route
            path="forgot-password"
            element={<LmcUserViewForgotPassword />}
          />
          <Route path="reset-password" element={<LmcUserViewResetPassword />} />
          <Route
            path="dashboard"
            element={
              <LmcPrivateRoute>
                <LmcUserViewDashboardLayout>
                  <Outlet />
                </LmcUserViewDashboardLayout>
              </LmcPrivateRoute>
            }
          >
            <Route index element={<LmcDashboard />} />
            <Route path="messages/:id" element={<LmcUserViewMessageItem />} />
            <Route path="reward" element={<LmcUserReward />} />
            <Route path="profile" element={<LmcUserViewProfile />} />
          </Route>
          <Route path="" element={<Navigate to={"dashboard"} />} />
          <Route path="*" element={<Navigate to={"/not-found"} />} />
        </Routes>
      </div>
    );

  return <>{content}</>;
};

export default LmcUserViewApp;
