import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import HttpService from "../../../../services/http";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import useAuth from "../../../../hooks/useAuth";
import { Button, Dropdown, Label, Modal, TextInput } from "flowbite-react";
import { FaChevronDown } from "react-icons/fa6";
import { queryClient } from "../../../../providers/QueryProvider";

type RoleTypeEditorType = {
  data: {
    role: string;
    type: string;
  };
  user_identification: string;
  customerId: string;
  entity_identification: string;
};

const schemaRole = Joi.object({
  role: Joi.string().trim().min(2).max(20).required().label("Role name"),
});

const shcemaType = Joi.object({
  type: Joi.string().trim().min(2).max(20).required().label("Type name"),
});

export const RoleModal = ({
  roles,
  mutation,
  showLabel = true,
  setRole,
  setRoles,
  setShowRoleModal,
  disabled,
}: {
  roles: string[] | [];
  mutation: any;
  showLabel?: boolean;
  setRole: Dispatch<SetStateAction<string>>;
  setRoles: Dispatch<SetStateAction<any>>;
  setShowRoleModal: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  const [t] = useTranslation();

  const { customer_identification } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schemaRole),
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data.role, {
      onSuccess: () => {
        toast.success("User role updated");

        queryClient.invalidateQueries(["roles_list", customer_identification]);
        queryClient.invalidateQueries("entity-users");

        setRoles([...roles, data.role]);

        setShowRoleModal(false);

        setRole(data.role.toUpperCase());
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showLabel && (
        <Label
          className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none block mb-3"
          value={t(
            "dashboard.roleTypeEditorNewVersion.modal.title-01"
          ).toString()}
        />
      )}

      <TextInput
        type="text"
        id="role"
        placeholder={`${t(
          "dashboard.roleTypeEditorNewVersion.modal.placeholder-01"
        )}`}
        color={errors.role ? "failure" : ""}
        helperText={<>{errors.role?.message}</>}
        {...register("role")}
      />

      <div className="flex justify-end">
        <Button
          className="mt-3 bg-sky-500"
          id="role"
          type="submit"
          isProcessing={mutation.isLoading}
          disabled={mutation.isLoading}
        >
          {t("dashboard.roleTypeEditorNewVersion.btn-01")}
        </Button>
      </div>
    </form>
  );
};

export const TypeModal = ({
  types,
  mutation,
  showLabel = true,
  setType,
  setTypes,
  setShowTypeModal,
  disabled,
}: {
  types: string[] | [];
  mutation: any;
  showLabel?: boolean;
  setType: Dispatch<SetStateAction<string>>;
  setTypes: Dispatch<SetStateAction<any>>;
  setShowTypeModal: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  const [t] = useTranslation();

  const { customer_identification } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(shcemaType),
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data.type, {
      onSuccess: () => {
        toast.success("User type updated");

        queryClient.invalidateQueries(["types_list", customer_identification]);
        queryClient.invalidateQueries("entity-users");

        setTypes([...types, data.type]);

        setShowTypeModal(false);

        setType(data.type.toUpperCase());
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showLabel && (
        <Label
          className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none block mb-3"
          value={t(
            "dashboard.roleTypeEditorNewVersion.modal.title-02"
          ).toString()}
        />
      )}

      <TextInput
        type="text"
        id="type"
        placeholder={`${t(
          "dashboard.roleTypeEditorNewVersion.modal.placeholder-02"
        )}`}
        color={!!errors.type ? "failure" : ""}
        helperText={<>{errors.type?.message as ReactNode}</>}
        {...register("type")}
      />

      <div className="flex justify-end">
        <Button
          className="mt-3 bg-sky-500"
          id="typeGroup"
          type="submit"
          disabled={disabled}
          isProcessing={mutation.isLoading}
        >
          Add
        </Button>
      </div>
    </form>
  );
};

const RoleTypeEditorNewVersion = ({
  data,
  customerId,
  entity_identification,
  user_identification,
}: RoleTypeEditorType) => {
  const [t] = useTranslation();

  const { userEndedSubscription } = useAuth();

  const [role, setRole] = useState<string>("");
  const [type, setType] = useState<string>("");

  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showTypeModal, setShowTypeModal] = useState(false);

  const [roles, setRoles] = useState<string[] | []>([]);
  const [types, setTypes] = useState<string[] | []>([]);

  useEffect(() => {
    setRole(data.role);
    setType(data.type);
  }, [data]);

  const {
    // data: resRole,
    isSuccess: isSuccessRoles,
    isLoading: isLodingRole,
    isError: isErrorRole,
    refetch: roleRefetch,
  } = useQuery(
    ["roles_list", user_identification],
    () =>
      HttpService.get(
        `/${user_identification}/${entity_identification}/entity/roles`,

        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (value) => {
        setRoles(value.data.element);
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const {
    // data: resType,
    isSuccess: isSuccessTypes,
    isLoading: isLoadingType,
    isError: isErrorType,
    refetch: typeRefetch,
  } = useQuery(
    ["types_list", user_identification],
    () =>
      HttpService.get(
        `/${user_identification}/${entity_identification}/entity/types`,

        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (value) => {
        setTypes(value.data.element);
      },
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateRoleMutation = useMutation<string, AxiosError<any, any>, any>(
    (role) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/user/${customerId}/role/${role}`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const UpdateTypeMutation = useMutation<string, AxiosError<any, any>, any>(
    (type) =>
      HttpService.patch(
        `/${user_identification}/${entity_identification}/user/${customerId}/type/${type}`,
        null,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleRoleManagement = (e: string) => {
    const optionValue = e;

    // Update user role here
    if (optionValue !== "addRole") {
      UpdateRoleMutation.mutate(optionValue, {
        onSuccess(data, variables, context) {
          toast.success("User's role updated");

          setRole(optionValue);
        },
      });
    } else {
      // if it is a new role add it to the list of roles
      setShowRoleModal(true);
    }
  };

  const handleTypeManagement = (e: string) => {
    const optionValue = e;

    // Update user role here
    if (optionValue !== "addType") {
      UpdateTypeMutation.mutate(optionValue, {
        onSuccess(data, variables, context) {
          toast.success("User's type updated");

          setType(optionValue);
        },
      });
    } else {
      // if it is a new role add it to the list of roles
      setShowTypeModal(true);
    }
  };

  let roleContent;

  if (isLodingRole || isLoadingType) roleContent = <div>loading...</div>;

  if (isErrorRole)
    roleContent = (
      <Button color="light" onClick={() => roleRefetch()}>
        Reload
      </Button>
    );

  if (isSuccessRoles)
    roleContent = (
      <div className="md:col-span-6 col-span-12">
        <div className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-4">
          {t("dashboard.entities.viewEntityUser.key7")}
        </div>
        <Dropdown
          label=""
          renderTrigger={() => (
            <button className="w-full pl-[17px] pr-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-between items-center flex">
              <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                {role}
              </div>
              <div className="w-5 h-5 relative">
                <FaChevronDown size={14} />
              </div>
            </button>
          )}
          className="w-[315px]"
        >
          <div className="max-h-[200px] overflow-y-auto">
            {roles.map((role: string, index: number) => (
              <Dropdown.Item
                key={index}
                value={role}
                onClick={() => handleRoleManagement(role)}
              >
                {role}
              </Dropdown.Item>
            ))}
          </div>
          <Dropdown.Divider />
          <Dropdown.Item
            className="text-sky-500"
            onClick={() => handleRoleManagement("addRole")}
          >
            {t("dashboard.roleTypeEditorNewVersion.btn-02")}
          </Dropdown.Item>
        </Dropdown>
      </div>
    );

  let typeContent;

  if (isErrorType)
    typeContent = (
      <Button color="light" onClick={() => typeRefetch()}>
        Reload
      </Button>
    );

  if (isSuccessTypes)
    typeContent = (
      <div className="md:col-span-6 col-span-12">
        <div className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-4">
          {t("dashboard.entities.viewEntityUser.key8")}
        </div>
        <Dropdown
          label=""
          renderTrigger={() => (
            <button className="w-full pl-[17px] pr-[13px] py-[9px] bg-white rounded-md shadow border border-gray-300 justify-between items-center flex">
              <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                {type}
              </div>
              <div className="w-5 h-5 relative">
                <FaChevronDown size={14} />
              </div>
            </button>
          )}
          className="w-[315px]"
        >
          <div className="max-h-[200px] overflow-y-auto">
            {types.map((type: string, index: number) => (
              <Dropdown.Item
                key={index}
                value={type}
                onClick={() => handleTypeManagement(type)}
              >
                {type}
              </Dropdown.Item>
            ))}
          </div>
          <Dropdown.Divider />
          <Dropdown.Item
            className="text-sky-500"
            onClick={() => handleTypeManagement("addType")}
          >
            {t("dashboard.roleTypeEditorNewVersion.btn-03")}
          </Dropdown.Item>
        </Dropdown>
      </div>
    );

  return (
    <div className="w-full">
      {/* <h3 className="fw-bold mb-5">{t("dashboard.entities.roleType.title")}</h3> */}

      <div className="grid grid-cols-12 gap-4">
        {roleContent}

        {typeContent}
      </div>

      {/* Add user to group role modal */}
      <Modal
        size="lg"
        show={showRoleModal}
        onClose={() => setShowRoleModal(false)}
        className="role-modal"
      >
        <Modal.Header className="border-b-0 text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.roleTypeEditorNewVersion.modal.header-01")}
          <p className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight mt-2">
            {t("dashboard.roleTypeEditorNewVersion.modal.description-01")}
          </p>
        </Modal.Header>
        <Modal.Body>
          <RoleModal
            roles={roles}
            setRoles={setRoles}
            setShowRoleModal={setShowRoleModal}
            mutation={UpdateRoleMutation}
            setRole={setRole}
            disabled={userEndedSubscription}
          />
        </Modal.Body>
      </Modal>

      {/* Add user to group type modal */}
      <Modal
        size="lg"
        show={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        className="role-modal"
      >
        <Modal.Header className="border-b-0 text-gray-700 text-2xl font-bold font-['Figtree'] leading-loose">
          {t("dashboard.roleTypeEditorNewVersion.modal.header-02")}
          <p className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight mt-2">
            {t("dashboard.roleTypeEditorNewVersion.modal.description-02")}
          </p>
        </Modal.Header>

        <Modal.Body>
          <TypeModal
            types={types}
            setTypes={setTypes}
            setShowTypeModal={setShowTypeModal}
            mutation={UpdateTypeMutation}
            setType={setType}
            disabled={userEndedSubscription}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RoleTypeEditorNewVersion;
